import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  deleteRuleSetting,
  editRuleSetting,
  fetchRoles,
  findRule,
  RoleData,
  submitRuleSetting,
} from "./RuleSettingsSlice";
import { RootState } from "../../store";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { MutatingDots } from "react-loader-spinner";
import Loader from "../components/Loader";

const RuleSetting: React.FC = () => {
  const [amountRange, setAmountRange] = useState({ min: 0, max: Infinity });
  const [approvers, setApprovers] = useState<RoleData[]>([{} as RoleData]);
  const [active, setActive] = useState(false);
  const [editingRuleIndex, setEditingRuleIndex] = useState<number | null>(null);
  const { roles, foundRules, loading } = useSelector((state: RootState) => state.role);

  const approversOptions: RoleData[] = roles.filter(
    (role) => !approvers.some((approver) => approver.roleGuid === role.roleGuid)
  );
  

  const location = useLocation();
  const data = location.state?.data;
  const ruleValues = location.state?.values;



  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRoles() as any);
    dispatch(findRule({ instanceGuid: data?.instanceGuid }) as any);
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      ruleGuid: "",
      currency: "ZAR",
      // category: ruleValues?.categoryCode,
      // ruleName: ruleValues?.ruleName,
      fromAmount: 0,
      toAmount: 0,
      levels: [],
    },
    validationSchema: Yup.object({
      levels: Yup.array()
        .of(
          Yup.object().shape({
            actorId: Yup.string().required("Approver is required"),
            actorName: Yup.string().required("Approver name is required"),
          })
        )
        .min(1, "At least one approver is required"),
      fromAmount: Yup.number()
        .typeError("From amount must be a number")
        .required("From amount is required")
        .min(0, "From amount must be at least 0"),
      toAmount: Yup.number()
        .typeError("From amount must be a number")
        .required("To amount is required")
        .min(0, "To amount must be at least 0")
        .test(
          "is-greater-than-fromAmount",
          "To amount must be greater than from amount",
          function (value) {
            const { fromAmount } = this.parent;
            return value > fromAmount;
          }
        ),
    }),
    onSubmit: async (values) => {
      try {
        const { ruleGuid, ...remainingValues } = values;
        {
          ruleGuid !== "" || null ? await dispatch(editRuleSetting({ ...values, instanceGuid: data?.instanceGuid, levels: values.levels.map((level: any, index) => ({ ...level, index: index + 1 })) } as any) as any).then((res: any) => {
            if (res?.meta?.requestStatus === "fulfilled") {
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Rule updated successfully!",
              });
            }
          })
            : await dispatch(submitRuleSetting({ ...remainingValues, instanceGuid: data?.instanceGuid } as any) as any).then((res: any) => {
              if (res?.meta?.requestStatus === "fulfilled") {
                Swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: "Rule created successfully!",
                });
              }
            });
        }

        await dispatch(findRule({ instanceGuid: data?.instanceGuid }) as any);

        handleCancel();
      } catch (error) {
        console.error("Submission failed:", error);
      }
    },
  });

  const handleAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: "min" | "max"
  ) => {
    let value = e.target.value;
  
    // Allow only numeric values and a single dot for decimals
    if (!/^\d*\.?\d*$/.test(value)) return;
  
    // Remove leading zero if it exists and the value is not empty
    const updatedValue = value === "" ? "" : value.replace(/^0+(\d)/, "$1");
  
    setAmountRange({ ...amountRange, [key]: updatedValue ? Number(updatedValue) : 0 });
  
    // Update Formik field value
    formik.setFieldValue(
      key === "min" ? "fromAmount" : "toAmount",
      updatedValue
    );
  };
  


  const handleApproverChange = (
    index: number,
    selectedOption: RoleData | null
  ) => {
    const updatedApprovers = [...approvers];
    updatedApprovers[index] = selectedOption as RoleData;
    setApprovers(updatedApprovers);

    const approverData = updatedApprovers
      .map((option, idx) => ({
        actorType: "ROLE",
        actorId: option?.roleGuid,
        actorName: option?.name,
        index: idx + 1,
      }))
      .filter((option) => option.actorId);

    formik.setFieldValue("levels", approverData);
  };

  const addApproverField = () => {
    const lastApprover = approvers[approvers.length - 1];

    if (lastApprover && lastApprover.name) {
      setApprovers([...approvers, {} as RoleData]);
    } else {
      toast.error(
        "Please complete the previous approver details before adding a new one.",
        { theme: "colored" }
      );
    }
  };

  const removeApproverField = (index: number) => {
    const updatedApprovers = approvers.filter((_, i) => i !== index);
    setApprovers(updatedApprovers);

    const updatedLevels = formik.values.levels.filter((_, i) => i !== index);
    formik.setFieldValue("levels", updatedLevels);
  };

  const handleCancel = () => {
    formik.resetForm();
    setAmountRange({ min: 0, max: Infinity });
    setApprovers([{} as RoleData]);
    setActive(false);
    setEditingRuleIndex(null); // Reset edit mode
  };

  const handleEditRule = (rule: any, index: number) => {
    setEditingRuleIndex(index); // Set edit mode for the clicked rule
    formik.setValues({
      // category: rule?.categoryCode,
      // ruleName:rule.ruleName,
      currency: rule?.currency,
      fromAmount: rule.fromAmount,
      toAmount: rule.toAmount,
      levels: rule.levels.map((level: any, idx: number) => ({
        actorId: level?.actorId,
        actorName: level?.actorName,
        actorType: level.actorType,
        index: idx + 1,

      })),
      ruleGuid: rule?.ruleGuid
    });
    setAmountRange({ min: rule.fromAmount, max: rule.toAmount });
    setApprovers(
      rule.levels.map((level: any) => ({
        roleGuid: level.actorId,
        name: level.actorName,
      }))
    );
  };


  return (
    <>
      {loading ? (
        <Loader/>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="container mt-4">
            <h4 className="mb-3">Rule Settings</h4>
            {foundRules.map((rule, index) => (
              <div key={index} className="card shadows-sm p-10 mb-5" style={{ border: "1px solid #ebeef2" }}>
                {editingRuleIndex === index ? (
                  <>
                    <div className="d-flex flex-row align-items-center mb-5">
                      <label className="form-label text-nowrap">
                        <i className="bi bi-circle-fill fs-7 text-danger me-5"></i>
                        If amount is greater or equal to&nbsp;
                      </label>
                      <div className="mb-2">
                        <div className="position-relative" style={{ maxWidth: "200px" }}>
                          <input
                            type="text"
                            value={amountRange.min}
                            onChange={(e) => handleAmountChange(e, "min")}
                            placeholder="0.00"
                            className="form-control"
                            style={{ paddingLeft: "60px", height: "33px" }} // Adjusted for space for currency dropdown
                            maxLength={12}
                          />
                          <select
                            name="currency"
                            value={formik.values.currency}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="position-absolute"
                            style={{
                              top: "50%",
                              left: "5px",
                              transform: "translateY(-50%)",
                              border: "none",
                              background: "transparent",
                              color: "#6c757d",
                              height: "33px", // Match input height
                              outline: "none"
                            }}
                          >
                            <option selected value="ZAR">ZAR</option>
                            <option value="USD">USD</option>
                          </select>
                        </div>
                        {formik.touched.fromAmount && formik.errors.fromAmount ? (
                          <div style={{ color: "red" }}>{formik.errors.fromAmount}</div>
                        ) : null}
                      </div>

                      <label className="form-label">
                        &nbsp;and less than &nbsp;
                      </label>
                      <div className="mb-2">
                        <div
                          className="position-relative"
                          style={{ maxWidth: "200px" }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "10px",
                              transform: "translateY(-50%)",
                              color: "#6c757d"
                            }}
                          >
                            {formik.values.currency}
                          </span>
                          <input
                            type="text"
                            value={
                              amountRange.max === Infinity ? "" : amountRange.max
                            }
                            onChange={(e) => handleAmountChange(e, "max")}
                            placeholder="No limit"
                            className="form-control"
                            style={{ paddingLeft: "50px", height: "33px" }}
                            maxLength={12}
                          />
                        </div>
                        {formik.touched.toAmount && formik.errors.toAmount ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.toAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {approvers.map((approver, index) => (
                      <div
                        className="form-group mb-3 d-flex flex-row align-items-center gap-5 ms-10"
                        key={index}
                      >
                        <label className="form-label">
                          <i
                            className="bi bi-arrow-return-right me-3 "
                            style={{ WebkitTextStroke: "1px" }}
                          ></i>{" "}
                          {index === 0 ? "Require" : "Then require"}
                        </label>
                        <div className="d-flex flex-grow-1 align-items-center">
                          <Select
                            options={approversOptions}
                            value={approver}
                            onChange={(selectedOption) =>
                              handleApproverChange(index, selectedOption)
                            }
                            className="basic-select w-100"
                            classNamePrefix="select"
                            placeholder="Select approver"
                            getOptionLabel={(role) => role.name}
                            getOptionValue={(role) => role.roleGuid}
                          />
                          {approvers.length > 1 && (
                            <span
                              className="fs-2x ms-2 mb-2 text-gray-600 cursor-pointer"
                              onClick={() => removeApproverField(index)}
                            >
                              &times;
                            </span>
                          )}
                        </div>
                        {formik.touched.levels && formik.errors.levels ? (
                          <div style={{ color: "red" }}>{formik.errors.levels}</div>
                        ) : null}
                      </div>
                    ))}

                    <span
                      className=" mb-4 text-start text-decoration-underline cursor-pointer ms-10"
                      onClick={addApproverField}
                    >
                      Add another approver
                    </span>

                    <div className="form-group ms-10 d-flex flex-end">
                      <button
                        type="button"
                        className="btn btn-secondary me-2 p-2 fs-7"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn  p-2 fs-7">
                        Save
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="">
                      <div className="d-flex flex-row  justify-content-between">
                        <div>
                          {" "}
                          <label className="form-label text-nowrap">
                            <i className="bi bi-circle-fill fs-7 me-5" style={{ color: `#${Math.floor(Math.random() * 16777215).toString(16)}` }}></i>{" "}
                            If amount is greater or equal to&nbsp;{rule?.currency}&nbsp;
                            <span className="fw-bold">{rule?.fromAmount}</span>
                            &nbsp;and less than&nbsp;{rule?.currency}&nbsp;
                            <span className="fw-bold">{rule?.toAmount}</span>
                          </label>
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="dropdown">
                            <button
                              className="btn btn-secondary m-0 p-0 d-flex align-items-center justify-content-center"
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                              }}
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="bi bi-three-dots p-0 m-0"></i>
                            </button>

                            <ul
                              className="dropdown-menu dropdown-menu-end p-1"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => handleEditRule(rule, index)}
                                >
                                  <i className="bi bi-pencil me-3"></i>Edit rule
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => {
                                    Swal.fire({
                                      title: 'Are you sure you want to delete this rule?',
                                      showCancelButton: true,
                                      confirmButtonText: 'Yes, delete it!',
                                      cancelButtonText: 'Cancel',
                                    }).then(async (result) => {
                                      if (result.isConfirmed) {

                                        await dispatch(deleteRuleSetting({ ruleGuid: rule?.ruleGuid }) as any).then((res: any) => {
                                          if (res?.meta?.requestStatus === "fulfilled") {
                                            Swal.fire({
                                              icon: "success",
                                              title: 'Rule setting deleted!',
                                            })
                                            dispatch(findRule({ instanceGuid: data?.instanceGuid }) as any);
                                          }
                                        });

                                      }
                                    });
                                  }}
                                >
                                  <i className="bi bi-trash3 me-3"></i>Delete rule
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5">
                      {rule.levels.map((rule, index) => (
                        <p className="ms-10">
                          <span className="fw-bold">
                            <i
                              className="bi bi-arrow-return-right me-3 "
                              style={{ WebkitTextStroke: "1px" }}
                            ></i>
                          </span>
                          {index === 0 ? "Require" : "Then require"}{" "}
                          <span className="fw-bold">{rule.actorName}</span>
                        </p>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ))}

            <div className="card shadow-sm p-10 border-0 mt-5" style={{ border: "1px solid #ebeef2" }}>
              <div className="form-group mb-4 d-flex flex-column">
                {!active ? (
                  <>
                    <div className="d-flex flex-row justify-content-between w-100">
                      <div>
                        {" "}
                        <i className="bi bi-circle-fill fs-7 text-danger me-5"></i>{" "}
                        Add another payment approval rule
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-secondary p-2 fs-7 m-0 p-0"
                          onClick={() => {
                            setActive(true);
                            formik.resetForm();
                            setAmountRange({ min: 0, max: Infinity });
                            setApprovers([{} as RoleData]);
                            setEditingRuleIndex(null); // Reset edit mode
                          }}
                        >
                          {" "}
                          <i
                            className="bi bi-plus-lg"
                            style={{ WebkitTextStroke: "0.5px" }}
                          ></i>
                          Add Rule
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-row align-items-center mb-5">
                      <label className="form-label text-nowrap">
                        <i className="bi bi-circle-fill fs-7 text-danger me-5"></i>
                        If amount is greater or equal to&nbsp;
                      </label>
                      <div className="mb-2">
                        <div className="position-relative" style={{ maxWidth: "200px" }}>
                          <input
                            type="text"
                            value={amountRange.min}
                            onChange={(e) => handleAmountChange(e, "min")}
                            placeholder="0.00"
                            className="form-control"
                            style={{ paddingLeft: "60px", height: "33px" }} // Adjusted for space for currency dropdown
                            maxLength={12}
                          />
                          <select
                            name="currency"
                            value={formik.values.currency}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="position-absolute"
                            style={{
                              top: "50%",
                              left: "5px",
                              transform: "translateY(-50%)",
                              border: "none",
                              background: "transparent",
                              color: "#6c757d",
                              height: "33px", // Match input height
                              outline: "none"
                            }}
                          >
                            <option selected value="ZAR">ZAR</option>
                            <option value="USD">USD</option>
                          </select>
                        </div>
                        {formik.touched.fromAmount && formik.errors.fromAmount ? (
                          <div style={{ color: "red" }}>{formik.errors.fromAmount}</div>
                        ) : null}
                      </div>

                      <label className="form-label">
                        &nbsp;and less than &nbsp;
                      </label>
                      <div className="mb-2">
                        <div
                          className="position-relative"
                          style={{ maxWidth: "200px" }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "10px",
                              transform: "translateY(-50%)",
                              color: "#6c757d"
                            }}
                          >
                            {formik.values.currency}
                          </span>
                          <input
                            type="text"
                            value={
                              amountRange.max === Infinity ? "" : amountRange.max
                            }
                            onChange={(e) => handleAmountChange(e, "max")}
                            placeholder="No limit"
                            className="form-control"
                            style={{ paddingLeft: "50px", height: "33px" }}
                            maxLength={12}
                          />
                        </div>
                        {formik.touched.toAmount && formik.errors.toAmount ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.toAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {approvers.map((approver, index) => (
                      <div
                        className="form-group mb-3 d-flex flex-row align-items-center gap-5 ms-10"
                        key={index}
                      >
                        <label className="form-label">
                          <i
                            className="bi bi-arrow-return-right me-3 "
                            style={{ WebkitTextStroke: "1px" }}
                          ></i>{" "}
                          {index === 0 ? "Require" : "Then require"}
                        </label>
                        <div className="d-flex flex-grow-1 align-items-center">
                          <Select
                            options={approversOptions}
                            value={approver}
                            onChange={(selectedOption) =>
                              handleApproverChange(index, selectedOption)
                            }
                            className="basic-select w-100"
                            classNamePrefix="select"
                            placeholder="Select approver"
                            getOptionLabel={(role) => role.name}
                            getOptionValue={(role) => role.roleGuid}
                          />
                          {approvers.length > 1 && (
                            <span
                              className="fs-2x ms-2 mb-2 text-gray-600 cursor-pointer"
                              onClick={() => removeApproverField(index)}
                            >
                              &times;
                            </span>
                          )}
                        </div>
                        {formik.touched.levels && formik.errors.levels ? (
                          <div style={{ color: "red" }}>{formik.errors.levels}</div>
                        ) : null}
                      </div>
                    ))}

                    <span
                      className=" mb-4 text-start text-decoration-underline cursor-pointer ms-10"
                      onClick={addApproverField}
                    >
                      Add another approver
                    </span>

                    <div className="form-group ms-10 d-flex flex-end">
                      <button
                        type="button"
                        className="btn btn-secondary me-2 p-2 fs-7 "
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn p-2 fs-7">
                        Save
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      )}

    </>
  );
};

export default RuleSetting;
