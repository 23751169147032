import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Swal from "sweetalert2";

// Define your API URL
const API_URL = process.env.REACT_APP_WEB_API_URL;

export interface RoleData {
    roleGuid: string;
    name: string;
    description: string;
    roleStatus: number;
    userCount: number;
    actorId?: string;  
    actorName?: string; 
}

interface RoleState {
    roles: RoleData[];
    ruleSettings: RuleSettingData[];
    foundRules: RuleSettingData[];
    loading: boolean;
    error: string | null;
    success: boolean;
}

const initialState: RoleState = {
    roles: [],
    ruleSettings: [],
    foundRules: [],
    loading: false,
    error: null,
    success: false,
};

export interface RuleSettingData {
    ruleName: string;
    fromAmount: number;
    toAmount: number;
    currency:string;
    levels: {
        actorType: string;
        actorId: string;
        actorName: string;
        index: number;
    }[];
    ruleGuid: string;
    status: string;
    businessGuid:string;
}

export type RuleGuid = {
    ruleGuid: string;
};


const getToken = (): string | null => {
    const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
    const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
    return tokenData ? tokenData.token : null;
};

const apiCall = async (url: string, body: any) => {
    const token = getToken();
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };

    const options = {
        method: "POST",
        headers,
        body: JSON.stringify(body),
    };

    const response = await enhancedFetch(url, options);
    const data = await response.json();

    if (data.status.statusCode !== 0) {
        const errorMessage = data.status.messageDescription || data.message || "An error occurred.";
        
        // Display error using Swal
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorMessage,
        });
    
        throw new Error(errorMessage); // Throws the error to be caught by higher-level error handling
    }
    
    return data.results;
};


export const fetchRoles = createAsyncThunk<RoleData[], void, { rejectValue: string }>(
    "roles/fetch",
    async () => {
        const url = `${API_URL}/team/getRoles`;
        return await apiCall(url, {});
    }
);

export const findRule = createAsyncThunk<RuleSettingData[], { instanceGuid: string }, { rejectValue: string }>(
    "rules/find",
    async ({ instanceGuid }) => {
        const url = `${API_URL}/rule/findRule`;
        return await apiCall(url, {
             searchCriteriaList: [
        {
          propertyName: "instanceGuid",
          propertyValue: instanceGuid,
        },
      ],
        });
    }
);

export const submitRuleSetting = createAsyncThunk<RuleSettingData, RuleSettingData, { rejectValue: string }>(
    "rules/submit",
    async (data) => {
        const url = `${API_URL}/rule/createRule`;
        return await apiCall(url, data);
    }
);

export const editRuleSetting = createAsyncThunk<RuleSettingData, RuleSettingData, { rejectValue: string }>(
    "rules/edit",
    async (data) => {
        const url = `${API_URL}/rule/updateRule`;
        return await apiCall(url, data);
    }
);

export const deleteRuleSetting = createAsyncThunk<RuleSettingData, RuleGuid, { rejectValue: string }>(
    "rules/delete",
    async (data) => {
        const url = `${API_URL}/rule/deleteRule`;
        return await apiCall(url, data);
    }
);

const roleSlice = createSlice({
    name: "role",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoles.pending, (state) => {
                state.loading = false;
            })
            .addCase(fetchRoles.fulfilled, (state, action: PayloadAction<RoleData[]>) => {
                state.roles = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchRoles.rejected, (state, action) => {
                state.error = action.payload || "An error occurred.";
                state.loading = false;
            })
            .addCase(submitRuleSetting.pending, (state) => {
                state.loading = false;
                state.success = false;
                state.error = null;
            })
            .addCase(submitRuleSetting.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(submitRuleSetting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "An error occurred.";
                state.success = false;
            })
            .addCase(findRule.pending, (state) => {
                state.loading = true;
            })
            .addCase(findRule.fulfilled, (state, action: PayloadAction<RuleSettingData[]>) => {
                state.foundRules = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(findRule.rejected, (state, action) => {
                state.error = action.payload || "An error occurred.";
                state.loading = false;
            })
            .addCase(editRuleSetting.pending, (state) => {
                state.loading = false;
            })
            .addCase(editRuleSetting.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(editRuleSetting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "An error occurred.";
                state.success = false;
            })
            .addCase(deleteRuleSetting.pending, (state) => {
                state.loading = false;
            })
            .addCase(deleteRuleSetting.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(deleteRuleSetting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "An error occurred.";
                state.success = false;
            });
    },
});

export default roleSlice.reducer;
