import { useState, useEffect } from "react";
import { enhancedFetch } from "../../../../modules/auth/core/_requests";
import { DrawerComponent } from "../../../../../_metronic/assets/ts/components";
import { OverlayTrigger, Popover } from "react-bootstrap";

interface Data {
  isfavourite: boolean;
  customerGuid: string;
  customerName: string;
  companyName: string;
  countryType: string;
  isdCode: number;
  phoneNumber: string;
  emailId: string;
  address: string;
  vatNumber: string;
  notes: string;
  bankName: string;
  bankAccountNo: string;
  bic: string;
  type: number;
}

export function CustomerDetailsModal({ Data }: { Data: Data | null }) {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [viewData, setViewData] = useState<Data | null>(null);

  const getItemDataa = async () => {
    try {
      const url = `${API_URL}/customer/load`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        customerGuid: Data?.customerGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      setViewData(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Data?.customerGuid) getItemDataa();
    DrawerComponent.reinitialization();
  }, [Data]);

  return (
    <>
      <div
        id="kt_modal_customer"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_modal_toggle_customer"
        data-kt-drawer-close="#kt_modal_close_customer"
      >
        {/* begin::Card */}
        <div className="card shadow-none rounded-0 w-100 p-5">
          {/* begin::Header */}
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_modal_close_customer"
              style={{ height: "24px", width: "24px" }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-gray-600 p-0">Details</h5>
            <div className="card-toolbar"></div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}

          <div className="card shadow p-5">
            <h4>Item Details</h4>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Customer Name</p>
              {viewData && viewData?.customerName?.length > 30 ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Popover
                      className="p-2 bg-light-secondary"
                      id="popover-trigger-focus"
                    >
                      {viewData?.customerName}
                    </Popover>
                  }
                >
                  <p className="text-primary text-hover-dark text-decoration-underline mb-1 fs-7">
                    {viewData?.customerName?.length > 30
                      ? `${viewData?.customerName.slice(0, 30)}...`
                      : viewData?.customerName}
                  </p>
                </OverlayTrigger>
              ) : (
                <p className="text-primary text-hover-dark text-decoration-underline  mb-1 fs-7">
                  {viewData?.customerName}
                </p>
              )}
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Company Name</p>
              {viewData && viewData?.companyName?.length > 30 ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Popover
                      className="p-2 bg-light-secondary"
                      id="popover-trigger-focus"
                    >
                      {viewData?.companyName}
                    </Popover>
                  }
                >
                  <p className="text-primary text-hover-dark text-decoration-underline mb-1 fs-7">
                    {viewData?.companyName?.length > 30
                      ? `${viewData?.companyName.slice(0, 30)}...`
                      : viewData?.companyName}
                  </p>
                </OverlayTrigger>
              ) : (
                <p className="text-primary text-hover-dark text-decoration-underline  mb-1 fs-7">
                  {viewData?.companyName}
                </p>
              )}
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Category</p>
              <p>
                {viewData?.type === 1
                  ? "Customer"
                  : viewData?.type === 2
                    ? "Vendor"
                    : "Both"}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Country Type</p>
              <p>{viewData?.countryType} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Isd Code</p>
              <p>+{viewData?.isdCode} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Phone Number</p>
              <p>{viewData?.phoneNumber} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Email Id</p>
              {viewData && viewData?.emailId?.length > 30 ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Popover
                      className="p-2 bg-light-secondary"
                      id="popover-trigger-focus"
                    >
                      {viewData?.emailId}
                    </Popover>
                  }
                >
                  <p className="text-primary text-hover-dark text-decoration-underline mb-1 fs-7">
                    {viewData?.emailId?.length > 30
                      ? `${viewData?.emailId.slice(0, 30)}...`
                      : viewData?.emailId}
                  </p>
                </OverlayTrigger>
              ) : (
                <p className="text-primary text-hover-dark text-decoration-underline  mb-1 fs-7">
                  {viewData?.emailId}
                </p>
              )}
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Bank Name</p>
              <p>{viewData?.bankName} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Bank Account Number</p>
              <p>{viewData?.bankAccountNo} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Address</p>
              {viewData && viewData?.address?.length > 30 ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Popover
                      className="p-2 bg-light-secondary"
                      id="popover-trigger-focus"
                    >
                      {viewData?.address}
                    </Popover>
                  }
                >
                  <p className="text-primary text-hover-dark text-decoration-underline mb-1 fs-7">
                    {viewData?.address?.length > 30
                      ? `${viewData?.address.slice(0, 30)}...`
                      : viewData?.address}
                  </p>
                </OverlayTrigger>
              ) : (
                <p className="text-primary text-hover-dark text-decoration-underline  mb-1 fs-7">
                  {viewData?.address}
                </p>
              )}
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">VAT Number</p>
              <p>{viewData?.vatNumber} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">BIC</p>
              <p>{viewData?.bic} </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Notes</p>
              {viewData && viewData?.notes?.length > 30 ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Popover
                      className="p-2 bg-light-secondary"
                      id="popover-trigger-focus"
                    >
                      {viewData?.notes}
                    </Popover>
                  }
                >
                  <p className="text-primary text-hover-dark text-decoration-underline mb-1 fs-7">
                    {viewData?.notes?.length > 30
                      ? `${viewData?.notes.slice(0, 30)}...`
                      : viewData?.notes}
                  </p>
                </OverlayTrigger>
              ) : (
                <p className="text-primary text-hover-dark text-decoration-underline  mb-1 fs-7">
                  {viewData?.notes}
                </p>
              )}
            </div>
          </div>

          {/* end::Body */}
        </div>
        {/* end::Card */}
      </div>
    </>
  );
}
