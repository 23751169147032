import { useState, ChangeEvent, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BukDetailsModal } from "./bulkPaymentDetails/bulkPaymentDetails";
import { CreateAppModal } from "./bulkPaymentStepper/CreateAppModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Authorize from "../../modules/auth/components/Authorize";
import Loader from "../components/Loader";
import { DrawerComponent } from "../../../_metronic/assets/ts/components";

interface FileUpload {
  name: string;
  description: string;
  fileUploadId: number;
  fileUploadGuid: string;
  jobScheduledTime: string;
  fileName: string;
  uploadedDate: string;
  successCount: number;
  failCount: number;
  totalCount: number;
  fileUploadStatus: number;
  fileUploadResponse: {
    sum: number;
  };
}

export const ApiData = {
  api: (): void => {},
};

export default function BulkPaymentDashboard() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const baseCurrency = sessionStorage.getItem("currency");

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [button, setButton] = useState("Create");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [data, setData] = useState<FileUpload[]>([]);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<FileUpload | null>(null);

  const [filteredTransactions, setFilteredTransactions] = useState(data);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>("Success");
  const [loading, setLoading] = useState(false);

  const tooltipDelete = <Tooltip id="tooltip-add-money">Cancel</Tooltip>;

  const navigate = useNavigate();

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  // useEffect(() => {
  //   if (userDet?.userDetails?.authority?.includes("2")) {
  //     setButton("Initiate");
  //   }
  // }, []);

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    const filteredData = data.filter((transaction) => {
      // Filter based on selected tab
      let isTabMatched = false;
      switch (selectedTab) {
        case "All":
          isTabMatched = true;
          break;
        case "Success":
          isTabMatched = transaction.fileUploadStatus === 3;
          break;
        case "Scheduled":
          isTabMatched = transaction.fileUploadStatus === 4;
          break;
        case "File Processing":
          isTabMatched = transaction.fileUploadStatus === 2;
          break;
        case "Cancelled":
          isTabMatched = transaction.fileUploadStatus === 5;
          break;
        // default:
        //   isTabMatched = true;
      }

      if (!isTabMatched) return false;

      // Filter based on search query
      const lowercasedSearch = searchQuery.toLowerCase();
      return (
        transaction.name.toLowerCase().includes(lowercasedSearch) ||
        transaction.description.toLowerCase().includes(lowercasedSearch) ||
        transaction.uploadedDate.toLowerCase().includes(lowercasedSearch)
      );
    });
    console.log(filteredData);

    setFilteredTransactions(filteredData);
  }, [data, searchQuery, selectedTab]);

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "Success") {
      const filteredData = data.filter((transaction) =>
        tab === "Success" ? transaction.fileUploadStatus === 3 : ""
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Scheduled") {
      const filteredData = data.filter((transaction) =>
        tab === "Scheduled" ? transaction.fileUploadStatus === 4 : ""
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "File Processing") {
      const filteredData = data.filter((transaction) =>
        tab === "File Processing" ? transaction.fileUploadStatus === 2 : ""
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Cancelled") {
      const filteredData = data.filter((transaction) =>
        tab === "Cancelled" ? transaction.fileUploadStatus === 5 : ""
      );
      setFilteredTransactions(filteredData);
    }
  };

  const handleEditClick = (index: number) => {
    const clickedData: FileUpload = currentData[index];
    Swal.fire({
      icon: "warning",
      text: "Are you sure you want to cancel the selected scheduled transfer ?",
      showCancelButton: true, // Show the cancel button
      confirmButtonColor: "#007bff", // Set the confirm button color to btn
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowEscapeKey: true,
      allowEnterKey: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Cancell();
      }
    });
    const Cancell = async () => {
      try {
        const url = `${API_URL}/bulk/cancelScheduledFileProcess`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          fileUploadId: Number(clickedData?.fileUploadId),
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        if (data.status.statusCode === 0) {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "The selected scheduled transfer has been cancelled",
            showCancelButton: false, // Show the cancel button
            confirmButtonColor: "#007bff", // Set the confirm button color to btn
            confirmButtonText: "Ok",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
          Data();
        }
      } catch (error) {
        console.log(error);
      }
    };
  };

  // const handleDeleteClick = (index: number) => {
  //     const clickedData: MyData = currentData[index];
  //     // setDeleteData(() => clickedData.employeeCode);

  //     const deleteItem = async () => {
  //         try {
  //             const url = `${API_URL}/employee/delete`;
  //             const headers = {
  //                 "Content-Type": "application/json",
  //                 Authorization: `Bearer ${token}`,
  //             };
  //             const body = JSON.stringify({
  //                 // employeeCode: clickedData.employeeCode,
  //             });

  //             const options = {
  //                 method: "POST",
  //                 headers,
  //                 body,
  //             };

  //             let response = await fetch(url, options);
  //             let data = await response.json();
  //             if (data.status.statusCode === 0) {
  //                 Swal.fire({
  //                     icon: "success",
  //                     title: "Confirmation!!",
  //                     text: "The selected employee has been deleted",
  //                     showCancelButton: true,
  //                     confirmButtonColor: "#007bff",
  //                     confirmButtonText: "Ok",
  //                 });
  //                 PayeeData();
  //             }
  //         } catch (error) {
  //             console.log(error);
  //         }
  //     };

  //     Swal.fire({
  //         icon: "warning",
  //         title: "Confirmation",
  //         text: "Are you sure you want to delete the selected employee?",
  //         showCancelButton: true, // Show the cancel button
  //         confirmButtonColor: "#007bff", // Set the confirm button color to btn
  //         confirmButtonText: "Yes",
  //         cancelButtonColor: "#9fa6b2",
  //         cancelButtonText: "Cancel", // Text for the cancel button
  //     }).then((result) => {
  //         if (result.isConfirmed) {
  //             deleteItem();
  //         }
  //     });
  // };

  const handleDetailsClick = (index: number) => {
    const clickedData: FileUpload = currentData[index];
    setSelectedData(() => clickedData);
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  // const filteredData = data
  // const totalCount = filteredData?.length || 0;

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const Data = async () => {
    try {
      const url = `${API_URL}/bulk/viewUploadedFiles`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        pageSize: 1000,
        pageNumber: 1,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setData(data.results);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Data();
    ApiData.api = Data;
    DrawerComponent.reinitialization();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <h4 className="mb-5">Bulk Payment Dashboard</h4>
            <div className="card shadow p-5">
              <div className="d-flex gap-8">
                <div
                  className="card shadow-sm d-flex flex-grow-1 bg-gray-300"
                  style={{ maxWidth: "450px", minWidth: "450px" }}
                >
                  <div className="card-body p-10">
                    <div className="d-flex flex-column justify-content-center align-items-start gap-5 ">
                      <div className="d-flex flex-column card-body align-items-start justify-content-center">
                        <p
                          className="p-0 m-0 text-dark mb-5 fs-2x"
                          style={{ fontWeight: "bold" }}
                        >
                          {data.length}
                        </p>
                        <h4 className="p-0 m-0 text-dark mb-2">
                          Bulk Payments{" "}
                        </h4>
                      </div>
                      <div className="d-flex gap-5"></div>
                    </div>
                  </div>
                </div>

                <div
                  className="card shadow-sm d-flex flex-grow-1"
                  style={{ width: "100%" }}
                >
                  <div className="card-body p-5 flex-grow-1 p-0 m-0">
                    <div className="d-flex justify-content-between mb-3 flex-grow-1">
                      <h4 className="p-0 m-0">Bulk Pay Life Time</h4>
                    </div>
                    <br />

                    <div className="w-100 d-flex flex-row gap-7">
                      <div className="card shadow-sm bg-info w-100 p-10">
                        <div className="d-flex justify-content-start">
                          <h1 className="text-light fs-2x fw-bolder">
                            {data.length}
                            {/* {loans[selectedIndex]?.paidEmiAmount.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )} */}
                          </h1>
                          <br />
                          <br />
                        </div>
                        <div className="d-flex justify-content-start">
                          <p className="text-light fs-7 ">
                            {" "}
                            Bulk Payments Till Date
                          </p>
                        </div>
                      </div>
                      <div className=" card shadow-sm bg-primary w-100 p-10">
                        <div className="d-flex justify-content-start">
                          <h1 className="text-light fs-2x fw-bolder">
                            {data
                              ?.reduce((totalSum, fileUpload) => {
                                // Add the 'sum' property of each FileUpload object to the totalSum
                                return (
                                  totalSum + fileUpload.fileUploadResponse.sum
                                );
                              }, 0)
                              ?.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            <span className="fs-9">({baseCurrency})</span>
                          </h1>
                          {/* <h1 className="text-light fs-2x fw-bolder">
                        {baseCurrency}{" "}
                        {data?.reduce((totalSum, fileUpload) => {
                          // Add the 'sum' property of each FileUpload object to the totalSum
                          return totalSum + fileUpload.fileUploadResponse.sum;
                        }, 0)} */}
                          {/* </h1> */}
                          <br />
                          <br />
                        </div>
                        <div className="d-flex justify-content-start">
                          <p className="text-light fs-7">
                            {" "}
                            Total Bulk Payment Value
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <div className="card shadow">
                  <div className="py-2 card p-5">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="">
                        <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
                          <li className="nav-item" key={1}>
                            <a
                              className="nav-link active text-active-gray-800"
                              data-bs-toggle="tab"
                              href="#kt_tab_pane_1"
                              onClick={() => handleTabClick("Success")}
                            >
                              Success
                            </a>
                          </li>
                          <li className="nav-item" key={2}>
                            <a
                              className="nav-link text-active-gray-800"
                              data-bs-toggle="tab"
                              href="#kt_tab_pane_2"
                              onClick={() => handleTabClick("Scheduled")}
                            >
                              Scheduled
                            </a>
                          </li>
                          <li className="nav-item" key={3}>
                            <a
                              className="nav-link text-active-gray-800"
                              data-bs-toggle="tab"
                              href="#kt_tab_pane_3"
                              onClick={() => handleTabClick("File Processing")}
                            >
                              File Processing
                            </a>
                          </li>
                          <li className="nav-item" key={4}>
                            <a
                              className="nav-link text-active-gray-800"
                              data-bs-toggle="tab"
                              href="#kt_tab_pane_4"
                              onClick={() => handleTabClick("Cancelled")}
                            >
                              Cancelled
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="d-flex align-items-center p-3 gap-10">
                        <div className="d-flex align-items-center  position-relative my-1">
                          <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {/* <form autoComplete="off"> */}
                          <input
                            value={searchQuery}
                            onChange={handleSearchChange}
                            type="text"
                            className="form-control  p-3 border-secondary form-control-solid w-250px ps-15"
                            placeholder="Search payments"
                          />
                          {/* </form> */}
                        </div>
                        <div>
                          <Authorize
                            hasAnyPermission={["MENU_BULK_PAYMENT|ADD"]}
                          >
                            <button
                              className="btn"
                              onClick={() => setShowCreateAppModal(true)}
                            >
                              {button} Bulk Payment
                            </button>
                          </Authorize>
                          {/* <button className="btn" onClick={()=>{navigate("/money-movement/create-bulkpayments")}}>Create Bulk Payment</button> */}
                        </div>
                      </div>
                    </div>

                    {/* <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} /> */}

                    <div className="tab-content " id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="kt_tab_pane_1"
                        role="tabpanel"
                      >
                        <>
                          <div className="card p-2">
                            <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                              <thead className="bg-gray-200">
                                <tr className="text-start text-dark-600 fw-bold fs-6  ">
                                  {/* <th className="min-w-100px p-5">ID</th> */}
                                  <th className="min-w-100px p-5">
                                    Name of bulk payment
                                  </th>

                                  <th className="min-w-100px p-5 text-nowrap">
                                    Date
                                  </th>
                                  <th className="min-w-100px p-5 text-nowrap">
                                    Time
                                  </th>
                                  <th className="min-w-100px p-5 text-nowrap">
                                    Amount
                                  </th>
                                  <th className="min-w-100px p-5 text-nowrap">
                                    Status
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {currentData && currentData.length > 0 ? (
                                  currentData?.map((item, index) => {
                                    // if (item.fileUploadStatus === 3) {
                                    return (
                                      <tr
                                        key={index}
                                        onMouseOver={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "#f1f1f2";
                                          // target.style.transform =
                                          //   "translate3d(6px, -6px, 0)";
                                          // target.style.boxShadow =
                                          //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                        }}
                                        onMouseOut={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "white";
                                          // target.style.transform = "none";
                                          // target.style.boxShadow = "none";
                                        }}
                                      >
                                        <td
                                          className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline"
                                          onClick={() =>
                                            handleDetailsClick(index)
                                          }
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="left"
                                          data-bs-dismiss="click"
                                          data-bs-trigger="hover"
                                          id="kt_modal_toggle_bulk"
                                          style={{ cursor: "pointer" }}
                                        >
                                          {item?.name}
                                        </td>
                                        <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                          {item?.uploadedDate &&
                                            new Date(
                                              item.uploadedDate
                                            ).toLocaleString("en-US", {
                                              month: "short",
                                              day: "numeric",
                                              year: "numeric",
                                              // hour: '2-digit',
                                              // minute: '2-digit',
                                              // second: '2-digit',
                                              // hour12: true,
                                            })}
                                        </td>
                                        <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                          {item?.uploadedDate &&
                                            new Date(
                                              item.uploadedDate
                                            ).toLocaleString("en-US", {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              hour12: false,
                                            })}
                                        </td>

                                        <td className="p-5 text-gray-600">
                                          {baseCurrency}{" "}
                                          {item?.fileUploadResponse.sum.toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </td>
                                        <td className="p-5 text-primary">
                                          Success
                                        </td>
                                      </tr>
                                    );
                                    // }
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6} className="text-center">
                                      No Data Found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="kt_tab_pane_2"
                        role="tabpanel"
                      >
                        <>
                          <div className="card p-2">
                            <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                              <thead className="bg-gray-200">
                                <tr className="text-start text-dark-600 fw-bold fs-6  ">
                                  {/* <th className="min-w-100px p-5">ID</th> */}
                                  <th className="min-w-100px p-5">
                                    Name of bulk payment
                                  </th>

                                  <th className="min-w-100px p-5 text-nowrap">
                                    Created date
                                  </th>
                                  <th className="min-w-100px p-5 text-nowrap">
                                    Scheduled date
                                  </th>
                                  <th className="min-w-100px p-5 text-nowrap">
                                    Amount
                                  </th>
                                  <th className="min-w-100px p-5 text-nowrap">
                                    Status
                                  </th>
                                  <Authorize
                                    hasAnyPermission={[
                                      "MENU_BULK_PAYMENT|DELETE",
                                    ]}
                                  >
                                    <th className="min-w-100px p-5 text-nowrap">
                                      Action
                                    </th>
                                  </Authorize>
                                </tr>
                              </thead>

                              <tbody>
                                {currentData && currentData.length > 0 ? (
                                  currentData?.map((item, index) => {
                                    // if (item.fileUploadStatus === 4) {
                                    return (
                                      <tr
                                        key={index}
                                        onMouseOver={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "#f1f1f2";
                                          // target.style.transform =
                                          //   "translate3d(6px, -6px, 0)";
                                          // target.style.boxShadow =
                                          //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                        }}
                                        onMouseOut={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "white";
                                          // target.style.transform = "none";
                                          // target.style.boxShadow = "none";
                                        }}
                                      >
                                        <td
                                          className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleDetailsClick(index)
                                          }
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="left"
                                          data-bs-dismiss="click"
                                          data-bs-trigger="hover"
                                          id="kt_modal_toggle_bulk"
                                        >
                                          {item?.name}
                                        </td>
                                        <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                          {item?.uploadedDate &&
                                            new Date(
                                              item.uploadedDate
                                            ).toLocaleString("en-US", {
                                              month: "short",
                                              day: "numeric",
                                              year: "numeric",
                                              // hour: '2-digit',
                                              // minute: '2-digit',
                                              // second: '2-digit',
                                              // hour12: true,
                                            })}{" "}
                                          |{" "}
                                          {item?.uploadedDate &&
                                            new Date(
                                              item.uploadedDate
                                            ).toLocaleString("en-US", {
                                              // month: "short",
                                              // day: "numeric",
                                              // year: "numeric",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              second: "2-digit",
                                              hour12: true,
                                            })}
                                        </td>
                                        <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                          {item?.jobScheduledTime &&
                                            new Date(
                                              item.jobScheduledTime
                                            ).toLocaleString("en-US", {
                                              month: "short",
                                              day: "numeric",
                                              year: "numeric",
                                              // hour: "2-digit",
                                              // minute: "2-digit",
                                              // second: "2-digit",
                                              // hour12: true,
                                            })}{" "}
                                          |{" "}
                                          {item?.jobScheduledTime &&
                                            new Date(
                                              item.jobScheduledTime
                                            ).toLocaleString("en-US", {
                                              // month: 'short',
                                              // day: 'numeric',
                                              // year: 'numeric',
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              second: "2-digit",
                                              hour12: true,
                                            })}
                                        </td>

                                        <td className="p-5 text-gray-600">
                                          {baseCurrency}{" "}
                                          {item?.fileUploadResponse.sum.toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </td>
                                        <td className="p-5 text-warning">
                                          Scheduled
                                        </td>
                                        <Authorize
                                          hasAnyPermission={[
                                            "MENU_BULK_PAYMENT|DELETE",
                                          ]}
                                        >
                                          <td className="p-5 text-gray-600 p-2">
                                            <div className="d-flex gap-10">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={tooltipDelete}
                                              >
                                                <i
                                                  className="bi bi-x-circle text-danger fs-2"
                                                  onClick={() =>
                                                    handleEditClick(index)
                                                  }
                                                ></i>
                                              </OverlayTrigger>
                                            </div>
                                          </td>
                                        </Authorize>
                                      </tr>
                                    );
                                    // }
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6} className="text-center">
                                      No Data Found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="kt_tab_pane_3"
                        role="tabpanel"
                      >
                        <>
                          <div className="card p-2">
                            <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                              <thead className="bg-gray-200">
                                <tr className="text-start text-dark-600 fw-bold fs-6  ">
                                  {/* <th className="min-w-100px p-5">ID</th> */}
                                  <th className="min-w-100px p-5">
                                    Name of bulk payment
                                  </th>

                                  <th className="min-w-100px p-5 text-nowrap">
                                    Date
                                  </th>
                                  <th className="min-w-100px p-5 text-nowrap">
                                    Time
                                  </th>
                                  <th className="min-w-100px p-5 text-nowrap">
                                    Amount
                                  </th>
                                  <th className="min-w-100px p-5 text-nowrap">
                                    Status
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {currentData && currentData.length > 0 ? (
                                  currentData?.map((item, index) => {
                                    // if (item.fileUploadStatus === 2) {
                                    return (
                                      <tr
                                        key={index}
                                        onMouseOver={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "#f1f1f2";
                                          // target.style.transform =
                                          //   "translate3d(6px, -6px, 0)";
                                          // target.style.boxShadow =
                                          //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                        }}
                                        onMouseOut={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "white";
                                          // target.style.transform = "none";
                                          // target.style.boxShadow = "none";
                                        }}
                                      >
                                        <td
                                          className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleDetailsClick(index)
                                          }
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="left"
                                          data-bs-dismiss="click"
                                          data-bs-trigger="hover"
                                          id="kt_modal_toggle_bulk"
                                        >
                                          {item?.name}
                                        </td>
                                        <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                          {item?.uploadedDate &&
                                            new Date(
                                              item.uploadedDate
                                            ).toLocaleString("en-US", {
                                              month: "short",
                                              day: "numeric",
                                              year: "numeric",
                                              // hour: '2-digit',
                                              // minute: '2-digit',
                                              // second: '2-digit',
                                              // hour12: true,
                                            })}
                                        </td>
                                        <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                          {item?.uploadedDate &&
                                            new Date(
                                              item.uploadedDate
                                            ).toLocaleString("en-US", {
                                              // month: 'short',
                                              // day: 'numeric',
                                              // year: 'numeric',
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              second: "2-digit",
                                              hour12: true,
                                            })}
                                        </td>

                                        <td className="p-5 text-gray-600">
                                          {baseCurrency}{" "}
                                          {item?.fileUploadResponse.sum.toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </td>
                                        <td className="p-5 text-gray-600">
                                          File processing
                                        </td>
                                      </tr>
                                    );
                                    // }
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6} className="text-center">
                                      No Data Found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="kt_tab_pane_4"
                        role="tabpanel"
                      >
                        <>
                          <div className="card p-2">
                            <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                              <thead className="bg-gray-200">
                                <tr className="text-start text-dark-600 fw-bold fs-6  ">
                                  {/* <th className="min-w-100px p-5">ID</th> */}
                                  <th className="min-w-100px p-5">
                                    Name of bulk payment
                                  </th>

                                  <th className="min-w-100px p-5 text-nowrap">
                                    Date
                                  </th>
                                  <th className="min-w-100px p-5 text-nowrap">
                                    Time
                                  </th>
                                  <th className="min-w-100px p-5 text-nowrap">
                                    Amount
                                  </th>
                                  <th className="min-w-100px p-5 text-nowrap">
                                    Status
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {currentData && currentData.length > 0 ? (
                                  currentData?.map((item, index) => {
                                    // if (item.fileUploadStatus === 5) {
                                    return (
                                      <tr
                                        key={index}
                                        onMouseOver={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "#f1f1f2";
                                          // target.style.transform =
                                          //   "translate3d(6px, -6px, 0)";
                                          // target.style.boxShadow =
                                          //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                        }}
                                        onMouseOut={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "white";
                                          // target.style.transform = "none";
                                          // target.style.boxShadow = "none";
                                        }}
                                      >
                                        <td
                                          className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                                          onClick={() =>
                                            handleDetailsClick(index)
                                          }
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="left"
                                          data-bs-dismiss="click"
                                          data-bs-trigger="hover"
                                          id="kt_modal_toggle_bulk"
                                        >
                                          {item?.name}
                                        </td>
                                        <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                          {item?.uploadedDate &&
                                            new Date(
                                              item.uploadedDate
                                            ).toLocaleString("en-US", {
                                              month: "short",
                                              day: "numeric",
                                              year: "numeric",
                                              // hour: '2-digit',
                                              // minute: '2-digit',
                                              // second: '2-digit',
                                              // hour12: true,
                                            })}
                                        </td>
                                        <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                          {item?.uploadedDate &&
                                            new Date(
                                              item.uploadedDate
                                            ).toLocaleString("en-US", {
                                              // month: 'short',
                                              // day: 'numeric',
                                              // year: 'numeric',
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              second: "2-digit",
                                              hour12: true,
                                            })}
                                        </td>

                                        <td className="p-5 text-gray-600">
                                          {baseCurrency}{" "}
                                          {item?.fileUploadResponse.sum.toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </td>
                                        <td className="p-5 text-danger">
                                          Cancelled
                                        </td>
                                      </tr>
                                    );
                                    // }
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6} className="text-center">
                                      No Data Found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="p-5">
                        <ul className="pagination">
                          <li
                            className={`page-item double-arrow ${
                              currentPage === 1 ? "disabled" : ""
                            }`}
                            key={0}
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() => handlePageChange(1)}
                            >
                              <i className="previous"></i>
                              <i className="previous"></i>
                            </a>
                          </li>
                          <li
                            className={`page-item previous ${
                              currentPage === 1 ? "disabled" : ""
                            }`}
                            key={1}
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              <i className="previous"></i>
                            </a>
                          </li>
                          {renderPageNumbers()}
                          <li
                            className={`page-item next ${
                              currentPage ===
                              Math.ceil(
                                filteredTransactions?.length / itemsPerPage
                              )
                                ? "disabled"
                                : ""
                            }`}
                            key={2}
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              <i className="next"></i>
                            </a>
                          </li>
                          <li
                            className={`page-item double-arrow ${
                              currentPage ===
                              Math.ceil(
                                filteredTransactions?.length / itemsPerPage
                              )
                                ? "disabled"
                                : ""
                            }`}
                            key={3}
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() =>
                                handlePageChange(
                                  Math.ceil(
                                    filteredTransactions?.length / itemsPerPage
                                  )
                                )
                              }
                            >
                              <i className="next"></i>
                              <i className="next"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="p-5 d-flex align-items-center">
                        <label
                          htmlFor="itemsPerPage"
                          style={{ fontWeight: "bold" }}
                        >
                          Total: {filteredTransactions?.length}&nbsp;&nbsp;
                        </label>
                        <select
                          className=" cursor-pointer"
                          id="itemsPerPage"
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={75}>75</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CreateAppModal
            show={showCreateAppModal}
            handleClose={() => setShowCreateAppModal(false)}
          />
          <BukDetailsModal Data={selectedData} />
        </>
      )}
    </div>
  );
}
