import axios from "axios";
import { UserModel } from "./_models";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const API_URL = process.env.REACT_APP_WEB_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const LOGIN_URL = `${API_URL}/login_v1`;
export const REGISTER_URL = `${API_URL}/signup`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;

// Server should return AuthModel
export async function login(email: string, password: string) {
  const data = {
    username: email,
    password: password,
  };

  try {
    const response = await axios.post(LOGIN_URL, data, {
      headers: {
        "Content-Type": "application/json",
        tenant_id: "eazy_bank",
      },
    });

    // if (response?.data?.status?.statusCode === 0) {
    //   toast.success('Login successful!');
    //   return response.data;
    // }
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const status = error.response?.status;
      if (status === 401) {
        toast.error("Unauthorized: Incorrect email or password.", {
          toastId: "login-error-401",
        });
      } else if (status === 503) {
        toast.error("Service Unavailable: Try again later.", {
          toastId: "login-error-503",
        });
      }
    } else {
      toast.error("An unexpected error occurred.", {
        toastId: "login-error-unexpected",
      });
    }
    throw error;
  }
}

export const enhancedFetch = async (url: string, options: any) => {
  // Add common parameters to the body
  const enhancedOptions = {
    ...options,
    body: JSON.stringify({
      ...JSON.parse(options.body),
      channel: "WEB",
      appType: "SMEWEB",
    }),
  };

  try {
    const response = await fetch(url, enhancedOptions);

    if (response.ok) {
      if (!response.headers.get("content-disposition")) {
        const res = await response.clone().json();
       
        if (res?.status?.svcErrorCode === "EMCORE_ACC_LOCKED") {
          sessionStorage.clear();
          Swal.fire({
            icon: "warning",
            text: `${res?.status?.messageDescription}`,
            allowOutsideClick: false,
          }).then((res) => {
            console.log(res);

            window.location.href = "/auth";
          });
        }
      if (
        res?.status?.statusCode === 1 &&
        res?.status?.svcErrorCode != "EMCORE_ACC_LOCKED"
      ) {
        toast.error(res?.status?.messageDescription, {
          toastId: "login-error-500",
          position: "top-center",
          theme: "colored",
        });

      }
    }

      return response;
    } else {
      switch (response.status) {
        case 401:
          toast.error("Unauthorized", { toastId: "login-error-401" });
          // Handle 401 Unauthorized
          break;
        case 500:
          toast.error(`Something went wrong.\nPlease try again later!`, {
            toastId: "login-error-500",
          });
          // Handle 503 Service Unavailable
          break;
        case 503:
          toast.error("Service Unavailable: Try again later.", {
            toastId: "login-error-503",
          });
          // Handle 503 Service Unavailable
          break;

        // Handle other status codes as needed
      }
      throw new Error(`Request failed with status ${response?.status}`);
    }
  } catch (error) {
    if (!navigator.onLine) {
      toast.error("Internet connection lost!", {
        toastId: "login-error-offline",
      });
    }
    // else {
    //   toast.error("Something went wrong, try again later", {
    //     toastId: "login-error-unexpected",
    //   });
    // }
    throw error;
  }
};

// Server should return AuthModel
export function register(isdCode: string, mobile: string) {
  let data = JSON.stringify({
    isdCode,
    mobile,
  });

  return axios.post(REGISTER_URL, data, {
    headers: {
      "Content-Type": "application/json",
      tenant_id: "eazy_bank",
    },
  });
}

const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
const token = tokenData ? tokenData.token : null;

export const signOut = async () => {
  try {
    const url = `${API_URL}/logout`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const body = JSON.stringify({});

    const options = {
      method: "POST",
      headers,
      body,
    };

    let response = await enhancedFetch(url, options);
    let data = await response.json();
    console.log(data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  });
}
