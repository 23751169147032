import { useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import jsPDF from "jspdf";
import { PayBillResponse } from "../../interface/Interface";
import Xfin from "../../../_metronic/assets/Icons/xfin.png";

interface EReceiptProps {
  data: PayBillResponse;
}

export function EReceiptModal({ data }: EReceiptProps) {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const downloadPDF = (billData: PayBillResponse) => {
    const img = new Image();
    img.src = Xfin;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const imageData = canvas.toDataURL("image/png");

        const doc = new jsPDF();
        doc.setFontSize(14);
        doc.setFont("bold");

        let yPosition = 20;
        const lineHeight = 10;
        const keyWidth = 50; // Width for the keys

        doc.text("Bill Receipt", 105, yPosition, { align: "center" });

        yPosition += lineHeight * 2;
        doc.setFontSize(12);
        doc.setFont("normal");

        Object.entries(billData).forEach(([key, value]) => {
          const keyText = `${capitalizeFirstLetter(key)}:`;
          const valueText = value.toString(); // Ensure the value is a string
          doc.text(keyText, 20, yPosition);
          doc.text(valueText, 20 + keyWidth, yPosition); // Align values after a fixed width
          yPosition += lineHeight;
        });

        // Adding the image to the PDF
        const imageWidth = 40;
        const imageHeight = 12;
        const imageX = 20;
        const imageY = 10;

        doc.addImage(imageData, "PNG", imageX, imageY, imageWidth, imageHeight);

        doc.save("bill-details.pdf");
      } else {
        console.error("Unable to get 2D context");
      }
    };
  };

  // Helper function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleDeleteCloseModal = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
    // handleDeleteConfirmation()
  };

  const getIconClass = () => {
    switch (data?.["Biller Type"]) {
      case "Electricity":
        return "bi bi-lightning-charge-fill fs-1 text-warning";
      case "Water":
        return "bi bi-droplet-fill fs-1 text-primary";
      case "Internet":
        return "bi bi-wifi fs-1 text-dark";
      case "Airtime":
        return "bi bi-phone fs-1 text-info";
      default:
        return ""; // Default or error class
    }
  };

  return (
    <>
      <div
        className="modal fade"
        tabIndex={-1}
        id="e_receipt_modal"
        ref={modalRef}
      >
        <div className="modal-dialog modal-dialog-centered w-400px" >
          <div className="modal-content">
            <div className="modal-head d-flex flex-row justify-content-between align-items-center p-4 pb-0 mx-3">
              <>
                {/* <i
                  className="bi bi-arrow-left-short fs-1 me-2"
                  onClick={() => {
                    handleDeleteCloseModal();
                    handleBack();
                  }}
                ></i> */}
                <p className="fs-3 pb-0 mt-4">E-Receipt</p>
              </>
              {/* <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div> */}
            </div>

            {/* begin::Body */}
            <div className="modal-body pt-0">
              <div className="d-flex flex-column">
                <div
                  className="card shadow-sm p-5"
                  style={{ maxHeight: "500px", overflow: "auto" }}
                >
                  <div className="d-flex justify-content-center p-5">
                    <i className={getIconClass()}></i>
                  </div>
                  <hr />

                  {Object.entries(data).map(([key, value]) => (
                    <div
                      key={key}
                      className="d-flex justify-content-between mb-2"
                    >
                      <div>
                        <p>{key}</p>
                      </div>
                      <div>
                        <p
                          className={`fw-bold badge badge-${
                            key === "Status" && String(value) === "Success"
                              ? "success"
                              : key === "Status" && String(value) === "Failed"
                                ? "danger"
                                : ""
                          }`}
                        >
                          {String(value)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="d-flex justify-content-center mt-5">
                <button
                  className="btn rounded"
                  style={{
                    width: "380px",
                  }}
                  onClick={() => downloadPDF(data)}
                >
                  Download E-Receipt
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
