import { useState, useEffect, useRef } from "react";
import React from "react";
import { Modal } from "bootstrap";
import Swal from "sweetalert2";
import { LoanApi, LoanDetails } from "./LoanDashboard";

import {
  RuleMessage,
  TransferResponse,
  User,
} from "../TransferMoney/TransferModal";

import { E_ReceiptModal } from "./LoanReceipt";
import OTPInput from "react-otp-input";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Authorize from "../../modules/auth/components/Authorize";
import { ThreeDots } from "react-loader-spinner";
import { DrawerComponent } from "../../../_metronic/assets/ts/components";

interface Bank {
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
}

interface Receipt {
  "Applicant Name": string;
  "Loan Account Number": string;
  "Repayment Amount": string;
  "Transaction Id": string;
  Date: string;
  Time: string;
  Type: string;
  Status: string;
}

export interface PaymentData {
  userId: string;
  tenantId: string;
  loanGuid: string;
  paymentType: number;
  amount: number;
  senderAccountNumber: string;
  interest: number;
  transactionPIN: string;
  receipt: Receipt;
}

interface Props {
  screen: string;
  setScreen: (scrNum: string) => void;
  loan: LoanDetails;
}

export function LoanDetailsModal({ screen, setScreen, loan }: Props) {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [button, setButton] = useState("Next");
  const [approvers, setApprovers] = useState<User[]>([]);
  const [authority, setAuthority] = useState(0);
  const [show, setShow] = useState(true);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef(null);

  const [accounts, setAccounts] = useState<Bank[]>([]);
  const [isActive, setIsActive] = useState(false);
  const selected = "Select Account";
  const [selectedRadio, setSelectedRadio] = useState(
    loan?.paymentInfo?.totalDueAmount !== 0 ? "1" : "2"
  );

  const [selectedAccount, setSelectedAccount] = useState<Bank | null>(null);
  const [approverName, setApproverName] = useState("");
  const [approverGuid, setApproverGuid] = useState("");
  const [apperrMsg, setAppErrMsg] = useState(false);
  const [pin, setPin] = useState("");
  const [payAmount, setPayAmount] = useState(
    loan?.paymentInfo?.totalDueAmount === 0
      ? loan?.paymentInfo?.totalOutstandingAmount
      : loan?.paymentInfo?.totalDueAmount
  );
  const [intrest, setIntrest] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [accountErr, setAccountErr] = useState(false);
  const [successData, setSuccessData] = useState<PaymentData>({
    userId: "",
    tenantId: "",
    loanGuid: "",
    paymentType: 0,
    amount: 0,
    senderAccountNumber: "",
    interest: 0,
    transactionPIN: "",
    receipt: {
      "Applicant Name": "",
      "Loan Account Number": "",
      "Repayment Amount": "",
      "Transaction Id": "",
      Date: "",
      Time: "",
      Type: "",
      Status: "",
    },
  });

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  // const [pin, setPin] = useState("");

  const [ruleData, setRuleData] = useState<RuleMessage | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const handleClickOutsideModal = (event: MouseEvent) => {
    const modalElement = modalRef?.current as HTMLElement | null;
    const buttonInsideModal = event.target as HTMLElement;

    // Check if the clicked element is inside the modal or a specific button
    if (
      modalElement &&
      !modalElement.contains(buttonInsideModal) &&
      !buttonInsideModal.classList.contains("btn")
    ) {
      // Clicked outside modal and not on the specific button, so hide the modal and reset the form
      reset();
      // Additional hide modal logic here
    }
  };

  useEffect(() => {
    // Add click event listener to document to handle clicks outside the modal
    document.addEventListener("click", handleClickOutsideModal);
    return () => {
      // Cleanup: remove the click event listener when the component unmounts
      document.removeEventListener("click", handleClickOutsideModal);
    };
  }, []);

  useEffect(() => {
    setPayAmount(
      loan?.paymentInfo?.totalDueAmount === 0
        ? loan?.paymentInfo?.totalOutstandingAmount
        : loan?.paymentInfo?.totalDueAmount
    );
    setIntrest(
      loan?.paymentInfo?.interestFromTotalDueAmount === 0
        ? loan?.paymentInfo?.interestFromTotalOutstandingAmount
        : loan?.paymentInfo?.interestFromTotalDueAmount
    );
    setSelectedRadio(loan?.paymentInfo?.totalDueAmount !== 0 ? "1" : "2");
  }, [loan]);

  const handleDropdownToggle = () => {
    setIsActive(!isActive);
  };

  const ApproverList = async (amount: string) => {
    try {
      const url = `${API_URL}/team/getApproverList`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        currency: baseCurrency,
        amount: amount,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovers(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (userDet?.userDetails?.authority?.includes("2")) {
  //     const debounceTimeout = setTimeout(() => {
  //       if (+payAmount) {
  //         ApproverList(payAmount.toString());
  //       }
  //     }, 500);

  //     return () => clearTimeout(debounceTimeout);
  //   }
  // }, [payAmount]);

  useEffect(() => {
    // if (userDet?.userDetails?.authority?.includes("2")) {
    //   ApproverList(userDet?.userDetails?.zarLimit);
    //   setButton("Initiate");
    // }
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  const handleEReceipt = () => {
    const modalElement = document.getElementById("e_receipt");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleApprover = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value);
    setAppErrMsg(false);
    setApproverGuid(e.target.value);
    const appName = approvers.find((app) => app.userGuid === e.target.value);
    if (appName) setApproverName(appName.name);
  };

  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await fetch(url, options);
      let data = await response.json();
      setAccounts(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const repayLoan = async () => {
    try {
      const url = `${API_URL}/loan/repayLoan`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        loanGuid: loan?.loanGuid,
        paymentType: +selectedRadio,
        amount: payAmount,
        interest: intrest,
        senderAccountNumber: selectedAccount?.accountNumber,
        transactionPIN: pin,
        ...(button === "Initiate" && {
          beneficiaryName: selectedAccount?.accountNumber,
          currency: selectedAccount?.currency,
          initiatedTxn: true,
        }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await fetch(url, options);
      let data = await response.json();

      if (data?.status?.statusCode === 0) {
        LoanApi.api();
        LoanApi.detailsApi(loan.loanGuid);
        LoanApi.activeApi();
        setSuccessData(data?.message);
        if (button === "Initiate") {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            html: "Initiation successful <br> Pending for approval",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Transfer Successful",
            text: "Loan Repayment successfully done",
            showCancelButton: true, // Show the cancel button
            confirmButtonColor: "#007bff", // Set the confirm button color to btn
            confirmButtonText: "View E-Receipt",
            cancelButtonColor: "#9fa6b2",
            cancelButtonText: "Close",
            allowEscapeKey: true,
            allowEnterKey: true, // Text for the cancel button
          }).then((result) => {
            if (result.isConfirmed) {
              handleEReceipt();
            }
          });
        }
      }
      // if (data?.status?.statusCode === 1) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error",
      //     text: data?.status?.messageDescription,
      //     allowEscapeKey: true,
      //     allowEnterKey: true,
      //   });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const getRules = async () => {
    try {
      const url = `${API_URL}/transaction/checkTxnDetails`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        amount: payAmount,
        currency: baseCurrency,
        transactionType: "REPAY_LOAN",
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setRuleData(data?.message);
      if (data.message?.processingType === "PROCESS") {
        setButton("Pay");
      }
      if (data.message?.processingType === "SEND_FOR_APPROVAL") {
        setButton("Initiate");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const submit = () => {
    repayLoan();
  };

  const reset = () => {
    setScreen("1");
    setIsActive(false);
    setSelectedAccount(null);
    setPin("");
    setErrMsg("");
    setAccountErr(false);
    setAppErrMsg(false);
    setActiveInput(0);
    setOtp(["", "", "", ""]);
    setRuleData(null);
    setButton("Next");
    setLoading(false);
  };

  useEffect(() => {
    DrawerComponent.reinitialization();
  }, []);

  var drawerEl = document.querySelector("#kt_modal_loan");
  var drawer = DrawerComponent.getInstance(drawerEl!?.id);
  drawer?.on("kt.drawer.hide", function () {
    setRuleData(null);
    setButton("Next");
    setLoading(false);
  });

  return (
    <>
      <div
        ref={modalRef}
        id="kt_modal_loan"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_modal_toggle_loanDetails"
        data-kt-drawer-close="#kt_close"
      >
        <div className="card shadow-none rounded-0 w-100 p-5">
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_close"
              style={{ height: "24px", width: "24px" }}
              onClick={() => {
                reset();
              }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-gray-600 p-0">
              {screen === "1" ? "Loan Details" : "Loan Payment Page"}
            </h5>
          </div>
          <div className={`card-body`}>
            {screen === "1" && (
              <>
                <div className="card d-flex flex-row justify-content-around px-5 py-7 mb-5 rounded bg-gray-200 ">
                  <div className="d-flex flex-column justify-content-between gap-5">
                    <div className="d-flex flex-column">
                      <p className="m-0 fw-bold">Loan Amount</p>
                      <p>
                        {baseCurrency}{" "}
                        {loan?.loanAmount?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                    <div className="d-flex flex-column">
                      <p className="m-0 fw-bold">EMI</p>
                      <p>
                        {baseCurrency}{" "}
                        {loan?.emi?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <div className="d-flex flex-column">
                      <p className="m-0 fw-bold">Loan Date</p>
                      <p>
                        {new Date(loan?.requestedDate)?.toLocaleDateString(
                          "en-US",
                          { month: "short", day: "2-digit", year: "numeric" }
                        )}
                      </p>
                    </div>
                    <div className="d-flex flex-column">
                      <p className="m-0 fw-bold">Interest Rate</p>
                      <p>
                        {loan?.interestRate?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        %
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-between">
                  <p className="fw-bold ">Repayment Information</p>
                  <p></p>
                </div>
                <hr className="mt-0" />
                <div className="d-flex flex-row justify-content-between">
                  <p className="fw-bold ">Total No. of Installments</p>
                  <p className="fw-bold ">{loan?.noOfInstallment}</p>
                </div>
                <hr className="mt-0" />
                {loan?.repaymentSchedules?.map((emi) => (
                  <div
                    className={`d-flex flex-row justify-content-between my-2 ${
                      emi?.paymentStatus === 6 || emi?.paymentStatus === 7
                        ? "text-danger"
                        : ""
                    }`}
                    style={{
                      opacity:
                        emi?.paymentStatus === 1 || emi?.paymentStatus === 4
                          ? 0.5
                          : 1,
                    }}
                  >
                    <div>
                      <p className="m-0 ">
                        Repayment ({emi?.month}/
                        {loan?.repaymentSchedules?.length})
                      </p>
                      <p>
                        {/* {emi?.dueDate} */}
                        {new Date(emi?.dueDate)?.toLocaleDateString("en-US", {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        })}{" "}
                        {emi?.paymentStatus === 6 || emi?.paymentStatus === 7
                          ? "(Overdue)"
                          : ""}
                      </p>
                    </div>
                    <div>
                      <p className="text-end m-0">Amount</p>
                      <p className="text-end m-0">
                        ZAR{" "}
                        {emi?.monthlyPayment?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                      {/* {index === 0 && (
                        <p>
                          Includes Processing Fee<sup>*</sup>
                        </p>
                      )} */}
                    </div>
                  </div>
                ))}
                <hr />
                <div className="d-flex flex-row justify-content-between">
                  <p className="fw-bold ">Total Repayment</p>
                  <p className="fw-bold text-primary">
                    {baseCurrency}{" "}
                    {loan?.totalRepaymentAmount?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <hr
                  className={`mt-0 ${
                    loan?.remainingAmount !== 0 && loan?.loanStatus === 2
                      ? ""
                      : "mb-20"
                  }`}
                />
                {loan?.remainingAmount !== 0 && loan?.loanStatus === 2 && (
                  <>
                    <div className="d-flex flex-row justify-content-between">
                      <p className="fw-bold ">Remaining Amount</p>
                      <p className="fw-bold">
                        {baseCurrency}{" "}
                        {loan?.remainingAmount?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                    <Authorize hasAnyPermission={["MENU_LOAN|ADD"]}>
                      <button
                        className="btn rounded mt-5"
                        style={{
                          width: "380px",
                        }}
                        onClick={() => {
                          if (authority === 3) {
                            Swal.fire({
                              icon: "warning",

                              html: `
                          <p style="font-weight: bold; text-align: center; color: red;">
                            You do not have access to this feature.
                          </p>
                       
                          <p style="font-weight: bold; text-align: center; color: red;">
                            Please contact admin.
                          </p>
                        `,
                              allowEscapeKey: true,
                              allowEnterKey: true,
                            });
                          } else {
                            getRules();
                            setScreen("2");
                          }
                        }}
                      >
                        {button}
                      </button>
                    </Authorize>
                  </>
                )}
              </>
            )}
            {screen === "2" && (
              <>
                <form autoComplete="off">
                  <div className="d-flex flex-column gap-5 ">
                    <label className="form-label">
                      How much would you like to pay?
                    </label>
                    <div
                      className="form-check d-flex justify-content-between p-4 rounded shadow-sm"
                      style={{
                        opacity:
                          loan?.paymentInfo?.totalDueAmount !== 0 ? "1" : "0.5",
                        pointerEvents:
                          loan?.paymentInfo?.totalDueAmount !== 0
                            ? "auto"
                            : "none",
                        cursor:
                          loan?.paymentInfo?.totalDueAmount !== 0
                            ? "allowd"
                            : "not-allowed",
                      }}
                      aria-disabled={loan?.paymentInfo?.totalDueAmount === 0}
                    >
                      <label className="fw-bold" htmlFor="Radio1">
                        Current Due (ZAR{" "}
                        {loan?.paymentInfo?.totalDueAmount?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                        )
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        value={"1"}
                        onChange={() => {
                          setPayAmount(loan?.paymentInfo?.totalDueAmount);
                          setIntrest(
                            loan?.paymentInfo?.interestFromTotalDueAmount
                          );
                          setSelectedRadio("1");
                          getRules();
                        }}
                        checked={
                          selectedRadio === "1" ||
                          (loan?.paymentInfo?.totalDueAmount !== 0 &&
                            selectedRadio !== "2")
                        }
                      />
                    </div>

                    {selectedRadio === "1" &&
                      payAmount !== 0 &&
                      loan?.paymentInfo?.currentDueAmount !== 0 && (
                        <>
                          <p className="mb-0 fw-bold">Current due breakup</p>
                          <div className="border border-dashed rounded p-3 border-dark mb-3">
                            <div className="d-flex flex-row justify-content-between">
                              <p className="fw-bold mb-0 text-danger">
                                {loan?.paymentInfo?.currentDueDate}
                              </p>
                              <p className=" mb-1 text-danger">
                                {baseCurrency}{" "}
                                {loan?.paymentInfo?.currentDueAmount?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </p>
                            </div>
                            {loan?.paymentInfo?.previousDueAmount !== 0 && (
                              <div className="d-flex flex-row justify-content-between">
                                <p className="fw-bold mb-0 text-danger">
                                  Previous Due
                                </p>
                                <p className="mb-1 text-danger">
                                  {baseCurrency}{" "}
                                  {loan?.paymentInfo?.previousDueAmount?.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </p>
                              </div>
                            )}

                            <div className="d-flex flex-row justify-content-between">
                              <p className="fw-bold mb-0">Late Fees</p>
                              <p className="mb-0">
                                {baseCurrency}{" "}
                                {loan?.paymentInfo?.lateFee?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio2">
                        Total Outstanding ({baseCurrency}{" "}
                        {loan?.paymentInfo?.totalOutstandingAmount?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                        )
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        value={"2"}
                        onChange={() => {
                          setPayAmount(
                            loan?.paymentInfo?.totalOutstandingAmount
                          );
                          setIntrest(
                            loan?.paymentInfo
                              ?.interestFromTotalOutstandingAmount
                          );
                          setSelectedRadio("2");
                        }}
                        checked={
                          selectedRadio === "2" ||
                          (loan?.paymentInfo?.totalDueAmount === 0 &&
                            selectedRadio !== "1")
                        }
                      />
                    </div>
                    {selectedRadio === "2" &&
                      payAmount !== 0 &&
                      loan?.paymentInfo?.currentDueAmount !== 0 && (
                        <>
                          <p className="mb-0 fw-bold">
                            Total Outstanding breakup
                          </p>
                          <div className="border border-dashed rounded p-3 border-dark mb-3">
                            <div className="d-flex flex-row justify-content-between">
                              <p className="fw-bold mb-0 text-danger">
                                {loan?.paymentInfo?.currentDueDate}
                              </p>
                              <p className=" mb-1 text-danger">
                                {baseCurrency}{" "}
                                {loan?.paymentInfo?.currentDueAmount?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </p>
                            </div>
                            {loan?.paymentInfo?.previousDueAmount !== 0 && (
                              <div className="d-flex flex-row justify-content-between">
                                <p className="fw-bold mb-0 text-danger">
                                  Previous Due
                                </p>
                                <p className="mb-1 text-danger">
                                  {baseCurrency}{" "}
                                  {loan?.paymentInfo?.previousDueAmount?.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </p>
                              </div>
                            )}

                            <div className="d-flex flex-row justify-content-between">
                              <p className="fw-bold mb-0">Late Fees</p>
                              <p className="mb-0">
                                {baseCurrency}{" "}
                                {loan?.paymentInfo?.lateFee?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </p>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                              <p className="fw-bold mb-0">
                                Upcoming Installments
                              </p>
                              <p className="mb-1">
                                {baseCurrency}{" "}
                                {loan?.paymentInfo?.totalUpcomingInstallmentAmount?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    {/* {radioErr && (
                    <p className="text-danger">
                      Please select an amount to pay
                    </p>
                  )} */}
                    <div className="">
                      <label className="form-label">Select Account</label>
                      <div
                        onClick={handleDropdownToggle}
                        className="form-select d-flex justify-content-between cursor-pointer "
                      >
                        <>
                          <p className="m-0">
                            {selectedAccount?.accountNumber
                              ? selectedAccount?.accountNumber
                              : selected}
                          </p>
                          <p
                            className={`m-0 ${
                              selectedAccount &&
                              selectedAccount?.balance < payAmount
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            {selectedAccount?.currency}{" "}
                            {selectedAccount?.balance?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </>
                      </div>

                      <div
                        className="dropdown-content card shadow"
                        ref={dropdownRef}
                        style={{
                          display: isActive ? "block" : "none",
                        }}
                      >
                        {accounts
                          ?.filter((item) => item.currency === "ZAR")
                          .map((item, key) => (
                            <>
                              <div
                                key={key}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  //   if (
                                  //     item?.currency === convData?.toCurrency
                                  //   ) {
                                  //     if (
                                  //       item?.balance >
                                  //       Number(convData?.amountToBeDeducted)
                                  //     ) {
                                  //       setSelectedAccount(item);
                                  //       setIsSelected(
                                  //         `${item.accountNumber} ${item.currency}`
                                  //       );
                                  //       setIsActive(!isActive);
                                  //       setFieldValue(
                                  //         "selectedAccount",
                                  //         `${item.accountNumber}`
                                  //       );
                                  //     }
                                  //   } else {
                                  setSelectedAccount(item);
                                  setAccountErr(false);
                                  //     setIsSelected(
                                  //       `${item.accountNumber} ${item.currency}`
                                  //     );
                                  setIsActive(!isActive);

                                  //   }
                                }}
                                className="d-flex flex-column item w-100 p-4 cursor-pointer bg-hover-secondary"
                              >
                                <div className="d-flex flex-row justify-content-between">
                                  <span style={{ fontWeight: "bold" }}>
                                    {item.accountNumber}
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: "10px",
                                      color:
                                        payAmount && payAmount > item.balance
                                          ? "red"
                                          : "#246BFD",
                                    }}
                                    className="text-end"
                                  >
                                    {item.currency}{" "}
                                    {item.balance.toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </span>
                                </div>
                                {payAmount > item.balance && (
                                  <p className="text-danger text-end m-0">
                                    Insufficient balance!
                                  </p>
                                )}
                              </div>
                            </>
                          ))}
                      </div>
                      {accountErr && (
                        <p className="text-danger">
                          Please select an account to pay
                        </p>
                      )}
                    </div>

                    {loading && (
                      <div className="d-flex flex-row justify-content-center">
                        <ThreeDots
                          visible={true}
                          height="80"
                          width="80"
                          color="#0000FF"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    )}
                    {ruleData && !loading && (
                      <div className="card shadow-sm p-5 mt-2">
                        <p>
                          {" "}
                          <span className="fw-bold">Service Charge :</span>
                          &nbsp;
                          <span>
                            {ruleData?.serviceCharge}&nbsp;{baseCurrency}
                          </span>
                        </p>

                        <p>
                          <span className="fw-bold">Service Charge Tax : </span>
                          &nbsp;
                          <span>
                            {ruleData?.serviceChargeTax}&nbsp;{baseCurrency}
                          </span>
                        </p>
                        <p>
                          <span className="fw-bold">
                            Service Charge Amount :{" "}
                          </span>
                          &nbsp;
                          <span>
                            {ruleData?.serviceChargeAmt}&nbsp;{baseCurrency}
                          </span>
                        </p>
                        <p>
                          <span className="fw-bold">Total : </span>&nbsp;
                          <span>
                            {Number(payAmount) +
                              Number(ruleData?.serviceChargeAmt)}
                            &nbsp;{baseCurrency}
                          </span>
                        </p>
                        {ruleData?.processingType === "SEND_FOR_APPROVAL" && (
                          <p>
                            {" "}
                            <span className="fw-bold">
                              This will be sent to {ruleData?.actorName}&nbsp;(
                              {ruleData?.actorType}) for approval
                            </span>
                          </p>
                        )}
                      </div>
                    )}

                    {selectedAccount &&
                      payAmount !== 0 &&
                      payAmount < selectedAccount?.balance && (
                        <div className="card shadow p-5">
                          <div className="text-center mt-2 mb-3">
                            <h4>Enter Your PIN</h4>
                          </div>
                          <div className="text-center">
                            <p>Enter your PIN to confirm</p>
                          </div>
                          <div className="d-flex justify-content-center text-center mb-2">
                            <OTPInput
                              // {...field}
                              value={otp.join("")}
                              onChange={(value: string) => {
                                value
                                  .split("")
                                  .forEach((char, index) =>
                                    handleChange(char, index)
                                  );
                              }}
                              numInputs={4}
                              renderSeparator={<span>&nbsp;&nbsp;</span>}
                              renderInput={(props, index) => (
                                <input
                                  {...props}
                                  value={otp[index]}
                                  ref={(el) => (inputRefs.current[index] = el)}
                                  onChange={(e) => {
                                    handleChange(e.target.value, index);
                                    setErrMsg("");
                                  }}
                                  onKeyDown={(e) => {
                                    handleKeyDown(e, index);
                                    setErrMsg("");
                                  }}
                                  type={show ? "password" : "text"}
                                  autoFocus={true}
                                  disabled={index !== activeInput}
                                  onFocus={() => setActiveInput(index)}
                                  className="text-center rounded mt-3 border border-1 border-dark"
                                  style={{ height: "50px", width: "50px" }}
                                />
                              )}
                            />
                          </div>
                          <p
                            className="text-center cursor-pointer text-decoration-underline"
                            onClick={() => setShow(!show)}
                          >
                            {show ? "Show PIN" : "Hide PIN"}
                          </p>
                          {errMsg && (
                            <p className="text-danger text-center">{errMsg}</p>
                          )}
                        </div>
                      )}

                    <div
                      className="d-flex flex-row gap-5"
                      style={{
                        marginBottom: "25px",
                        // position: "fixed",
                        // bottom: "5px",
                        width: "365px",
                      }}
                    >
                      <button
                        type="button"
                        className="btn rounded mt-5"
                        style={{
                          width: "190px",
                        }}
                        onClick={() => {
                          if (payAmount && selectedAccount && pin.length === 4)
                            submit();

                          if (!selectedAccount) setAccountErr(true);
                          if (payAmount && selectedAccount && pin.length !== 4)
                            setErrMsg("Pin should be 4 digits");
                        }}
                      >
                        {button}
                      </button>
                      <button
                        id="kt_close"
                        aria-label="Close"
                        className="btn rounded mt-5"
                        style={{
                          width: "190px",
                        }}
                        onClick={() => reset()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>

      <E_ReceiptModal data={successData} />
    </>
  );
}
