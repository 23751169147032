import { useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { DeleteConfirmation } from "./DeleteConfirmation";
import { enhancedFetch } from "../../modules/auth/core/_requests";

export function DeleteModal(data: { data: number; payee: any }) {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const delPayee = data.data;
  const loadData = data.payee;
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  //   const handleDeleteConfirmation = () => {
  // setShowDeleteConfirmation(true);
  // const modalElement = modalRef.current;
  // if (modalElement) {
  //   const modal = Modal.getInstance(modalElement);
  //   modal?.hide();
  // }

  //   };
  const handleDeleteConfirmation = () => {
    const modalElement = document.getElementById("delete");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDeleteCloseModal = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const PayeeData = async () => {
    try {
      const url = `${API_URL}/payee/deletePayee`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        payeeId: delPayee,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status?.statusCode === 0) loadData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        tabIndex={-1}
        id="delete_modal"
        ref={modalRef}
      >
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          // style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-column justify-content-center align-items-center p-5">
                <div className="mb-2">
                  <i className="bi bi-exclamation-circle fs-5x text-warning"></i>
                </div>
                <div className="label">
                  <div className="modal-title-wrapper">
                    <h1 className="modal-title" style={{ color: "#246bfd" }}>
                      Delete payee
                    </h1>
                  </div>
                </div>
                <div className="label p-0">
                  <div className="lorem-ipsum-dolor-wrapper d-flex align-items-center justify-content-center">
                    <p
                      className="lorem-ipsum-dolor mb-7"
                      style={{
                        fontSize: "15px",
                        boxSizing: "border-box",
                        textAlign: "center",
                        padding: "0px 25px",
                      }}
                    >
                      Are you sure you want to delete the selected payee?
                    </p>
                  </div>
                </div>

                <div className="d-flex gap-5">
                  <button
                    type="button"
                    className="btn  rounded"
                    style={{
                      width: "150px",
                    }}
                    onClick={() => {
                      PayeeData();
                      handleDeleteCloseModal();
                      handleDeleteConfirmation();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary rounded"
                    data-bs-dismiss="modal"
                    style={{
                      width: "150px",
                      color: "#246bfd",
                    }}
                    onClick={handleDeleteCloseModal}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {<DeleteConfirmation />}
    </>
  );
}
