import { useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { useLocation } from "react-router-dom";
import { Vendor } from "./vendorList";
import download from "../../../_metronic/assets/all-serviceIcons/file-download.svg";
import { getTransactionDetail } from "../Transaction/TxnApi";
import Xfin from "../../../_metronic/assets/Icons/xfin.png";
import jsPDF from "jspdf";
import { Data } from "./IncomingTable";

interface VendorDetailsState {
  vendorGuid: string;
}

const VendorDetails = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const [tableData, setTableData] = useState<Data[]>([]);
  const [invoicedoc, setInvoiceDoc] = useState<string | undefined>("");

  const location = useLocation();
  const state = location.state as VendorDetailsState | undefined;
  const vendorGuid = state?.vendorGuid;

  const tooltipresend = <Tooltip id="tooltip-add-money">Download</Tooltip>;
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [vendor, setVendor] = useState<Vendor | null>(null);

  const handleDocViewClick = async () => {
    const modalElement = document.getElementById("invoice_pdf_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const getInvoice = async (vendorName: string, invoiceType: number) => {
    try {
      const url = `${API_URL}/vendorInvoice/list`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        orgSearch: vendorName,
        invoiceType: invoiceType,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0 && data?.results?.length > 0) {
        setTableData(data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVendorData = async () => {
    try {
      const url = `${API_URL}/vendor/findByGuid`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        vendorGuid: vendorGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setVendor(data.message);
        getInvoice(data?.message?.companyName, data?.message?.vendorType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (vendorGuid) getVendorData();
  }, [vendorGuid]);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const downloadPDF = (data: Record<string, unknown>[]) => {
    const img = new Image();
    img.src = Xfin;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const imageData = canvas.toDataURL("image/png");

        const doc = new jsPDF();
        doc.setFontSize(14);
        doc.setFont("bold");

        let yPosition = 10;
        const lineHeight = 10;
        const keyWidth = 50;
        doc.text("Invoice Payment Receipt", 105, yPosition, {
          align: "center",
        });

        yPosition += lineHeight * 2;
        doc.setFontSize(12);
        doc.setFont("normal");
        // Dynamically add data from the billData object
        Object.entries(data).forEach(([key, value]) => {
          const keyText = `${capitalizeFirstLetter(key)}:`;
          const valueText = value?.toString() || ""; // Ensure the value is a string
          doc.text(keyText, 20, yPosition);
          doc.text(valueText, 20 + keyWidth, yPosition); // Align values after a fixed width
          yPosition += lineHeight;
        });

        // Adding the image to the PDF
        const imageWidth = 40;
        const imageHeight = 12;
        const imageX = 20;
        const imageY = 10;

        doc.addImage(imageData, "PNG", imageX, imageY, imageWidth, imageHeight);

        doc.save("Vendor-pay-receipt.pdf");
      } else {
        console.error("Unable to get 2D context");
      }
    };
  };

  const handleDownloadClick = async (txnId: string, account: string) => {
    try {
      if (txnId) {
        const transaction = await getTransactionDetail(
          API_URL,
          token,
          account,
          txnId
        );

        downloadPDF(transaction.receipt);
      }
    } catch (error) {
      console.error("Failed to enhancedFetch transaction details:", error);
    }
  };
  return (
    <>
      {" "}
      <h4 className="mb-5">Vendor Details</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card shadow-sm p-5">
          <div className="d-flex flex-row ms-5 mt-5">
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Business Holder Name</div>

              <div className="fs-7">
                {vendor && vendor?.companyName?.length > 30 ? (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    overlay={
                      <Popover
                        className="p-2 bg-light-secondary"
                        id="popover-trigger-focus"
                      >
                        {vendor?.companyName}
                      </Popover>
                    }
                  >
                    <p className="text-gray-800 mb-1 fs-7">
                      {vendor?.companyName?.length > 30
                        ? `${vendor?.companyName.slice(0, 30)}...`
                        : vendor?.companyName}
                    </p>
                  </OverlayTrigger>
                ) : (
                  <p className="text-gray-800 mb-1 fs-7">
                    {vendor?.companyName}
                  </p>
                )}
              </div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Vendor Name</div>
              <div>
                {vendor && vendor?.name?.length > 30 ? (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    overlay={
                      <Popover
                        className="p-2 bg-light-secondary"
                        id="popover-trigger-focus"
                      >
                        {vendor?.name}
                      </Popover>
                    }
                  >
                    <p className="text-gray-800 mb-1 fs-7">
                      {vendor?.name?.length > 30
                        ? `${vendor?.name.slice(0, 30)}...`
                        : vendor?.name}
                    </p>
                  </OverlayTrigger>
                ) : (
                  <p className="text-gray-800 mb-1 fs-7">{vendor?.name}</p>
                )}
              </div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Vendor Type</div>
              <div>
                {vendor?.vendorType === 1 ? "Domestic" : "International"}
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex flex-row ms-5 mt-5">
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">VAT Number</div>
              <div className="fs-7">{vendor?.vatNumber}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Registered Email</div>
              <div>{vendor?.email}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Registered Phone Number</div>
              <div>
                {vendor?.isdCode}-{vendor?.mobileNumber}
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex flex-row ms-5 mt-5">
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Registered Address</div>
              {vendor && vendor?.address?.length > 30 ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Popover
                      className="p-2 bg-light-secondary"
                      id="popover-trigger-focus"
                    >
                      {vendor?.address}
                    </Popover>
                  }
                >
                  <p className="text-gray-800 mb-1 fs-7">
                    {vendor?.address?.length > 30
                      ? `${vendor?.address.slice(0, 30)}...`
                      : vendor?.address}
                  </p>
                </OverlayTrigger>
              ) : (
                <p className="text-gray-800 mb-1 fs-7">{vendor?.address}</p>
              )}
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Registered Country</div>
              <div className="fs-7">{vendor?.country}</div>
            </div>

            <div style={{ width: "400px" }}>
              {/* <div className="fw-bold fs-5">Registered Address</div>
              <div className="fs-7">{vendor?.address}</div> */}
            </div>
          </div>
          <br />
          <div className="d-flex flex-row ms-5 mt-5">
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Bank Name</div>
              <div className="fs-7">{vendor?.bankName}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Account Number/IBAN</div>
              <div className="fs-7">{vendor?.accountNumber}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">SWIFT/BIC</div>
              <div className="fs-7">{vendor?.bic}</div>
            </div>
          </div>
          <br />
        </div>
      </div>
      <hr />
      <h4 className="mb-5">Vendor Payment History</h4>
      <div className="card p-2">
        <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
          <thead className="bg-gray-200">
            <tr className="text-start text-dark-600 fw-bold fs-6 ">
              <th className="min-w-100px p-5">Invoice Date</th>
              <th className="min-w-100px p-5">Invoice No</th>
              <th className="min-w-100px p-5 text-nowrap">Amount</th>
              <th className="min-w-100px p-5 text-nowrap">Status</th>
              <th className="min-w-100px p-5">Action</th>
            </tr>
          </thead>

          <tbody>
            {tableData && tableData.length > 0 ? (
              tableData.map((item, index) => (
                <tr
                  key={index}
                  onMouseOver={(e) => {
                    const target = e.currentTarget as HTMLElement;
                    target.style.backgroundColor = "#f1f1f2";
                  }}
                  onMouseOut={(e) => {
                    const target = e.currentTarget as HTMLElement;
                    target.style.backgroundColor = "white";
                  }}
                >
                  <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                    {new Date(item.createdDate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </td>
                  <td
                    className={`p-5 text-hover-dark ${
                      item?.upload
                        ? "text-primary text-decoration-underline cursor-pointer"
                        : "text-gray-600"
                    }`}
                    onClick={() => {
                      if (item?.upload) {
                        setInvoiceDoc(item?.url);
                        handleDocViewClick();
                      }
                    }}
                  >
                    {item.invoiceNumber}
                  </td>

                  <td className="p-5 text-gray-600">
                    {item.currency}{" "}
                    {item.amount?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>

                  <td
                    className={`p-5 ${
                      item.status === 5
                        ? "text-primary"
                        : item.status === 2
                          ? "text-success"
                          : item.status === 4
                            ? "text-danger"
                            : "text-warning"
                    }`}
                  >
                    {item.status === 5
                      ? "pending"
                      : item.status === 2
                        ? "paid"
                        : item.status === 4
                          ? "cancelled"
                          : item.status === 6
                            ? "Txn Processing"
                            : "Unknown"}
                  </td>
                  <td className="p-5 text-gray-600 p-2">
                    {item.status === 2 && (
                      <div className="d-flex gap-10">
                        <OverlayTrigger placement="top" overlay={tooltipresend}>
                          <img
                            style={{
                              height: "30px",
                              width: "22px",
                              mixBlendMode: "multiply",
                            }}
                            src={download}
                            alt=""
                            onClick={() =>
                              handleDownloadClick(item.txnId, item.fromAccount)
                            }
                          />
                        </OverlayTrigger>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div
        className="modal fade"
        id="invoice_pdf_modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered mw-700px">
          <div
            className="modal-content"
            style={{ maxHeight: "700px", overflow: "auto" }}
          >
            <div className="modal-header" id="kt_modal_add_user_header">
              <h3 className="fw-bold m-0">PDF Viewer</h3>

              {/* <div
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  data-kt-users-modal-action="close"
                  data-bs-dismiss="modal"
                >
                  <i className="ki-duotone ki-cross fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </div> */}
            </div>

            <div className="modal-body px-15">
              <embed
                src={`${invoicedoc}#toolbar=0`}
                type="application/pdf"
                width="100%"
                height="800px"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VendorDetails;
