import { useEffect } from "react";
import { AccountStmnt } from "../PaymentLinkDashboard";
import { DrawerComponent } from "../../../../_metronic/assets/ts/components";
import { OverlayTrigger, Popover } from "react-bootstrap";

export function PaymentDetailsModal({ Data }: { Data: AccountStmnt | null }) {
  useEffect(() => {
    DrawerComponent.reinitialization();
  }, []);
  return (
    <>
      <div
        id="kt_modal"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_modal_toggle"
        data-kt-drawer-close="#kt_modal_close"
      >
        {/* begin::Card */}
        <div className="card shadow-none rounded-0 w-100 p-5">
          {/* begin::Header */}
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_modal_close"
              style={{ height: "24px", width: "24px" }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-gray-600 p-0">Details</h5>
            <div className="card-toolbar"></div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card shadow p-10">
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Name</p>

              {Data && Data?.name?.length > 30 ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Popover
                      className="p-2 bg-light-secondary"
                      id="popover-trigger-focus"
                    >
                      {Data?.name}
                    </Popover>
                  }
                >
                  <p className="mb-1 fs-7">
                    {Data?.name?.length > 30
                      ? `${Data?.name.slice(0, 30)}...`
                      : Data?.name}
                  </p>
                </OverlayTrigger>
              ) : (
                <p className="mb-1 fs-7">{Data?.name}</p>
              )}
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Currency</p>
              <p>{Data?.currency}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Amount</p>
              <p>
                {Data?.amount.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Refrence Id</p>
              <p>{Data?.referenceId}</p>
            </div>

            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Created Date</p>
              <p>{Data?.createdDate}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Expiry Date</p>
              <p>{Data?.expiryDate}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Auto Reminder</p>
              <p>{Data?.autoReminder === "true" ? "Yes" : "No"}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Payment Link</p>
              <p>{Data?.paymentLink}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Notes</p>
              <p>{Data?.notes}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Description</p>

              {Data && Data?.description?.length > 30 ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Popover
                      className="p-2 bg-light-secondary"
                      id="popover-trigger-focus"
                    >
                      {Data?.description}
                    </Popover>
                  }
                >
                  <p className="mb-1 fs-7">
                    {Data?.description?.length > 30
                      ? `${Data?.description.slice(0, 30)}...`
                      : Data?.description}
                  </p>
                </OverlayTrigger>
              ) : (
                <p className="mb-1 fs-7">{Data?.description}</p>
              )}
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Status</p>
              <p
                className={`badge badge-${
                  Data?.status === "created"
                    ? "primary"
                    : Data?.status === "paid"
                      ? "success"
                      : Data?.status === "cancelled"
                        ? "danger"
                        : "secondary"
                }`}
              >
                {Data?.status === "created"
                  ? "Unpaid"
                  : Data?.status === "paid"
                    ? "Paid"
                    : Data?.status === "cancelled"
                      ? "Cancelled"
                      : "Expired"}{" "}
              </p>
            </div>
          </div>

          {/* end::Body */}
        </div>
        {/* end::Card */}
      </div>
    </>
  );
}
