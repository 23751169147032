import { useState, useEffect } from "react";
import { enhancedFetch } from "../../../../modules/auth/core/_requests";
import { DrawerComponent } from "../../../../../_metronic/assets/ts/components";
import { OverlayTrigger, Popover } from "react-bootstrap";

interface Data {
  itemName: string;
  itemRate: string;
  itemAvailability: boolean;
  itemDesc: string;
  itemStatus: number;
  itemGuid: string;
}

export default function InvoiceDetailsModal({ Data }: { Data: Data | null }) {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const baseCurrency = sessionStorage.getItem("currency");

  const [viewData, setViewData] = useState<Data | null>(null);

  const getItemDataa = async () => {
    try {
      const url = `${API_URL}/inventory/loadItem`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        itemGuid: Data?.itemGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      setViewData(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Data?.itemGuid) getItemDataa();

    DrawerComponent.reinitialization();
  }, [Data]);

  return (
    <>
      <div
        id="kt_modal_inventory"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_modal_toggle_inventory"
        data-kt-drawer-close="#kt_modal_close"
      >
        {/* begin::Card */}
        <div className="card shadow-none rounded-0 w-100 p-5">
          {/* begin::Header */}
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_modal_close"
              style={{ height: "24px", width: "24px" }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-gray-600 p-0">Details</h5>
            <div className="card-toolbar"></div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}

          <div className="card shadow p-5">
            <h4>Item Details</h4>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Item Name</p>
              {viewData && viewData?.itemName?.length > 30 ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Popover
                      className="p-2 bg-light-secondary"
                      id="popover-trigger-focus"
                    >
                      {viewData?.itemName}
                    </Popover>
                  }
                >
                  <p className="text-gray-800 mb-1 fs-7">
                    {viewData?.itemName?.length > 30
                      ? `${viewData?.itemName.slice(0, 30)}...`
                      : viewData?.itemName}
                  </p>
                </OverlayTrigger>
              ) : (
                <p className="text-gray-800 mb-1 fs-7">{viewData?.itemName}</p>
              )}
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Item Description</p>
              {viewData && viewData?.itemName?.length > 30 ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Popover
                      className="p-2 bg-light-secondary"
                      id="popover-trigger-focus"
                    >
                      {viewData?.itemName}
                    </Popover>
                  }
                >
                  <p className="text-gray-800 mb-1 fs-7">
                    {viewData?.itemName?.length > 30
                      ? `${viewData?.itemName.slice(0, 30)}...`
                      : viewData?.itemName}
                  </p>
                </OverlayTrigger>
              ) : (
                <p className="text-gray-800 mb-1 fs-7">{viewData?.itemName}</p>
              )}
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Item Rate</p>
              <p>
                {baseCurrency} {viewData?.itemRate}{" "}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-5">
              <p className="fw-bold">Item Availability</p>
              <p>
                {viewData?.itemAvailability ? "Available" : "Not Available"}{" "}
              </p>
            </div>
          </div>

          {/* end::Body */}
        </div>
        {/* end::Card */}
      </div>
    </>
  );
}
