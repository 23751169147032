/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, ChangeEvent, useState } from "react";
import { StepProps } from "./EditPayrollAppModel";
import * as React from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { isPhoneValid } from "../../../modules/auth/components/Login";

export const resetApi = {
  api: (): void => {},
};

export const PayrollStepperModal = ({
  data,
  updateData,
  setAddressUpdate,
  setIdUpdate,
  hasError,
}: StepProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const IdfileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState("");
  const [id, setId] = useState("");

  const reset = () => {
    setImage("");
    setId("");
  };
  resetApi.api = reset;

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    updateData({
      empAddressProof: {
        addressProof: file || null,
      },
      addressDocStatus: {
        addDocStatus: 3,
      },
    });
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result as string;
        console.log(dataURL);

        setImage(dataURL);

        setAddressUpdate(true);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleIDImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    updateData({
      idProof: {
        idProof: file || null,
      },
      idDocStatus: {
        idDocStatus: 3,
      },
    });
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result as string;

        setId(dataURL);

        setIdUpdate(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleIdClick = () => {
    if (IdfileInputRef.current) {
      IdfileInputRef.current.click();
    }
  };

  return (
    <>
      <div className="current" data-kt-stepper-element="content">
        <div className="w-100">
          <div className="fv-row">
            <div
              className="card shadow p-5"
              style={{ maxHeight: "300px", overflow: "auto" }}
            >
              <div className=" p-3">
                <div>
                  <label className="form-label">Employee Name</label>
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Employee Name"
                    value={data.appBasic.empName}
                    style={{ padding: "6.5px" }}
                    autoFocus
                    onChange={(e) => {
                      const value = e.target.value;
                      // Regex to allow only alphanumeric characters
                      const alphanumericValue = value.replace(
                        /[^a-zA-Z0-9 ]/g,
                        ""
                      );
                      updateData({
                        appBasic: {
                          empName: alphanumericValue,
                          appType: data.appBasic.appType,
                        },
                      });
                    }}
                    maxLength={50}
                  />
                  {!data.appBasic.empName && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Name is required
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="d-flex flex-column flex-grow-1 mt-6"
                  style={{ width: "100%" }}
                >
                  <label className="form-label m-0 p-0">DOB</label>
                  <input
                    type="date"
                    className="form-control"
                    value={
                      data.empDate.date ? data.empDate.date.split("T")[0] : ""
                    }
                    // Assuming you're storing the date in YYYY-MM-DD format
                    onChange={(e) => {
                      updateData({
                        empDate: {
                          date: e.target.value, // The value will be in YYYY-MM-DD format
                        },
                      });
                    }}
                    max={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 15)
                      )
                        .toISOString()
                        .split("T")[0]
                    } // 15 years ago
                  />
                </div>
                {!data.empDate.date && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Date is required
                    </div>
                  </div>
                )}

                <div className="mt-8">
                  <label className="form-label">Employee Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Employee Email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                    value={data.empEmail.email}
                    style={{ padding: "6.5px" }}
                    onChange={(e) =>
                      updateData({
                        empEmail: {
                          email: e.target.value,
                        },
                      })
                    }
                    maxLength={50}
                  />

                  {/* Email is required */}
                  {!data.empEmail.email && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="empEmail"
                        data-validator="notEmpty"
                        className="fv-help-block text-danger"
                      >
                        Email is required
                      </div>
                    </div>
                  )}

                  {/* Validate email only if it is not empty */}
                  {data.empEmail.email && (
                    <>
                      {/* Check if the email contains only allowed characters */}
                      {!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                        data.empEmail.email
                      ) && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="empEmail"
                            data-validator="invalidFormat"
                            className="fv-help-block text-danger"
                          >
                            Please enter a valid email address with only
                            letters, digits, periods (.), and valid format.
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="mt-8">
                  <label className="form-label">Employee Phone Number</label>
                  <PhoneInput
                    defaultCountry="in"
                    forceDialCode={true}
                    onChange={(e) => {
                      const phoneValue = e;
                      updateData({
                        empPhone: {
                          phone: phoneValue,
                        },
                      });
                    }}
                    inputStyle={{ width: "100%" }}
                    disableDialCodePrefill={false}
                    // className={formik.touched.phone && formik.errors.phone ? 'error' : ''}
                    value={data?.empPhone?.phone}
                  />

                  {(!data.empPhone.phone ||
                    !isPhoneValid(data.empPhone.phone)) &&
                    hasError && (
                      <div className="fv-plugins-message-container">
                        <div
                          data-field="empPhone"
                          data-validator="notEmpty"
                          className="fv-help-block"
                        >
                          {!data.empPhone.phone
                            ? "Phone is required"
                            : "Invalid phone number"}{" "}
                          {/* Show appropriate error message */}
                        </div>
                      </div>
                    )}
                </div>
                <div className="mt-8">
                  <label className="form-label">Employee Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    value={data.empAddress.address}
                    style={{ padding: "6.5px" }}
                    onChange={(e) =>
                      updateData({
                        empAddress: {
                          address: e.target.value,
                        },
                      })
                    }
                    maxLength={100}
                  />
                  {!data.empAddress.address && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Address is required
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="position-relative mt-8 form-control p-2 d-flex"
                  onClick={handleClick}
                >
                  <span className="text-gray-500">Address Proof</span>
                  <input
                    type="file"
                    id="uploadImage"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept=".jpeg,.jpg"
                    onChange={handleImageUpload}
                  />

                  <div
                    className="position-absolute translate-middle-y end-0 me-3 "
                    style={{ marginTop: "13px" }}
                  >
                    <i
                      className="bi bi-cloud-arrow-up-fill fs-2"
                      id="uploadIcon"
                    ></i>
                  </div>
                </div>
                <div className="p-3">
                  {image ? (
                    <img src={image} alt="Uploaded" style={{ width: "15%" }} />
                  ) : (
                    <img
                      src={data.empAddressProofImg.addressProofImg}
                      alt="Address Proof"
                      style={{ width: "15%" }}
                    />
                  )}
                </div>

                {!data.empAddressProof.addressProof && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Address proof is required
                    </div>
                  </div>
                )}

                <div className="mt-5">
                  <label className="form-label">Blood Group</label>
                  <select
                    className="form-select"
                    data-control="select2"
                    value={data.empBloodGroup.bloodGroup}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      updateData({
                        empBloodGroup: {
                          bloodGroup: e.target.value,
                        },
                      });
                    }}
                    style={{ padding: "6.5px" }}
                  >
                    <option value="">Blood Group</option>
                    <option
                      value="A +ve"
                      selected={data.empBloodGroup.bloodGroup === "A +ve"}
                    >
                      A +ve
                    </option>
                    <option
                      value="O +ve"
                      selected={data.empBloodGroup.bloodGroup === "O +ve"}
                    >
                      O +ve
                    </option>
                    <option
                      value="B +ve"
                      selected={data.empBloodGroup.bloodGroup === "B +ve"}
                    >
                      B +ve
                    </option>
                    <option
                      value="AB +ve"
                      selected={data.empBloodGroup.bloodGroup === "AB +ve"}
                    >
                      AB +ve
                    </option>
                    <option
                      value="A -ve"
                      selected={data.empBloodGroup.bloodGroup === "A -ve"}
                    >
                      A -ve
                    </option>
                    <option
                      value="O -ve"
                      selected={data.empBloodGroup.bloodGroup === "O -ve"}
                    >
                      O -ve
                    </option>
                    <option
                      value="B -ve"
                      selected={data.empBloodGroup.bloodGroup === "B -ve"}
                    >
                      B -ve
                    </option>
                    <option
                      value="AB -ve"
                      selected={data.empBloodGroup.bloodGroup === "AB -ve"}
                    >
                      AB -ve
                    </option>
                  </select>

                  {!data.empBloodGroup.bloodGroup && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Blood Group is required
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="position-relative mt-8 form-control p-2 d-flex"
                  onClick={handleIdClick}
                >
                  <span className="text-gray-500">Id Proof</span>
                  <input
                    type="file"
                    id="uploadImage"
                    ref={IdfileInputRef}
                    style={{ display: "none" }}
                    accept=".jpeg,.jpg"
                    onChange={handleIDImageUpload}
                  />
                  <div
                    className="position-absolute translate-middle-y end-0 me-3 "
                    style={{ marginTop: "13px" }}
                  >
                    <i
                      className="bi bi-cloud-arrow-up-fill fs-2"
                      id="uploadIcon"
                    ></i>
                  </div>
                </div>

                <div className="p-3">
                  {" "}
                  {id ? (
                    <img src={id} alt="Uploaded ID" style={{ width: "15%" }} />
                  ) : (
                    <img
                      src={data.idProofImg.idProofImg}
                      alt="ID Proof"
                      style={{ width: "15%" }}
                    />
                  )}
                </div>

                {!data.idProof.idProof && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Id proof is required
                    </div>
                  </div>
                )}
                <div className="mt-5">
                  <label className="form-label">Emergency Contact Number</label>
                  <PhoneInput
                    defaultCountry="in"
                    forceDialCode={true}
                    onChange={(e) => {
                      const phoneValue = e;
                      updateData({
                        emeContact: {
                          emeContact: phoneValue,
                        },
                      });
                    }}
                    inputStyle={{ width: "100%" }}
                    disableDialCodePrefill={false}
                    // className={formik.touched.phone && formik.errors.phone ? 'error' : ''}
                    value={data.emeContact.emeContact}
                  />

                  {(!data.emeContact.emeContact ||
                    !isPhoneValid(data.emeContact.emeContact)) &&
                    hasError && (
                      <div className="fv-plugins-message-container">
                        <div
                          data-field="emeCont"
                          data-validator="notEmpty"
                          className="fv-help-block"
                        >
                          {!data.emeContact.emeContact
                            ? "Phone is required"
                            : "Invalid phone number"}{" "}
                          {/* Show appropriate error message */}
                        </div>
                      </div>
                    )}
                </div>

                <div
                  className="d-flex flex-column flex-grow-1 mt-6"
                  style={{ width: "100%" }}
                >
                  <label className="form-label m-0 p-0">Date of Joining</label>
                  <input
                    type="date"
                    className="form-control"
                    value={data.doj.doj ? data.doj.doj.split("T")[0] : ""}
                    onChange={(e) => {
                      updateData({
                        doj: {
                          doj: e.target.value, // The value will be in YYYY-MM-DD format
                        },
                      });
                    }}
                    max={new Date().toISOString().split("T")[0]} // Set max date to today
                  />
                </div>
                {!data.doj.doj && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Date is required
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/*end::Form Group */}
        </div>
      </div>
    </>
  );
};
