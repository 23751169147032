import { useState, ChangeEvent, FC, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { CreateAppModal } from "../components/Modal/PayeeModal";
import { DeleteModal } from "../components/DeleteModal";
import PinInput from "react-pin-input";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikProps,
  FormikValues,
} from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import OTPInput from "react-otp-input";
import Authorize from "../../modules/auth/components/Authorize";
import Loader from "../components/Loader";

interface MyData {
  currency: string;
  payeeStatus: number;
  payeeAccountNumber: string;
  payeeType: string;
  payeeSubType: string;
  payeeAlias: string;
  payeeFirstName: string;
  payeeLastName: string;
  payeeId: number;
  payeeCategory: string;
  isFavourite: boolean;
}

export interface VerifyData {
  userId: string;
  tenantId: string;
  businessGuid: string;
  txnHolderId: string;
  initiatorGuid: string;
  approverGuid: string;
  txnType: number;
  requestJson: string;
  functionCode: string;
  approverName: string;
  currency: string;
  beneficiaryName: string;
  amount: number;
  expiryDate: string;
  payeeName: string;
  payeeAlias: string;
  payeeType: string;
  accountNumber: string;
  payeeCategory: string;
  status: number;
  createdDate: string;
  createdDateString: string;
  initiationCode: string;
  receipts: Record<string, unknown>;
  scheduleTxn: boolean;
}

export const ApiPayee = {
  api: (): void => {},
};
const PayeeManagement: FC = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [button, setButton] = useState("Add");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [data, setData] = useState<MyData[]>([]);
  const [verifydata, setVerifyData] = useState<VerifyData[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState(data);
  // const [filteredVerifyTransactions, setFilteredVerifyTransactions] = useState(verifydata);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<MyData | null>(null);
  const [deletePayee, setDeletePayee] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [pin, setPin] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const [holderGuid, setHolderGuid] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const tooltipDelete = <Tooltip id="tooltip-add-money">Delete</Tooltip>;

  const OTPRef = useRef<HTMLDivElement | null>(null);
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  let ele = useRef<PinInput | null>(null);

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [isVerify, setIsVerify] = useState("");
  const [reason, setReason] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  useEffect(() => {
    if (userDet?.userDetails?.authority?.includes("2")) {
      setButton("Initiate");
    }
  }, []);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };
  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  useEffect(() => {
    if (OTPRef.current) {
      const modal = new Modal(OTPRef.current);

      OTPRef.current.addEventListener("shown.bs.modal", () => {
        inputRefs.current[activeInput]?.focus();
        ele?.current?.focus();
      });

      OTPRef.current.addEventListener("hidden.bs.modal", handleOTPCloseModal);
      return () => {
        OTPRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleOTPCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleOTPCloseModal = () => {
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);
    formikRef.current?.resetForm();
    ele.current?.clear();
    const modalElement = OTPRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };
  const OTPModalOpen = () => {
    const modalElement = document.getElementById("OTP_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  useEffect(() => {
    // Filter data based on both selected tab and search query
    const filteredData = data?.filter((payee) => {
      // Filter based on search query
      const isSearchMatched =
        payee.payeeAccountNumber
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        payee.payeeFirstName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        payee.currency
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

      // Return true if search query matches and the selected tab matches
      return (
        isSearchMatched &&
        (selectedTab === "All" || // Always match for "All" tab
          (selectedTab === "Favourite" && payee.isFavourite) ||
          (selectedTab === "Same bank" && payee.payeeSubType === "INTRA") ||
          (selectedTab === "Other bank" && payee.payeeSubType === "INTER") ||
          (selectedTab === "International" &&
            payee.payeeType === "REMITTANCE") ||
          (selectedTab === "Wallet" && payee.payeeType === "WALLET") ||
          (selectedTab === "Pending" && payee.payeeStatus === 1))
      );
    });

    // Update filtered transactions
    setFilteredTransactions(filteredData);
  }, [data, searchQuery, selectedTab]);

  const handleClick = (index: number) => {
    const clickedData: MyData = currentData[index];
    const condition = clickedData.isFavourite ? false : true;

    const addFav = async () => {
      try {
        const url = `${API_URL}/payee/makeFavourite`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          payeeType: clickedData?.payeeType,
          payeeAlias: clickedData?.payeeAlias,
          isFavourite: condition,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        if (data?.status?.statusCode === 0) PayeeData();
      } catch (error) {
        console.log(error);
      }
    };
    addFav();
  };

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };
  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };
  const handleDetailsClick = (index: number) => {
    const clickedData: MyData = currentData[index];
    setSelectedData(() => clickedData);
  };
  const handleDelete = (index: number) => {
    const clickedData: MyData = currentData[index];
    console.log(clickedData);

    setDeletePayee(clickedData.payeeId);

    const DeletePayee = async () => {
      try {
        const url = `${API_URL}/payee/deletePayee`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          payeeAlias: clickedData?.payeeAlias,
          payeeType: clickedData?.payeeType,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        if (data?.status?.statusCode === 0) {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "Payee has been deleted",
            showCancelButton: false,
            confirmButtonText: "Ok",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
          PayeeData();
        }
      } catch (error) {
        console.log(error);
      }
    };

    Swal.fire({
      icon: "warning",
      text: "Are you sure you want to delete the selected payee?",
      showCancelButton: true, // Show the cancel button
      confirmButtonColor: "#007bff", // Set the confirm button color to btn
      confirmButtonText: "Yes",
      cancelButtonColor: "#9fa6b2",
      cancelButtonText: "Cancel",
      allowEscapeKey: true,
      allowEnterKey: true, // Text for the cancel button
    }).then((result) => {
      if (result.isConfirmed) {
        DeletePayee();
      }
    });
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const currentVerifyData = verifydata
    ?.filter((data) => data.status === 1)
    ?.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "All") {
      const filteredData = data;
      setFilteredTransactions(filteredData);
    } else if (tab === "Favourite") {
      const filteredData = data.filter(
        (transaction) => transaction.isFavourite
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Same bank") {
      const filteredData = data.filter(
        (transaction) => transaction.payeeSubType === "INTRA"
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Other bank") {
      const filteredData = data.filter(
        (transaction) => transaction.payeeSubType === "INTER"
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "International") {
      const filteredData = data.filter(
        (transaction) => transaction.payeeType === "REMITTANCE"
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Wallet") {
      const filteredData = data.filter(
        (transaction) => transaction.payeeType === "WALLET"
      );
      setFilteredTransactions(filteredData);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers =
      selectedTab !== "Pending"
        ? Math.ceil(filteredTransactions?.length / itemsPerPage)
        : Math.ceil(currentVerifyData?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const PayeeData = async () => {
    try {
      const url = `${API_URL}/payee/listPayee`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setData(data.results);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const verifyPayeeList = async () => {
    try {
      const url = `${API_URL}/team/getApproverPayeeTxns`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({ searchCriteriaList: [] });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setVerifyData(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const approvePayee = async () => {
    try {
      const url = `${API_URL}/team/approveTxns`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        pin: pin,
        holderGuidList: holderGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        PayeeData();
        verifyPayeeList();
        setIsVerify("");
        setHolderGuid([]);
        handleOTPCloseModal();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Payee approval successful",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const rejectPayee = async () => {
    try {
      const url = `${API_URL}/team/rejectItemsForApproval`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        pin: pin,
        holderGuidList: holderGuid,
        rejectionMsg: reason,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        verifyPayeeList();
        setHolderGuid([]);
        setIsVerify("");
        handleOTPCloseModal();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Payee rejected successfully",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectAll = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const target = e.target as HTMLInputElement;
    setSelectAll(target.checked);

    if (target.checked) {
      // Get all holderGuids from filteredTransactions
      const allHolderGuids = currentVerifyData.map(
        (transaction) => transaction.txnHolderId
      );

      setHolderGuid(allHolderGuids);
    } else {
      setHolderGuid([]);
    }
  };
  const handleCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    holderGuid: string
  ) => {
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      setHolderGuid((prev) => [...prev, holderGuid]);
    } else {
      setHolderGuid((prev) => prev.filter((guid) => guid !== holderGuid));
    }
  };
  useEffect(() => {
    ApiPayee.api = PayeeData;
    PayeeData();
    verifyPayeeList();
  }, []);

  // const getOtp = async () => {
  // try {
  // const url = `${API_URL}/payee/generateOtp`;
  // const headers = {
  // "Content-Type": "application/json",
  // Authorization: `Bearer ${token}`,
  // };

  // const body = JSON.stringify({
  // payeeId: otpVerify,
  // });

  // const options = {
  // method: "POST",
  // headers,
  // body,
  // };

  // let response = await enhancedFetch(url, options);
  // let data = await response.json();
  // } catch (error) {
  // console.log(error);
  // }
  // };
  const ValidatePin = async () => {
    try {
      const url = `${API_URL}/payee/validatePin`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        pin: pin,
        payeeAlias: selectedData?.payeeAlias,
        payeeType: selectedData?.payeeType,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        handleOTPCloseModal();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Payee has been verified",
          confirmButtonText: "OK",
          allowEscapeKey: false,
          allowEnterKey: false,
        });
      }
      if (data.status.statusCode === 1) {
        setPin("");
        setActiveInput(0);
        setOtp(["", "", "", ""]);
        ele.current?.clear();
        formikRef.current?.resetForm();
        setErrMsg(data.status.messageDescription);
      }

      PayeeData();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (holderGuid.length === currentVerifyData?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [holderGuid, currentVerifyData?.length]);

  const handleRejectClick = async () => {
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Enter your reason for rejection",
      inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
      allowEscapeKey: true,
      allowEnterKey: true,
      preConfirm: (inputValue) => {
        if (!inputValue) {
          Swal.showValidationMessage("You need to write reason!");
        }
        return inputValue;
      },
    });

    if (text) {
      setReason(text);
      OTPModalOpen();
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h4 className="mb-5">Payee Management</h4>
          <div className="py-2 card p-5">
            <div className="d-flex justify-content-between align-items-center">
              <div className="">
                <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
                  <li className="nav-item" key={1}>
                    <a
                      className="nav-link text-active-gray-800 active"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_1"
                      onClick={() => handleTabClick("All")}
                    >
                      All
                    </a>
                  </li>
                  <li className="nav-item" key={2}>
                    <a
                      className="nav-link text-active-gray-800"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_2"
                      onClick={() => handleTabClick("Favourite")}
                    >
                      Favourite
                    </a>
                  </li>
                  <li className="nav-item" key={3}>
                    <a
                      className="nav-link text-active-gray-800 text-nowrap"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_3"
                      onClick={() => handleTabClick("Same bank")}
                    >
                      Same bank
                    </a>
                  </li>
                  <li className="nav-item" key={4}>
                    <a
                      className="nav-link text-active-gray-800 text-nowrap"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_4"
                      onClick={() => handleTabClick("Other bank")}
                    >
                      Other bank
                    </a>
                  </li>
                  <li className="nav-item" key={5}>
                    <a
                      className="nav-link text-active-gray-800"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_5"
                      onClick={() => handleTabClick("International")}
                    >
                      International
                    </a>
                  </li>
                  <li className="nav-item" key={6}>
                    <a
                      className="nav-link text-active-gray-800"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_6"
                      onClick={() => handleTabClick("Wallet")}
                    >
                      Wallet
                    </a>
                  </li>
                  {(userDet?.userDetails?.authority?.includes("3") ||
                    button === "Add") && (
                    <li className="nav-item" key={7}>
                      <a
                        className="nav-link text-active-gray-800"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_7"
                        onClick={() => handleTabClick("Pending")}
                      >
                        Pending Approval{" "}
                        {currentVerifyData?.length > 0 ? (
                          <span className="bullet bullet-dot bg-danger h-6px w-6px mb-3 me-2" />
                        ) : null}
                      </a>
                    </li>
                  )}
                </ul>
              </div>

              <div className="d-flex align-items-center p-3 gap-10">
                <div className="d-flex align-items-center position-relative my-1">
                  <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    value={searchQuery}
                    onChange={handleSearchChange}
                    type="text"
                    data-kt-docs-table-filter="search"
                    className="form-control p-3 border-secondary form-control-solid w-250px ps-15"
                    placeholder="Search Payees"
                  />
                </div>
                <Authorize hasAnyPermission={["MENU_PAYEE|ADD"]}>
                  <button
                    className="btn fs-7 p-3"
                    onClick={() => setShowCreateAppModal(true)}
                  >
                    Add Payee
                  </button>
                </Authorize>
              </div>
            </div>

            <CreateAppModal
              show={showCreateAppModal}
              handleClose={() => setShowCreateAppModal(false)}
            />

            <div
              className="tab-content "
              id="myTabContent"
              style={{ overflow: "scroll" }}
            >
              <div
                className="tab-pane fade show active"
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <>
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6 ">
                          <th className="min-w-100px p-5">Payee Name</th>
                          <th className="min-w-100px p-5">Payee Alias</th>
                          <th className="min-w-100px p-5">Payee Type</th>
                          <th className="min-w-100px p-5">Account Number</th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Currency
                          </th>
                          <Authorize hasAnyPermission={["MENU_PAYEE|DELETE"]}>
                            <th className="min-w-100px p-5 text-nowrap">
                              Action
                            </th>
                          </Authorize>
                          <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                            <th className="min-w-100px p-5 text-nowrap">
                              Favourite
                            </th>
                          </Authorize>
                        </tr>
                      </thead>

                      <tbody>
                        {currentData && currentData.length > 0 ? (
                          currentData.map((item, index) => (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                              }}
                            >
                              <td
                                className="p-5 text-gray-600"
                                onClick={() => handleDetailsClick(index)}
                              >
                                <span
                                  className="text-danger"
                                  style={{
                                    fontWeight: "normal",
                                    marginRight: "10px",
                                  }}
                                >
                                  {item.payeeStatus === 8 ? (
                                    <i
                                      className="bi bi-circle-fill text-primary fs-9"
                                      style={{
                                        position: "absolute",
                                        left: "15px",
                                      }}
                                    ></i>
                                  ) : null}
                                </span>
                                {item.payeeFirstName}{" "}
                                {item.payeeLastName !== "null"
                                  ? item.payeeLastName
                                  : ""}
                                <Authorize
                                  hasAnyPermission={["MENU_PAYEE|ADD"]}
                                >
                                  {item.payeeStatus === 1 && (
                                    <span
                                      className="badge badge-danger cursor-pointer"
                                      style={{
                                        fontWeight: "normal",
                                        marginLeft: "10px",
                                      }}
                                      onClick={() => {
                                        OTPModalOpen();
                                      }}
                                    >
                                      Click to verify
                                    </span>
                                  )}
                                </Authorize>
                              </td>
                              <td className="p-5 text-gray-600">
                                {item?.payeeAlias}
                              </td>
                              <td className="p-5 text-gray-600">
                                <span
                                  className={`badge ${
                                    item?.payeeCategory === "RETAIL"
                                      ? "badge-light-success"
                                      : "badge-light-info"
                                  }`}
                                >
                                  {item?.payeeCategory === "RETAIL"
                                    ? "INDIVIDUAL"
                                    : item.payeeCategory}
                                </span>
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.payeeAccountNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.currency === "R" ? "ZAR" : item.currency}
                              </td>
                              <Authorize
                                hasAnyPermission={["MENU_PAYEE|DELETE"]}
                              >
                                <td className="p-5 text-gray-600 p-2">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash3 text-danger p-5 cursor-pointer"
                                      onClick={() => {
                                        handleDelete(index);
                                      }}
                                    ></i>
                                  </OverlayTrigger>
                                </td>
                              </Authorize>
                              <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                                <td className="p-5">
                                  {item.payeeStatus === 10 && (
                                    <i
                                      onClick={() => handleClick(index)}
                                      className={`bi bi-star${
                                        item.isFavourite
                                          ? "-fill text-warning"
                                          : ""
                                      } fs-2 p-5 cursor-pointer`}
                                    />
                                  )}
                                </td>
                              </Authorize>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>

                {/* <DeleteModal data={deletePayee} payee={PayeeData} /> */}
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                <>
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6 ">
                          <th className="min-w-100px p-5">Payee Name</th>

                          <th className="min-w-100px p-5">Payee Alias</th>
                          <th className="min-w-100px p-5">Payee Type</th>
                          <th className="min-w-100px p-5">Account Number</th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Currency
                          </th>
                          <Authorize hasAnyPermission={["MENU_PAYEE|DELETE"]}>
                            <th className="min-w-100px p-5 text-nowrap">
                              Action
                            </th>
                          </Authorize>
                          <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                            <th className="min-w-100px p-5 text-nowrap">
                              Favourite
                            </th>
                          </Authorize>
                        </tr>
                      </thead>

                      <tbody>
                        {currentData && currentData.length > 0 ? (
                          currentData.map((item, index) => {
                            if (item.isFavourite) {
                              return (
                                <tr
                                  key={index}
                                  onMouseOver={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "#f1f1f2";
                                  }}
                                  onMouseOut={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "white";
                                  }}
                                >
                                  <td
                                    className="p-5 text-gray-600"
                                    onClick={() => handleDetailsClick(index)}
                                  >
                                    <span
                                      className="text-danger"
                                      style={{
                                        fontWeight: "normal",
                                        marginRight: "10px",
                                      }}
                                    >
                                      {item.payeeStatus === 1 ? (
                                        <i
                                          className="bi bi-circle-fill text-primary fs-9"
                                          style={{
                                            position: "absolute",
                                            left: "15px",
                                          }}
                                        ></i>
                                      ) : null}
                                    </span>
                                    {item.payeeFirstName}
                                    <Authorize
                                      hasAnyPermission={["MENU_PAYEE|ADD"]}
                                    >
                                      {item.payeeStatus === 1 && (
                                        <span
                                          className="badge badge-danger cursor-pointer"
                                          style={{
                                            fontWeight: "normal",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() => {
                                            OTPModalOpen();
                                          }}
                                        >
                                          Click to verify
                                        </span>
                                      )}
                                    </Authorize>
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item?.payeeAlias}
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    <span
                                      className={`badge ${
                                        item?.payeeCategory === "RETAIL"
                                          ? "badge-light-success"
                                          : "badge-light-info"
                                      }`}
                                    >
                                      {item?.payeeCategory === "RETAIL"
                                        ? "INDIVIDUAL"
                                        : item.payeeCategory}
                                    </span>
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item.payeeAccountNumber}
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item.currency === "R"
                                      ? "ZAR"
                                      : item.currency}
                                  </td>
                                  <Authorize
                                    hasAnyPermission={["MENU_PAYEE|DELETE"]}
                                  >
                                    <td className="p-5 text-gray-600 p-2">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={tooltipDelete}
                                      >
                                        <i
                                          className="bi bi-trash3 text-danger p-5 cursor-pointer"
                                          onClick={() => {
                                            handleDelete(index);
                                          }}
                                        ></i>
                                      </OverlayTrigger>
                                    </td>
                                  </Authorize>
                                  <Authorize
                                    hasAnyPermission={["MENU_PAYEE|EDIT"]}
                                  >
                                    <td className="p-5">
                                      {item.payeeStatus === 10 && (
                                        <i
                                          onClick={() => handleClick(index)}
                                          className={`bi bi-star${
                                            item.isFavourite
                                              ? "-fill text-warning"
                                              : ""
                                          } fs-2 p-5 cursor-pointer`}
                                        />
                                      )}
                                    </td>
                                  </Authorize>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>

                {/* <DeleteModal data={deletePayee} payee={PayeeData} /> */}
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                <>
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6 ">
                          <th className="min-w-100px p-5">Payee Name</th>
                          <th className="min-w-100px p-5">Payee Alias</th>
                          <th className="min-w-100px p-5">Payee Type</th>
                          <th className="min-w-100px p-5">Account Number</th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Currency
                          </th>
                          <Authorize hasAnyPermission={["MENU_PAYEE|DELETE"]}>
                            <th className="min-w-100px p-5 text-nowrap">
                              Action
                            </th>
                          </Authorize>
                          <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                            <th className="min-w-100px p-5 text-nowrap">
                              Favourite
                            </th>
                          </Authorize>
                        </tr>
                      </thead>

                      <tbody>
                        {currentData && currentData.length > 0 ? (
                          currentData.map((item, index) => {
                            if (
                              item.payeeType === "BANK" &&
                              item.payeeSubType === "INTRA"
                            ) {
                              return (
                                <tr
                                  key={index}
                                  onMouseOver={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "#f1f1f2";
                                    // target.style.transform = "translate3d(6px, -6px, 0)";
                                    // target.style.boxShadow =
                                    //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                  }}
                                  onMouseOut={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "white";
                                    // target.style.transform = "none";
                                    // target.style.boxShadow = "none";
                                  }}
                                >
                                  <td
                                    className="p-5 text-gray-600"
                                    onClick={() => handleDetailsClick(index)}
                                  >
                                    <span
                                      className="text-danger"
                                      style={{
                                        fontWeight: "normal",
                                        marginRight: "10px",
                                      }}
                                    >
                                      {" "}
                                      {item.payeeStatus === 1 ? (
                                        <i
                                          className="bi bi-circle-fill text-primary fs-9"
                                          style={{
                                            position: "absolute",
                                            left: "15px",
                                          }}
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </span>{" "}
                                    {item.payeeFirstName}
                                    <Authorize
                                      hasAnyPermission={["MENU_PAYEE|EDIT"]}
                                    >
                                      {item.payeeStatus === 1 && (
                                        <span
                                          className="badge badge-danger cursor-pointer "
                                          style={{
                                            fontWeight: "normal",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() => {
                                            OTPModalOpen();
                                          }}
                                        >
                                          Click to verify
                                        </span>
                                      )}
                                    </Authorize>
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item?.payeeAlias}
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    <span
                                      className={`badge ${
                                        item?.payeeCategory === "RETAIL"
                                          ? "badge-light-success"
                                          : "badge-light-info"
                                      }`}
                                    >
                                      {item?.payeeCategory === "RETAIL"
                                        ? "INDIVIDUAL"
                                        : item.payeeCategory}
                                    </span>
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item.payeeAccountNumber}
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item.currency === "R"
                                      ? "ZAR"
                                      : item.currency}
                                  </td>
                                  <Authorize
                                    hasAnyPermission={["MENU_PAYEE|DELETE"]}
                                  >
                                    <td className="p-5 text-gray-600 p-2">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={tooltipDelete}
                                      >
                                        <i
                                          className="bi bi-trash3 text-danger p-5 cursor-pointer"
                                          onClick={() => {
                                            handleDelete(index);
                                          }}
                                        ></i>
                                      </OverlayTrigger>
                                    </td>
                                  </Authorize>
                                  <Authorize
                                    hasAnyPermission={["MENU_PAYEE|EDIT"]}
                                  >
                                    <td className="p-5">
                                      {item.payeeStatus === 10 && (
                                        <i
                                          onClick={() => handleClick(index)}
                                          className={`bi bi-star${
                                            item.isFavourite
                                              ? "-fill text-warning"
                                              : ""
                                          } fs-2 p-5 cursor-pointer`}
                                        />
                                      )}
                                    </td>
                                  </Authorize>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>

                {/* <DeleteModal data={deletePayee} payee={PayeeData} /> */}
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
                <>
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6 ">
                          <th className="min-w-100px p-5">Payee Name</th>
                          <th className="min-w-100px p-5">Payee Alias</th>
                          <th className="min-w-100px p-5">Payee Type</th>
                          <th className="min-w-100px p-5">Account Number</th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Currency
                          </th>
                          <Authorize hasAnyPermission={["MENU_PAYEE|DELETE"]}>
                            <th className="min-w-100px p-5 text-nowrap">
                              Action
                            </th>
                          </Authorize>
                          <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                            <th className="min-w-100px p-5 text-nowrap">
                              Favourite
                            </th>
                          </Authorize>
                        </tr>
                      </thead>

                      <tbody>
                        {currentData && currentData.length > 0 ? (
                          currentData.map((item, index) => {
                            if (
                              item.payeeType === "BANK" &&
                              item.payeeSubType === "INTER"
                            ) {
                              return (
                                <tr
                                  key={index}
                                  onMouseOver={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "#f1f1f2";
                                    // target.style.transform = "translate3d(6px, -6px, 0)";
                                    // target.style.boxShadow =
                                    //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                  }}
                                  onMouseOut={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "white";
                                    // target.style.transform = "none";
                                    // target.style.boxShadow = "none";
                                  }}
                                >
                                  <td
                                    className="p-5 text-gray-600"
                                    onClick={() => handleDetailsClick(index)}
                                  >
                                    <span
                                      className="text-danger"
                                      style={{
                                        fontWeight: "normal",
                                        marginRight: "10px",
                                      }}
                                    >
                                      {" "}
                                      {item.payeeStatus === 1 ? (
                                        <i
                                          className="bi bi-circle-fill text-primary fs-9"
                                          style={{
                                            position: "absolute",
                                            left: "15px",
                                          }}
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </span>{" "}
                                    {item.payeeFirstName}
                                    <Authorize
                                      hasAnyPermission={["MENU_PAYEE|ADD"]}
                                    >
                                      {item.payeeStatus === 1 && (
                                        <span
                                          className="badge badge-danger cursor-pointer "
                                          style={{
                                            fontWeight: "normal",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() => {
                                            OTPModalOpen();
                                          }}
                                        >
                                          Click to verify
                                        </span>
                                      )}
                                    </Authorize>
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item?.payeeAlias}
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    <span
                                      className={`badge ${
                                        item?.payeeCategory === "RETAIL"
                                          ? "badge-light-success"
                                          : "badge-light-info"
                                      }`}
                                    >
                                      {item?.payeeCategory === "RETAIL"
                                        ? "INDIVIDUAL"
                                        : item.payeeCategory}
                                    </span>
                                  </td>

                                  <td className="p-5 text-gray-600">
                                    {item.payeeAccountNumber}
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item.currency === "R"
                                      ? "ZAR"
                                      : item.currency}
                                  </td>
                                  <Authorize
                                    hasAnyPermission={["MENU_PAYEE|DELETE"]}
                                  >
                                    <td className="p-5 text-gray-600 p-2">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={tooltipDelete}
                                      >
                                        <i
                                          className="bi bi-trash3 text-danger p-5 cursor-pointer"
                                          onClick={() => {
                                            handleDelete(index);
                                          }}
                                        ></i>
                                      </OverlayTrigger>
                                    </td>
                                  </Authorize>
                                  <Authorize
                                    hasAnyPermission={["MENU_PAYEE|EDIT"]}
                                  >
                                    <td className="p-5">
                                      {item.payeeStatus === 10 && (
                                        <i
                                          onClick={() => handleClick(index)}
                                          className={`bi bi-star${
                                            item.isFavourite
                                              ? "-fill text-warning"
                                              : ""
                                          } fs-2 p-5 cursor-pointer`}
                                        />
                                      )}
                                    </td>
                                  </Authorize>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>

                {/* <DeleteModal data={deletePayee} payee={PayeeData} /> */}
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
                <>
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6 ">
                          <th className="min-w-100px p-5">Payee Name</th>
                          <th className="min-w-100px p-5">Payee Alias</th>
                          <th className="min-w-100px p-5">Payee Type</th>
                          <th className="min-w-100px p-5">Account Number</th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Currency
                          </th>
                          <Authorize hasAnyPermission={["MENU_PAYEE|DELETE"]}>
                            <th className="min-w-100px p-5 text-nowrap">
                              Action
                            </th>
                          </Authorize>
                          <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                            <th className="min-w-100px p-5 text-nowrap">
                              Favourite
                            </th>
                          </Authorize>
                        </tr>
                      </thead>

                      <tbody>
                        {currentData && currentData.length > 0 ? (
                          currentData.map((item, index) => {
                            if (item.payeeType === "REMITTANCE") {
                              return (
                                <tr
                                  key={index}
                                  onMouseOver={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "#f1f1f2";
                                    // target.style.transform = "translate3d(6px, -6px, 0)";
                                    // target.style.boxShadow =
                                    //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                  }}
                                  onMouseOut={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "white";
                                    // target.style.transform = "none";
                                    // target.style.boxShadow = "none";
                                  }}
                                >
                                  <td
                                    className="p-5 text-gray-600"
                                    onClick={() => handleDetailsClick(index)}
                                  >
                                    <span
                                      className="text-danger"
                                      style={{
                                        fontWeight: "normal",
                                        marginRight: "10px",
                                      }}
                                    >
                                      {" "}
                                      {item.payeeStatus === 1 ? (
                                        <i
                                          className="bi bi-circle-fill text-primary fs-9"
                                          style={{
                                            position: "absolute",
                                            left: "15px",
                                          }}
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </span>{" "}
                                    {item.payeeFirstName}
                                    <Authorize
                                      hasAnyPermission={["MENU_PAYEE|ADD"]}
                                    >
                                      {item.payeeStatus === 1 && (
                                        <span
                                          className="badge badge-danger cursor-pointer "
                                          style={{
                                            fontWeight: "normal",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() => {
                                            OTPModalOpen();
                                          }}
                                        >
                                          Click to verify
                                        </span>
                                      )}
                                    </Authorize>
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item?.payeeAlias}
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    <span
                                      className={`badge ${
                                        item?.payeeCategory === "RETAIL"
                                          ? "badge-light-success"
                                          : "badge-light-info"
                                      }`}
                                    >
                                      {item?.payeeCategory === "RETAIL"
                                        ? "INDIVIDUAL"
                                        : item.payeeCategory}
                                    </span>
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item.payeeAccountNumber}
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item.currency === "R"
                                      ? "ZAR"
                                      : item.currency}
                                  </td>
                                  <Authorize
                                    hasAnyPermission={["MENU_PAYEE|DELETE"]}
                                  >
                                    <td className="p-5 text-gray-600 p-2">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={tooltipDelete}
                                      >
                                        <i
                                          className="bi bi-trash3 text-danger p-5 cursor-pointer"
                                          onClick={() => {
                                            handleDelete(index);
                                          }}
                                        ></i>
                                      </OverlayTrigger>
                                    </td>
                                  </Authorize>
                                  <Authorize
                                    hasAnyPermission={["MENU_PAYEE|EDIT"]}
                                  >
                                    <td className="p-5">
                                      {item.payeeStatus === 10 && (
                                        <i
                                          onClick={() => handleClick(index)}
                                          className={`bi bi-star${
                                            item.isFavourite
                                              ? "-fill text-warning"
                                              : ""
                                          } fs-2 p-5 cursor-pointer`}
                                        />
                                      )}
                                    </td>
                                  </Authorize>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>

                {/* <DeleteModal data={deletePayee} payee={PayeeData} /> */}
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_6" role="tabpanel">
                <>
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6 ">
                          <th className="min-w-100px p-5">Payee Name</th>
                          <th className="min-w-100px p-5">Payee Alias</th>
                          <th className="min-w-100px p-5">Payee Type</th>
                          <th className="min-w-100px p-5">Account Number</th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Currency
                          </th>
                          <Authorize hasAnyPermission={["MENU_PAYEE|DELETE"]}>
                            <th className="min-w-100px p-5 text-nowrap">
                              Action
                            </th>
                          </Authorize>
                          <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                            <th className="min-w-100px p-5 text-nowrap">
                              Favourite
                            </th>
                          </Authorize>
                        </tr>
                      </thead>

                      <tbody>
                        {currentData && currentData.length > 0 ? (
                          currentData.map((item, index) => {
                            if (item.payeeType === "WALLET") {
                              return (
                                <tr
                                  key={index}
                                  onMouseOver={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "#f1f1f2";
                                    // target.style.transform = "translate3d(6px, -6px, 0)";
                                    // target.style.boxShadow =
                                    //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                  }}
                                  onMouseOut={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "white";
                                    // target.style.transform = "none";
                                    // target.style.boxShadow = "none";
                                  }}
                                >
                                  <td
                                    className="p-5 text-gray-600"
                                    onClick={() => handleDetailsClick(index)}
                                  >
                                    <span
                                      className="text-danger"
                                      style={{
                                        fontWeight: "normal",
                                        marginRight: "10px",
                                      }}
                                    >
                                      {" "}
                                      {item.payeeStatus === 1 ? (
                                        <i
                                          className="bi bi-circle-fill text-primary fs-9"
                                          style={{
                                            position: "absolute",
                                            left: "15px",
                                          }}
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </span>{" "}
                                    {item.payeeFirstName}
                                    <Authorize
                                      hasAnyPermission={["MENU_PAYEE|ADD"]}
                                    >
                                      {item.payeeStatus === 1 && (
                                        <span
                                          className="badge badge-danger cursor-pointer "
                                          style={{
                                            fontWeight: "normal",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() => {
                                            OTPModalOpen();
                                          }}
                                        >
                                          Click to verify
                                        </span>
                                      )}
                                    </Authorize>
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item.payeeAlias}
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    <span
                                      className={`badge ${
                                        item?.payeeCategory === "RETAIL"
                                          ? "badge-light-success"
                                          : "badge-light-info"
                                      }`}
                                    >
                                      {item?.payeeCategory === "RETAIL"
                                        ? "INDIVIDUAL"
                                        : item.payeeCategory}
                                    </span>
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item.payeeAccountNumber}
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    {item.currency === "R"
                                      ? "ZAR"
                                      : item.currency}
                                  </td>
                                  <Authorize
                                    hasAnyPermission={["MENU_PAYEE|DELETE"]}
                                  >
                                    <td className="p-5 text-gray-600 p-2">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={tooltipDelete}
                                      >
                                        <i
                                          className="bi bi-trash3 text-danger p-5 cursor-pointer"
                                          onClick={() => {
                                            handleDelete(index);
                                          }}
                                        ></i>
                                      </OverlayTrigger>
                                    </td>
                                  </Authorize>
                                  <Authorize
                                    hasAnyPermission={["MENU_PAYEE|EDIT"]}
                                  >
                                    <td className="p-5">
                                      {item.payeeStatus === 10 && (
                                        <i
                                          onClick={() => handleClick(index)}
                                          className={`bi bi-star${
                                            item.isFavourite
                                              ? "-fill text-warning"
                                              : ""
                                          } fs-2 p-5 cursor-pointer`}
                                        />
                                      )}
                                    </td>
                                  </Authorize>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>

                {/* <DeleteModal data={deletePayee} payee={PayeeData} /> */}
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_7" role="tabpanel">
                <>
                  <div className="d-flex flex-row justify-content-end p-5">
                    <div className="d-flex flex-row gap-5">
                      <Authorize hasAnyPermission={["MENU_PAYEE|ADD"]}>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            setIsVerify("approve");
                            OTPModalOpen();
                          }}
                          disabled={holderGuid.length === 0}
                        >
                          Approve
                        </button>
                      </Authorize>
                      <Authorize hasAnyPermission={["MENU_PAYEE|DELETE"]}>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={handleRejectClick}
                          disabled={holderGuid.length === 0}
                        >
                          Reject
                        </button>
                      </Authorize>
                    </div>
                  </div>
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6 ">
                          <th className="min-w-100px p-5">Payee Name</th>
                          <th className="min-w-100px p-5">Payee Alias</th>
                          <th className="min-w-100px p-5">Payee Type</th>
                          <th className="min-w-100px p-5">Account Number</th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Currency
                          </th>
                          {/* <th className="min-w-100px p-5 text-nowrap">Action</th> */}
                          <th className="min-w-100px p-5 text-nowrap">
                            <div className="form-check form-check-custom form-check-solid form-check-sm">
                              <input
                                type="checkbox"
                                className="form-check-input cursor-pointer bg-white border border-dark"
                                checked={selectAll}
                                onClick={handleSelectAll}
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentVerifyData && currentVerifyData.length > 0 ? (
                          currentVerifyData.map((item, index) => (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                              }}
                            >
                              <td
                                className="p-5 text-gray-600"
                                onClick={() => handleDetailsClick(index)}
                              >
                                {item.payeeName}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item?.payeeAlias}
                              </td>
                              <td className="p-5 text-gray-600">
                                <span
                                  className={`badge ${
                                    item?.payeeCategory === "RETAIL"
                                      ? "badge-light-success"
                                      : "badge-light-info"
                                  }`}
                                >
                                  {item?.payeeCategory === "RETAIL"
                                    ? "INDIVIDUAL"
                                    : item.payeeCategory}
                                </span>
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.accountNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.currency === "R" ? "ZAR" : item.currency}
                              </td>
                              {/* <td className="p-5 text-gray-600 p-2">
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltipDelete}
                            >
                              <i
                                className="bi bi-trash3 text-danger p-5 cursor-pointer"
                                onClick={() => {
                                  handleDelete(index);
                                }}
                              ></i>
                            </OverlayTrigger>
                          </td> */}
                              <td className="p-5">
                                <div className="form-check form-check-custom form-check-solid form-check-sm">
                                  <input
                                    type="checkbox"
                                    className="form-check-input cursor-pointer"
                                    checked={holderGuid.includes(
                                      item.txnHolderId
                                    )}
                                    onClick={(e) =>
                                      handleCheckClick(e, item.txnHolderId)
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-between">
                      <div className="p-5">
                        <ul className="pagination">
                          <li
                            className={`page-item double-arrow ${
                              currentPage === 1 ? "disabled" : ""
                            }`}
                            key={0}
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() => handlePageChange(1)}
                            >
                              <i className="previous"></i>
                              <i className="previous"></i>
                            </a>
                          </li>
                          <li
                            className={`page-item previous ${
                              currentPage === 1 ? "disabled" : ""
                            }`}
                            key={1}
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              <i className="previous"></i>
                            </a>
                          </li>
                          {renderPageNumbers()}
                          <li
                            className={`page-item next ${
                              currentPage ===
                              Math.ceil(
                                currentVerifyData?.length / itemsPerPage
                              )
                                ? "disabled"
                                : ""
                            }`}
                            key={2}
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              <i className="next"></i>
                            </a>
                          </li>
                          <li
                            className={`page-item double-arrow ${
                              currentPage ===
                              Math.ceil(
                                currentVerifyData?.length / itemsPerPage
                              )
                                ? "disabled"
                                : ""
                            }`}
                            key={3}
                          >
                            <a
                              href="#"
                              className="page-link"
                              onClick={() =>
                                handlePageChange(
                                  Math.ceil(
                                    currentVerifyData?.length / itemsPerPage
                                  )
                                )
                              }
                            >
                              <i className="next"></i>
                              <i className="next"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="p-5 d-flex align-items-center">
                        <label
                          htmlFor="itemsPerPage"
                          style={{ fontWeight: "bold" }}
                        >
                          Total: {currentVerifyData?.length}&nbsp;&nbsp;
                        </label>
                        <select
                          id="itemsPerPage"
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                          style={{
                            padding: "1px 2px 1px 2px",
                            cursor: "pointer",
                          }}
                        >
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={75}>75</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
            {selectedTab !== "Pending" && (
              <div className="d-flex justify-content-between">
                <div className="p-5">
                  <ul className="pagination">
                    <li
                      className={`page-item double-arrow ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={0}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(1)}
                      >
                        <i className="previous"></i>
                        <i className="previous"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={1}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="previous"></i>
                      </a>
                    </li>
                    {renderPageNumbers()}
                    <li
                      className={`page-item next ${
                        currentPage ===
                        Math.ceil(filteredTransactions?.length / itemsPerPage)
                          ? "disabled"
                          : ""
                      }`}
                      key={2}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="next"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item double-arrow ${
                        currentPage ===
                        Math.ceil(filteredTransactions?.length / itemsPerPage)
                          ? "disabled"
                          : ""
                      }`}
                      key={3}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() =>
                          handlePageChange(
                            Math.ceil(
                              filteredTransactions?.length / itemsPerPage
                            )
                          )
                        }
                      >
                        <i className="next"></i>
                        <i className="next"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="p-5 d-flex align-items-center">
                  <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                    Total: {filteredTransactions?.length}&nbsp;&nbsp;
                  </label>
                  <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
                  >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={75}>75</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
            )}
          </div>

      <div className="modal fade" tabIndex={-1} id="OTP_modal" ref={OTPRef}>
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          // style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row justify-content-between">
                {/* <h4>Verify Payee</h4> */}
                {/* <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div> */}
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center p-5">
                    <div className="label">
                      <div className="modal-title-wrapper">
                        <h1 className="modal-title">Enter Your PIN</h1>
                      </div>
                    </div>

                    <div className="label p-0">
                      <div className="lorem-ipsum-dolor-wrapper d-flex align-items-center justify-content-center">
                        <p
                          className="lorem-ipsum-dolor mb-7 text-nowrap"
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          Enter your PIN to confirm
                        </p>
                      </div>
                    </div>
                    <Formik
                      innerRef={formikRef}
                      initialValues={{
                        pin: "",
                      }}
                      validationSchema={Yup.object({
                        pin: Yup.string()
                          .matches(
                            /^[ A-Za-z0-9_@./#&+-]*$/,
                            "Invalid characters"
                          )
                          .min(4, "Pin must be 4 digits")
                          .required("Pin is required"),
                      })}
                      onSubmit={() => {
                        if (selectedTab === "Pending") {
                          if (isVerify === "approve") approvePayee();
                          if (isVerify === "reject") rejectPayee();
                        } else {
                          ValidatePin();
                        }
                      }}
                    >
                      <Form>
                        <div>
                          <Field name="pin">
                            {({
                              form,
                            }: {
                              field: any;
                              meta: any;
                              form: FormikProps<any>;
                            }) => (
                              <div>
                                <OTPInput
                                  // {...field}
                                  value={otp.join("")}
                                  onChange={(value: string) => {
                                    value
                                      .split("")
                                      .forEach((char, index) =>
                                        handleChange(char, index)
                                      );
                                  }}
                                  numInputs={4}
                                  renderSeparator={<span>&nbsp;&nbsp;</span>}
                                  renderInput={(props, index) => (
                                    <input
                                      {...props}
                                      value={otp[index]}
                                      ref={(el) =>
                                        (inputRefs.current[index] = el)
                                      }
                                      onChange={(e) => {
                                        const pin = handleChange(
                                          e.target.value,
                                          index
                                        );
                                        form.setFieldValue("pin", pin);
                                        setErrMsg("");
                                      }}
                                      onKeyDown={(e) => {
                                        const pin = handleKeyDown(e, index);
                                        form.setFieldValue("pin", pin);
                                        setErrMsg("");
                                      }}
                                      type={show ? "text" : "password"}
                                      autoFocus={true}
                                      disabled={index !== activeInput}
                                      onFocus={() => setActiveInput(index)}
                                      className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                                      style={{ height: "50px", width: "50px" }}
                                    />
                                  )}
                                />
                              </div>
                            )}
                          </Field>
                          <p
                            className="text-center cursor-pointer mb-0 text-decoration-underline"
                            onClick={() => setShow(!show)}
                          >
                            {show ? "Hide PIN" : "Show PIN"}
                          </p>
                          <ErrorMessage
                            name="pin"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <br />
                        {errMsg && (
                          <p className="text-danger text-center">{errMsg}</p>
                        )}

                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            type="submit"
                            className="btn  rounded"
                            style={{
                              width: "226px",
                            }}
                          >
                            Confirm Payee
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DeleteModal data={deletePayee} payee={PayeeData} />
        </>
      )}
    </div>
  );
};
export default PayeeManagement;
