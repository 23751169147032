import React, { useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import { useAuth } from "../../core/Auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { verifyOtp } from "./api";
import { useNavigate } from "react-router-dom";
import { useFormCompletion } from "./Stepper";

const OtpScreen1 = () => {
  const [activeInput, setActiveInput] = useState(0);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [timer, setTimer] = useState(55);
  const { regUser } = useAuth();
  const { markFormCompleted } = useFormCompletion();

  const navigate = useNavigate();

  useEffect(() => {
    if (timer > 0) {
      const myInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [timer]);

  useEffect(() => {
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): void => {
    if (/^\d*$/.test(value) && formik?.values?.otp?.length === 6) {
      const newOtp = [...formik?.values?.otp];
      newOtp[index] = value;
      formik?.setFieldValue("otp", newOtp);

      if (value && index < formik?.values?.otp?.length - 1) {
        setActiveInput(index + 1);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): void => {
    if (e.key === "Backspace") {
      if (formik?.values?.otp[index] !== "") {
        const newOtp = [...formik?.values?.otp];
        newOtp[index] = "";
        formik?.setFieldValue("otp", newOtp);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...formik?.values?.otp];
        newOtp[index - 1] = "";
        formik?.setFieldValue("otp", newOtp);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      otp: ["", "", "", "", "", ""],
      status: "",
    },
    validationSchema: Yup.object().shape({
      otp: Yup.array()
        .of(
          Yup.string().matches(/^\d$/, "Each OTP field must be a single digit")
        )
        .length(6, "OTP must be exactly 6 digits")
        .test(
          "is-full",
          "OTP must be exactly 6 digits",
          (value) => value?.join("").length === 6
        ),
    }),
    onSubmit: async (values, { setStatus, resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      
      try {
        const data = await verifyOtp(
          regUser?.mobile ?? "",
          regUser?.isdCode ?? 91,
          values?.otp.join(""),
          "WEB",
          "SIGNUP",
          regUser?.appId ?? ""
        );
    
        if (data?.data?.status?.statusCode === 0) {
          markFormCompleted("verify");
          navigate("/auth/password");
          resetForm();
          setActiveInput(0);
        } else {
          formik.setFieldValue("status", data?.data?.status?.messageDescription);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    }
    
  });

  const handleResend = () => {
    setTimer(55);
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div>
        <div>
          <p className="fs-2 fw-bold text-center">
            Please enter the OTP for validation
          </p>
        </div>
        <div className="mt-20">
          <p className="text-center">
            OTP has been sent to +{regUser?.isdCode || ""}{" "}
            {regUser?.mobile
              ? `${regUser.mobile.slice(0, 3)} ${"*".repeat(
                  Math.max(0, regUser.mobile.length - 5)
                )}${regUser.mobile.slice(-2)}`
              : ""}
          </p>
        </div>
        {formik?.values?.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {formik?.values?.status}
            </div>
          </div>
        )}
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <div className="d-flex justify-content-center mt-20">
            <OTPInput
              value={formik?.values?.otp?.join("")}
              onChange={(value: string) => {
                value
                  .split("")
                  .forEach((char, index) => handleChange(char, index));
              }}
              numInputs={6}
              renderSeparator={<span>&nbsp;&nbsp;</span>}
              renderInput={(props, index) => (
                <input
                  {...props}
                  value={formik?.values?.otp[index]}
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={(e) => {
                    handleChange(e.target.value, index);
                  }}
                  onKeyDown={(e) => {
                    handleKeyDown(e, index);
                  }}
                  type={show ? "password" : "text"}
                  autoFocus={true}
                  disabled={index !== activeInput}
                  onFocus={() => setActiveInput(index)}
                  className="text-center rounded mt-3 border border-1 border-dark"
                  style={{ height: "50px", width: "50px" }}
                />
              )}
            />
          </div>
          <p
            className="text-center cursor-pointer text-deoration-underline mt-2"
            onClick={() => setShow(!show)}
          >
            {show ? "Show PIN" : "Hide PIN"}
          </p>
          {formik.touched.otp && formik.errors.otp && (
            <div className="text-danger mt-1">
              {formik.errors.otp.toString()}
            </div>
          )}

          <div className="mt-10" style={{ textAlign: "center" }}>
            <p>
              {timer === 0 ? (
                <span
                  className="text-primary cursor-pointer"
                  onClick={handleResend}
                >
                  Resend
                </span>
              ) : (
                <span>
                  {" "}
                  Resend code in <span className="text-primary">
                    {timer}
                  </span>{" "}
                  seconds
                </span>
              )}
            </p>
          </div>

          <div className="text-center mt-10">
              <button
                type="submit"
                id="kt_sign_up_submit"
                className="btn btn-lg btn w-100"
                disabled={formik.isSubmitting || loading}
              >
                {loading ? (
                  <div className=" spinner-border text-primary" role="status">
                    {/* <span className="" role="status" aria-hidden="true"></span> */}
                  </div>
                ) : (
                  <div>Verify</div>
                )}
              </button>
            </div>
        </form>
      </div>
    </div>
  );
};

export default OtpScreen1;
