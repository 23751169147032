export interface PayBillData {
  userId: string;
  tenantId: string;
  customerName: string;
  billerNames: string[];
  billerType: string;
  customerIdBiller: string;
  amountDue: number;
  dueDate?: string;
  billCurrency: string;
  billStatus: string;
}

export interface PayBill {
  accountNumber: string;
  accountType: string;
  currency: string;
  amountDue: number;
  transactionPIN: string;
  billerType: string; //Electricity , Internet , Airtime , Water
  billerNames: string[];
  customerIdBiller: string;
  connectionType: string;
}

export interface PayBillResponse {
  Amount: string;
  "Service Charge": string;
  "Bank Account": string;
  "Biller Name": string[];
  "Biller Type": string;
  "Customer Id": string;
  Date: string;
  Time: string;
  "Transaction Id": string;
  Type: string;
  Status: string;
}

export interface Accounts {
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
  subAccountExist: boolean;
  cif: string;
  swiftCode: string;
  createdDate: string;
  accountHolderName: string;
  accountHolderAddress: string;
  accStatus: string;
}

export interface TxnData {
  amount: number;
  txnId: string;
  txnStatus: number;
  accountNumber: string;
  currency: string;
  txnTypeName: string;
  dateTime: string;
  txnType: number;
  category: string;
  note: string;
  scheduled: string;
  hours: string;
  receipt: Record<string, unknown>[];
  businessGuid: string;
  senderName: string;
  senderCustomerId: string;
  benefName: string;
  benefCustomerId: string;
}

export interface ScheduleReceipt {
  Amount: string;
  "Payee Name": string;
  "Bank Account": string;
  "Schedule Date": string;
  Status: string; // Adjust based on actual values
}

interface Transaction {
  amount: number;
  currency: string;
  txnTypeName: string;
  dateTime: string;
  txnType: string;
}

interface Account {
  tenantId: string;
  functionCode: string;
  primaryRefId: string;
  primaryRefType: number;
  primaryFundId: string;
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
  transactions: Transaction[];
}

export type SubData = Account[];

export interface Receipt {
  Amount: string;
  "Txn Type": string;
  Date: string;
  Time: string;
  "Transaction Id": string;
  Type: string;
}

export interface AccountStmnt {
  amount: number;
  txnId: string;
  txnStatus: number;
  currency: string;
  txnTypeName: string;
  dateTime: string;
  txnType: string;
  receipt: Receipt;
}

export interface BillerData {
  billerNames: string[];
  customerIdndBiller: string[];
}

export interface GraphData {
  index: number;
  name: string;
  data: Array<{
    key: string;
    value: string;
  }>;
}

export interface StatisticsData {
  userId: string;
  tenantId: string;
  accountNumber: string;
  referenceId: string;
  referenceType: number;
  currency: string;
  stmntType: string;
  stmntDuration: number;
  graphData: GraphData[];
  income: number;
  expense: number;
}

export interface PasswordResponse {
  userId: string;
  tenantId: string;
  referenceId: string;
  referenceType: number;
  appId: string;
  mobile: string;
  email: string;
  isdCode: number;
  password: string;
  userStatus: number;
  customerCategory: string;
  businessGuid: string;
  status: {
    statusCode: number;
    messageDescription: string;
  };
}

export interface OtpResponse {
  tenantId: string;
  isdCode: number;
  mobile: string;
  channel: string;
  otpType: string;
  otp: string;
}

export interface VerifyOtpResponse {
  userId: string;
  tenantId: string;
  isdCode: number;
  mobile: string;
  channel: string;
  otpType: string;
  otp: string;
  token: string;
}

export interface UpdatePasswordResponse {
  statusCode: number;
}

export function formatAmount(amount: string): string {
  // Convert the input to a number
  const numericAmount =
    typeof amount === "string" ? parseFloat(amount) : amount;

  // Check if the conversion was successful
  if (!isNaN(numericAmount)) {
    // Format the number using toLocaleString
    return numericAmount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    // Return the original string if the conversion failed
    return amount.toString();
  }
}
