import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";

  import notificationReducer from "../firebase/NotificationSlice"
  import roleReducer from "./pages/settings/RuleSettingsSlice"
  import vendorReducer from "./pages/IncomingInvoice/vendorSlice";
  import ruleDashboardSlice from "./pages/settings/RuleDashboardSlice"
  
  
  const rootReducer = combineReducers({
   notificationLogs:notificationReducer,
   role: roleReducer,
   vendors: vendorReducer,
  banks: vendorReducer,
  ruleDashboard:ruleDashboardSlice
  });
  
  const store = configureStore({
    reducer: rootReducer,
  });
  
  export type RootState = ReturnType<typeof store.getState>;
  export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
  >;
  
  export default store;

