import React from 'react'

type Props = {
  className?: string
  path: string
  svgClassName?: string
}

const KTSVG: React.FC<Props> = ({className = ''}) => {
  return (
    <span className={`svg-icon ${className}`}>
      {/* <SVG src={toAbsoluteUrl(path)} className={svgClassName} /> */}
    </span>
  )
}

export {KTSVG}
