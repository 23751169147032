import { useState, useRef, useEffect, ChangeEvent } from "react";
import { Modal } from "bootstrap";
import { InventoryData } from "./data";
import { InventoryEditData } from "./data";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";
import InvoiceDetailsModal from "./InventoryDetailsModal/InventoryDetails";
import Swal from "sweetalert2";
import { formatAmount } from "../../../interface/Interface";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import Authorize from "../../../modules/auth/components/Authorize";
import Loader from "../../components/Loader";
import { DrawerComponent } from "../../../../_metronic/assets/ts/components";

interface Data {
  itemName: string;
  itemRate: string;
  itemAvailability: boolean;
  itemDesc: string;
  itemStatus: number;
  itemGuid: string;
}

interface EditData {
  itemName: string;
  itemRate: string;
  itemAvailability: boolean;
  itemDesc: string;
  itemStatus: number;
  itemGuid: string;
}

export default function Inventory() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const modalRef = useRef<HTMLDivElement | null>(null);
  const editmodalRef = useRef<HTMLDivElement | null>(null);

  const [ItemData, setItemData] = useState<Data[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [filteredTransactions, setFilteredTransactions] = useState(ItemData);
  const [selectedData, setSelectedData] = useState<Data | null>(null);
  const [editData, setEditData] = useState<EditData | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  const formikRef = useRef<FormikProps<any>>(null);

  const tooltipEdit = <Tooltip id="tooltip-add-money">Edit</Tooltip>;
  const tooltipDelete = <Tooltip id="tooltip-add-money">Delete</Tooltip>;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const SuccessModal = useRef<HTMLDivElement | null>(null);
  const DeleteModal = useRef<HTMLDivElement | null>(null);
  const DeleteConfirm = useRef<HTMLDivElement | null>(null);

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener("hidden.bs.modal", handleCloseModal);
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);
  useEffect(() => {
    if (editmodalRef.current) {
      const modal = new Modal(editmodalRef.current);
      editmodalRef.current.addEventListener(
        "hidden.bs.modal",
        handleCloseModal
      );
      return () => {
        editmodalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleEditCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    if (SuccessModal.current) {
      const modal = new Modal(SuccessModal.current);
      SuccessModal.current.addEventListener(
        "hidden.bs.modal",
        handleSuccessCloseModal
      );
      return () => {
        SuccessModal.current?.removeEventListener(
          "hidden.bs.modal",
          handleSuccessCloseModal
        );
        modal.dispose();
      };
    }
  }, []);
  useEffect(() => {
    if (DeleteModal.current) {
      const modal = new Modal(DeleteModal.current);
      DeleteModal.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        DeleteModal.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);
  useEffect(() => {
    if (DeleteConfirm.current) {
      const modal = new Modal(DeleteConfirm.current);
      DeleteConfirm.current.addEventListener(
        "hidden.bs.modal",
        handleConfirmCloseModal
      );
      return () => {
        DeleteConfirm.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    // Filter data based on both selected tab and search query
    const filteredData = ItemData?.filter((employee) => {
      // Filter based on selected tab
      let isTabMatched = false;
      switch (selectedTab) {
        case "All":
          isTabMatched = true; // Always match for "All" tab
          break;
        case "Available":
          isTabMatched = employee.itemAvailability ? true : false;
          break;
        case "Not Available":
          isTabMatched = !employee.itemAvailability ? true : false;
          break;
        default:
          isTabMatched = true; // Default to true for unknown tabs
      }

      // Filter based on search query
      const isSearchMatched =
        employee.itemName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        employee.itemDesc.toLowerCase().includes(searchQuery.toLowerCase()) ||
        employee.itemStatus.toString().includes(searchQuery.toLowerCase());

      // Return true if both conditions are matched
      return isTabMatched && isSearchMatched;
    });

    // Update filtered transactions
    setFilteredTransactions(filteredData);
  }, [ItemData, searchQuery, selectedTab]);

  const handleCloseModal = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };
  const handleSuccessCloseModal = () => {
    const modalElement = SuccessModal.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };
  const handleDeleteCloseModal = () => {
    const modalElement = DeleteModal.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };
  const handleConfirmCloseModal = () => {
    const modalElement = DeleteConfirm.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };
  const handleEditCloseModal = () => {
    const modalElement = editmodalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };
  const handleOpenModal = () => {
    const modalElement = document.getElementById("item-modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
    if (formikRef.current) {
      formikRef.current.resetForm(); // This should now be correctly recognized
    }
  };

  const handleDetailsClick = (index: number) => {
    const clickedData: Data = currentData[index];
    setSelectedData(() => clickedData);
  };

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };
  const handleEditClick = (index: number) => {
    if (formikRef.current) {
      formikRef.current.resetForm(); // This should now be correctly recognized
    }
    const clickedData: EditData = currentData[index];
    InventoryEditData.itemGuid = clickedData.itemGuid;
    setEditData(clickedData); // Update editData state with clickedData
    console.log(editData);

    const modalElement = document.getElementById("item-Editmodal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };
  const handleEditAvailabilityClick = (index: number) => {
    const clickedData: EditData = currentData[index];
    InventoryEditData.itemGuid = clickedData.itemGuid;
    InventoryEditData.itemName = clickedData.itemName || "";
    InventoryEditData.itemDesc = clickedData.itemDesc || "";
    InventoryEditData.itemRate = clickedData.itemRate || "";
    InventoryEditData.itemAvailability = clickedData.itemAvailability
      ? false
      : true;

    setEditData(clickedData); // Update editData state with clickedData (if needed)

    updateAvailability();
  };

  // ... rest of your code ...

  const handleDeleteClick = (index: number) => {
    const clickedData: Data = currentData[index];

    const deleteItem = async () => {
      try {
        const url = `${API_URL}/inventory/deleteItem`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          itemGuid: clickedData.itemGuid,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        if (data.status.statusCode === 0) {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "The selected item has been deleted",
            confirmButtonColor: "#007bff",
            confirmButtonText: "Ok",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }
        viewItem();
      } catch (error) {
        console.log(error);
      }
    };

    Swal.fire({
      icon: "warning",
      title: "Delete item",
      text: "Are you sure you want to delete the selected item ?",
      showCancelButton: true,
      confirmButtonColor: "#007bff",
      confirmButtonText: "Yes",
      cancelButtonColor: "#9fa6b2",
      cancelButtonText: "No",
      allowEscapeKey: true,
      allowEnterKey: true,
    }).then((res) => {
      if (res.isConfirmed) deleteItem();
    });
    // const modalElement = document.getElementById("delete_modal");
    // const modal = new Modal(modalElement as Element);
    // modal.show();
  };

  const addItem = async () => {
    try {
      const url = `${API_URL}/inventory/addItem`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...InventoryData,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        viewItem();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Item added",
          confirmButtonColor: "#007bff",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      // if (data.status.statusCode === 1) {
      //   viewItem();
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error",
      //     text: data?.status?.messageDescription,
      //     confirmButtonColor: "#007bff",
      //     allowEscapeKey: true,
      //     allowEnterKey: true,
      //   });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const updateAvailability = async () => {
    try {
      const url = `${API_URL}/inventory/updateItem`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...InventoryEditData,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0 && data.message.itemAvailability) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Item made available",
          confirmButtonColor: "#007bff",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      } else if (
        data.status.statusCode === 0 &&
        !data.message.itemAvailability
      ) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Item made unavailable",
          confirmButtonColor: "#007bff",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }

      viewItem();
    } catch (error) {
      console.log(error);
    }
  };

  const updateItem = async () => {
    try {
      const url = `${API_URL}/inventory/updateItem`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...InventoryEditData,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Item made updated",
          confirmButtonColor: "#007bff",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      // if (data.status.statusCode === 1) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error",
      //     text: data?.status?.messageDescription,
      //     confirmButtonColor: "#007bff",
      //     allowEscapeKey: true,
      //     allowEnterKey: true,
      //   });
      // }

      viewItem();
    } catch (error) {
      console.log(error);
    }
  };

  const viewItem = async () => {
    try {
      const url = `${API_URL}/inventory/viewItem`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setItemData(data.results);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    viewItem();
  }, []);

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "All") {
      setFilteredTransactions(ItemData);
    } else if (tab === "Available") {
      const filteredData = ItemData.filter((data) =>
        data.itemAvailability ? true : false
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Not Available") {
      const filteredData = ItemData.filter((data) =>
        !data.itemAvailability ? true : false
      );
      setFilteredTransactions(filteredData);
    }
  };

  useEffect(() => {
    DrawerComponent.reinitialization();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <style>
            {`
          #itemAvailability {
            background-color: #0000ff;
          }
        `}
          </style>
          <div>
            <h4 className="mb-4">Inventory</h4>
          </div>
          <div className="card shadow py-2 p-5">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <ul className="nav nav-tabs nav-line-tabs ms-5 fs-6 d-flex flex-nowrap">
                <li className="nav-item" key={1}>
                  <a
                    className="nav-link active text-active-gray-800"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_1"
                    onClick={() => handleTabClick("All")}
                  >
                    All
                  </a>
                </li>
                <li className="nav-item" key={2}>
                  <a
                    className="nav-link text-active-gray-800"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_2"
                    onClick={() => handleTabClick("Available")}
                  >
                    Available
                  </a>
                </li>
                <li className="nav-item" key={3}>
                  <a
                    className="nav-link text-nowrap text-active-gray-800"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_3"
                    onClick={() => handleTabClick("Not Available")}
                  >
                    Not Available
                  </a>
                </li>
              </ul>

              <div className="d-flex justify-content-center align-items-center p-3 gap-10">
                <div className="d-flex align-items-center gap-10">
                  <div className="d-flex align-items-center  position-relative my-1">
                    <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input
                      value={searchQuery}
                      onChange={handleSearchChange}
                      type="text"
                      data-kt-docs-table-filter="search"
                      className="form-control  p-3 border-secondary form-control-solid w-250px ps-15"
                      placeholder="Search item"
                    />
                  </div>
                </div>
                <Authorize hasAnyPermission={["MENU_INVENTRY|ADD"]}>
                  <button className="btn" onClick={handleOpenModal}>
                    Add Item
                  </button>
                </Authorize>
              </div>
            </div>

            <div
              className="tab-content "
              id="myTabContent"
              style={{
                overflow: "scroll",
              }}
            >
              <div
                className="tab-pane fade show active"
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <>
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6  ">
                          <th className="min-w-100px p-5">Item Name</th>
                          <th className="min-w-100px p-5">Item Description</th>

                          <th className="min-w-100px p-5 text-nowrap">
                            Item Rate
                          </th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Item Availability
                          </th>

                          <th className="min-w-100px p-5 text-nowrap">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentData?.length < 1 ? (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        ) : (
                          currentData?.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                onMouseOver={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "#f1f1f2";
                                  // target.style.transform =
                                  //   "translate3d(6px, -6px, 0)";
                                  // target.style.boxShadow =
                                  //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                }}
                                onMouseOut={(e) => {
                                  const target = e.currentTarget as HTMLElement;
                                  target.style.backgroundColor = "white";
                                  // target.style.transform = "none";
                                  // target.style.boxShadow = "none";
                                }}
                              >
                                <td
                                  className="p-5 fw-hover-bold fs-6 cursor-pointer"
                                  onClick={() => handleDetailsClick(index)}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="left"
                                  data-bs-dismiss="click"
                                  data-bs-trigger="hover"
                                  id="kt_modal_toggle_inventory"
                                >
                                  {item.itemName?.length > 40 ? (
                                    <OverlayTrigger
                                      trigger={["hover", "focus"]}
                                      placement="top"
                                      overlay={
                                        <Popover
                                          className="p-2 bg-light-secondary"
                                          id="popover-trigger-focus"
                                        >
                                          {item.itemName}
                                        </Popover>
                                      }
                                    >
                                      <p className="text-primary text-hover-dark text-decoration-underline mb-1 fs-7">
                                        {item.itemName?.length > 40
                                          ? `${item.itemName.slice(0, 40)}...`
                                          : item.itemName}
                                      </p>
                                    </OverlayTrigger>
                                  ) : (
                                    <p className="text-primary text-hover-dark text-decoration-underline  mb-1 fs-7">
                                      {item.itemName}
                                    </p>
                                  )}
                                </td>
                                <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                  {item.itemDesc?.length > 40 ? (
                                    <OverlayTrigger
                                      trigger={["hover", "focus"]}
                                      placement="top"
                                      overlay={
                                        <Popover
                                          className="p-2 bg-light-secondary"
                                          id="popover-trigger-focus"
                                        >
                                          {item.itemDesc}
                                        </Popover>
                                      }
                                    >
                                      <p className="text-gray-800 mb-1 fs-7">
                                        {item.itemDesc?.length > 40
                                          ? `${item.itemDesc.slice(0, 40)}...`
                                          : item.itemDesc}
                                      </p>
                                    </OverlayTrigger>
                                  ) : (
                                    <p className="text-gray-800 mb-1 fs-7">
                                      {item.itemDesc}
                                    </p>
                                  )}
                                </td>

                                <td className="p-5 text-gray-600">
                                  {baseCurrency} {formatAmount(item.itemRate)}
                                </td>
                                <td className="p-5 text-gray-600">
                                  <Authorize
                                    hasAnyPermission={["MENU_INVENTRY|EDIT"]}
                                  >
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input h-20px w-30px cursor-pointer"
                                        type="checkbox"
                                        id="flexSwitchChecked"
                                        style={{
                                          backgroundColor: item.itemAvailability
                                            ? "#0000FF"
                                            : "#dbdfe9",
                                        }}
                                        checked={item.itemAvailability}
                                        onChange={() =>
                                          handleEditAvailabilityClick(index)
                                        }
                                      />
                                    </div>
                                  </Authorize>
                                </td>
                                <td className="p-5 text-gray-600 p-2">
                                  <div className="d-flex gap-10">
                                    <Authorize
                                      hasAnyPermission={["MENU_INVENTRY|EDIT"]}
                                    >
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={tooltipEdit}
                                      >
                                        <i
                                          className="bi bi-pencil-fill cursor-pointer"
                                          style={{ color: "#0000ff" }}
                                          onClick={() => handleEditClick(index)}
                                        ></i>
                                      </OverlayTrigger>
                                    </Authorize>
                                    <Authorize
                                      hasAnyPermission={[
                                        "MENU_INVENTRY|DELETE",
                                      ]}
                                    >
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={tooltipDelete}
                                      >
                                        <i
                                          className="bi bi-trash cursor-pointer"
                                          style={{ color: "#FF0000" }}
                                          onClick={() =>
                                            handleDeleteClick(index)
                                          }
                                        ></i>
                                      </OverlayTrigger>
                                    </Authorize>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                    {/* <PayrollDetailsModal Data={selectedData} /> */}
                  </div>
                </>
                {/* <TransferModal Data={selectedData} /> */}
                {/* <DeleteModal /> */}
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                <>
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6  ">
                          <th className="min-w-100px p-5">Item Name</th>
                          <th className="min-w-100px p-5">Item Description</th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Item Rate
                          </th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Item Availability
                          </th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentData?.every(
                          (item) => !item.itemAvailability
                        ) ? (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        ) : (
                          currentData?.map((item, index) => {
                            if (item.itemAvailability) {
                              return (
                                <tr
                                  key={index}
                                  onMouseOver={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "#f1f1f2";
                                    // target.style.transform =
                                    //   "translate3d(6px, -6px, 0)";
                                    // target.style.boxShadow =
                                    //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                  }}
                                  onMouseOut={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "white";
                                    // target.style.transform = "none";
                                    // target.style.boxShadow = "none";
                                  }}
                                >
                                  <td
                                    className="p-5 fw-hover-bold fs-6 cursor-pointer"
                                    onClick={() => handleDetailsClick(index)}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    data-bs-dismiss="click"
                                    data-bs-trigger="hover"
                                    id="kt_modal_toggle_inventory"
                                  >
                                    {item.itemName?.length > 40 ? (
                                      <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="top"
                                        overlay={
                                          <Popover
                                            className="p-2 bg-light-secondary"
                                            id="popover-trigger-focus"
                                          >
                                            {item.itemName}
                                          </Popover>
                                        }
                                      >
                                        <p className="text-primary text-hover-dark text-decoration-underline mb-1 fs-7">
                                          {item.itemName?.length > 40
                                            ? `${item.itemName.slice(0, 40)}...`
                                            : item.itemName}
                                        </p>
                                      </OverlayTrigger>
                                    ) : (
                                      <p className="text-primary text-hover-dark text-decoration-underline  mb-1 fs-7">
                                        {item.itemName}
                                      </p>
                                    )}
                                  </td>
                                  <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                    {item.itemDesc?.length > 40 ? (
                                      <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="top"
                                        overlay={
                                          <Popover
                                            className="p-2 bg-light-secondary"
                                            id="popover-trigger-focus"
                                          >
                                            {item.itemDesc}
                                          </Popover>
                                        }
                                      >
                                        <p className="text-gray-800 mb-1 fs-7">
                                          {item.itemDesc?.length > 40
                                            ? `${item.itemDesc.slice(0, 40)}...`
                                            : item.itemDesc}
                                        </p>
                                      </OverlayTrigger>
                                    ) : (
                                      <p className="text-gray-800 mb-1 fs-7">
                                        {item.itemDesc}
                                      </p>
                                    )}
                                  </td>

                                  <td className="p-5 text-gray-600">
                                    {baseCurrency} {formatAmount(item.itemRate)}
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    <Authorize
                                      hasAnyPermission={["MENU_INVENTRY|EDIT"]}
                                    >
                                      <div className="form-check form-switch form-check-custom form-check-solid">
                                        <input
                                          className="form-check-input h-20px w-30px cursor-pointer"
                                          type="checkbox"
                                          value=""
                                          id="flexSwitchChecked"
                                          checked={item.itemAvailability}
                                          style={{
                                            backgroundColor:
                                              item.itemAvailability
                                                ? "#0000FF"
                                                : "#dbdfe9",
                                          }}
                                          onClick={() =>
                                            handleEditAvailabilityClick(index)
                                          }
                                        />
                                      </div>
                                    </Authorize>
                                  </td>
                                  <td className="p-5 text-gray-600 p-2">
                                    <div className="d-flex gap-10">
                                      <Authorize
                                        hasAnyPermission={[
                                          "MENU_INVENTRY|EDIT",
                                        ]}
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltipEdit}
                                        >
                                          <i
                                            className="bi bi-pencil-fill cursor-pointer"
                                            style={{ color: "#0000ff" }}
                                            onClick={() =>
                                              handleEditClick(index)
                                            }
                                          ></i>
                                        </OverlayTrigger>
                                      </Authorize>
                                      <Authorize
                                        hasAnyPermission={[
                                          "MENU_INVENTRY|DELETE",
                                        ]}
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltipDelete}
                                        >
                                          <i
                                            className="bi bi-trash cursor-pointer"
                                            style={{ color: "#FF0000" }}
                                            onClick={() =>
                                              handleDeleteClick(index)
                                            }
                                          ></i>
                                        </OverlayTrigger>
                                      </Authorize>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                            return null;
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                <>
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6  ">
                          <th className="min-w-100px p-5">Item Name</th>
                          <th className="min-w-100px p-5">Item Description</th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Item Rate
                          </th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Item Availability
                          </th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentData?.every((item) => item.itemAvailability) ? (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        ) : (
                          currentData?.map((item, index) => {
                            if (!item.itemAvailability) {
                              return (
                                <tr
                                  key={index}
                                  onMouseOver={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "#f1f1f2";
                                  }}
                                  onMouseOut={(e) => {
                                    const target =
                                      e.currentTarget as HTMLElement;
                                    target.style.backgroundColor = "white";
                                  }}
                                >
                                  <td
                                    className="p-5 fw-hover-bold fs-6 cursor-pointer"
                                    onClick={() => handleDetailsClick(index)}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    data-bs-dismiss="click"
                                    data-bs-trigger="hover"
                                    id="kt_modal_toggle_inventory"
                                  >
                                    {item.itemName?.length > 40 ? (
                                      <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="top"
                                        overlay={
                                          <Popover
                                            className="p-2 bg-light-secondary"
                                            id="popover-trigger-focus"
                                          >
                                            {item.itemName}
                                          </Popover>
                                        }
                                      >
                                        <p className="text-primary text-hover-dark text-decoration-underline mb-1 fs-7">
                                          {item.itemName?.length > 40
                                            ? `${item.itemName.slice(0, 40)}...`
                                            : item.itemName}
                                        </p>
                                      </OverlayTrigger>
                                    ) : (
                                      <p className="text-primary text-hover-dark text-decoration-underline  mb-1 fs-7">
                                        {item.itemName}
                                      </p>
                                    )}
                                  </td>
                                  <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                    {item.itemDesc?.length > 40 ? (
                                      <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="top"
                                        overlay={
                                          <Popover
                                            className="p-2 bg-light-secondary"
                                            id="popover-trigger-focus"
                                          >
                                            {item.itemDesc}
                                          </Popover>
                                        }
                                      >
                                        <p className="text-gray-800 mb-1 fs-7">
                                          {item.itemDesc?.length > 40
                                            ? `${item.itemDesc.slice(0, 40)}...`
                                            : item.itemDesc}
                                        </p>
                                      </OverlayTrigger>
                                    ) : (
                                      <p className="text-gray-800 mb-1 fs-7">
                                        {item.itemDesc}
                                      </p>
                                    )}
                                  </td>

                                  <td className="p-5 text-gray-600">
                                    {baseCurrency} {formatAmount(item.itemRate)}
                                  </td>
                                  <td className="p-5 text-gray-600">
                                    <Authorize
                                      hasAnyPermission={["MENU_INVENTRY|EDIT"]}
                                    >
                                      <div className="form-check form-switch form-check-custom form-check-solid">
                                        <input
                                          className="form-check-input h-20px w-30px cursor-pointer"
                                          type="checkbox"
                                          value=""
                                          id="flexSwitchChecked"
                                          checked={item.itemAvailability}
                                          style={{
                                            backgroundColor:
                                              item.itemAvailability
                                                ? "#0000FF"
                                                : "#dbdfe9",
                                          }}
                                          onClick={() =>
                                            handleEditAvailabilityClick(index)
                                          }
                                        />
                                      </div>
                                    </Authorize>
                                  </td>
                                  <td className="p-5 text-gray-600 p-2">
                                    <div className="d-flex gap-10">
                                      <Authorize
                                        hasAnyPermission={[
                                          "MENU_INVENTRY|EDIT",
                                        ]}
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltipEdit}
                                        >
                                          <i
                                            className="bi bi-pencil-fill cursor-pointer"
                                            style={{ color: "#0000ff" }}
                                            onClick={() =>
                                              handleEditClick(index)
                                            }
                                          ></i>
                                        </OverlayTrigger>
                                      </Authorize>
                                      <Authorize
                                        hasAnyPermission={[
                                          "MENU_INVENTRY|DELETE",
                                        ]}
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltipDelete}
                                        >
                                          <i
                                            className="bi bi-trash cursor-pointer"
                                            style={{ color: "#FF0000" }}
                                            onClick={() =>
                                              handleDeleteClick(index)
                                            }
                                          ></i>
                                        </OverlayTrigger>
                                      </Authorize>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                            return null;
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="p-5">
                <ul className="pagination">
                  <li
                    className={`page-item double-arrow ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    key={0}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() => handlePageChange(1)}
                    >
                      <i className="previous"></i>
                      <i className="previous"></i>
                    </a>
                  </li>
                  <li
                    className={`page-item previous ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    key={1}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <i className="previous"></i>
                    </a>
                  </li>
                  {renderPageNumbers()}
                  <li
                    className={`page-item next ${
                      currentPage ===
                      Math.ceil(filteredTransactions?.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                    key={2}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <i className="next"></i>
                    </a>
                  </li>
                  <li
                    className={`page-item double-arrow ${
                      currentPage ===
                      Math.ceil(filteredTransactions?.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                    key={3}
                  >
                    <a
                      href="#"
                      className="page-link"
                      onClick={() =>
                        handlePageChange(
                          Math.ceil(filteredTransactions?.length / itemsPerPage)
                        )
                      }
                    >
                      <i className="next"></i>
                      <i className="next"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="p-5 d-flex align-items-center">
                <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                  Total: {filteredTransactions?.length}&nbsp;&nbsp;
                </label>
                <select
                  id="itemsPerPage"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
                >
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={75}>75</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            tabIndex={-1}
            id="item-modal"
            ref={modalRef}
          >
            <div
              className="modal-dialog modal-dialog w-400px"
              style={{ margin: "0 auto", marginTop: "10%" }}
            >
              <div className="modal-content" style={{ padding: "10px" }}>
                <div className="modal-header">
                  <h3
                    className="modal-title text-nowrap m-0"
                    // style={{ color: "#246bfd" }}
                  >
                    Create item
                  </h3>

                  {/* <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div> */}
                </div>

                <div className="modal-body">
                  <Formik
                    innerRef={formikRef}
                    enableReinitialize={true}
                    initialValues={{
                      itemName: "",
                      itemDesc: "",
                      itemRate: "",
                      itemAvailability: true,
                    }}
                    validationSchema={Yup.object({
                      itemName: Yup.string()
                        .min(2, "Minimum 2 characters required")
                        .max(50, "Maximum 50 characters allowed")
                        .matches(
                          /^(?=.*[a-zA-Z])[\sa-zA-Z0-9]*$/,
                          "Must contain at least one alphabetic character"
                        )
                        .matches(
                          /^[\sa-zA-Z0-9]*$/,
                          "Only alphanumeric characters and spaces are allowed"
                        )
                        .test(
                          "no-only-spaces",
                          "Cannot contain only spaces",
                          (value) => {
                            if (typeof value === "string") {
                              return !/^\s+$/.test(value);
                            }
                            return false;
                          }
                        )
                        .trim()
                        .required("Item Name Required"),
                      itemDesc: Yup.string()
                        .required("Item Description Required")
                        .max(
                          100,
                          "Item Description must be at most 100 characters long"
                        ),
                      itemRate: Yup.number()
                        .min(1, "Item Rate must be greater than 0")
                        .required("Item Rate is Required"),
                      itemAvailability: Yup.boolean().required(
                        "Item Availability Required"
                      ),
                      // itemAvailability: Yup.boolean().test('itemAvailability', 'Item Availability Required', value => value === true)
                    })}
                    onSubmit={(values, { resetForm }) => {
                      InventoryData.itemName = values?.itemName;
                      InventoryData.itemDesc = values?.itemDesc;
                      InventoryData.itemRate = values?.itemRate;
                      InventoryData.itemAvailability = values?.itemAvailability;
                      addItem();
                      handleCloseModal();
                      resetForm();
                    }}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div>
                          <div className="mb-5">
                            <label className="form-label">Item Name</label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Item Name"
                              name="itemName"
                              maxLength={50}
                            />
                            <ErrorMessage
                              name="itemName"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-5">
                            <label className="form-label">
                              Item Description
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Item Description"
                              name="itemDesc"
                              maxLength={100}
                            />
                            <ErrorMessage
                              name="itemDesc"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-5">
                            <label className="form-label">Item Rate</label>
                            <span
                              className="fs-6 fw-bold text-gray-700"
                              style={{
                                position: "absolute",
                                padding: "12px",
                                right: "305px",
                                marginTop: "25px",
                              }}
                            >
                              {baseCurrency}
                            </span>
                            <Field
                              type="text"
                              className="form-control "
                              placeholder="Item Rate"
                              name="itemRate"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const value = e.target.value;

                                if (value === "0" || value === "") {
                                  setFieldValue("itemRate", "");
                                  return;
                                }
                                // If the value is empty, set the previous value to an empty string
                                if (value === "") {
                                  setFieldValue("itemRate", value);
                                }

                                // Check if the value is a valid number with up to 2 decimal places and a single decimal point
                                if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
                                  setFieldValue("itemRate", value);
                                }
                              }}
                              style={{ paddingLeft: "60px" }}
                            />

                            <ErrorMessage
                              name="itemRate"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-10">
                            <div className="form-check form-switch form-check-custom form-check-solid d-flex flex-row justify-content-between">
                              <label
                                className="form-check-label fs-6 text-dark"
                                htmlFor="itemAvailability"
                              >
                                Item Availability
                              </label>
                              <Field
                                type="checkbox"
                                id="itemAvailability"
                                className="form-check-input inp"
                                name="itemAvailability"
                                onChange={() =>
                                  setFieldValue(
                                    "itemAvailability",
                                    !values.itemAvailability
                                  )
                                }
                                style={{
                                  backgroundColor: values.itemAvailability
                                    ? "#0000FF"
                                    : "#dbdfe9",
                                }}
                              />
                            </div>
                            <ErrorMessage
                              name="itemAvailability"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="">
                            <button type="submit" className="btn w-100">
                              Create
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            tabIndex={-1}
            id="item-Editmodal"
            ref={editmodalRef}
          >
            <div
              className="modal-dialog modal-dialog w-400px"
              style={{ margin: "0 auto", marginTop: "10%" }}
            >
              <div className="modal-content" style={{ padding: "10px" }}>
                <div className="modal-header">
                  <h3
                    className="modal-title text-nowrap m-0"
                    // style={{ color: "#246bfd" }}
                  >
                    Edit item
                  </h3>

                  {/* <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div> */}
                </div>

                <div className="modal-body">
                  <Formik
                    innerRef={formikRef}
                    enableReinitialize={true}
                    initialValues={{
                      itemName: editData ? editData.itemName : "",
                      itemDesc: editData ? editData.itemDesc : "",
                      itemRate: editData ? editData.itemRate : "",
                      itemAvailability: editData
                        ? editData.itemAvailability
                        : false, // Set a default value if not available
                    }}
                    validationSchema={Yup.object({
                      itemName: Yup.string()
                        .matches(
                          /^[a-zA-Z0-9 ]+$/,
                          "Only letters and numbers are allowed"
                        )
                        .required("Item Name Required"),
                      itemDesc: Yup.string().required(
                        "Item Description Required"
                      ),
                      itemRate: Yup.number().required("Item Rate Required"),
                      itemAvailability: Yup.boolean().required(
                        "Item Availability Required"
                      ),
                      // itemAvailability: Yup.boolean().test('itemAvailability', 'Item Availability Required', value => value === true)
                    })}
                    onSubmit={(values, { resetForm }) => {
                      InventoryEditData.itemName = values?.itemName;
                      InventoryEditData.itemDesc = values?.itemDesc;
                      InventoryEditData.itemRate = values?.itemRate;
                      InventoryEditData.itemAvailability =
                        values?.itemAvailability;
                      updateItem();
                      handleEditCloseModal();
                      resetForm();
                    }}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div>
                          <div className="mb-5">
                            <label className="form-label">Item Name</label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Item Name"
                              name="itemName"
                              maxLength={50}
                            />
                            <ErrorMessage
                              name="itemName"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-5">
                            <label className="form-label">
                              Item Description
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Item Description"
                              name="itemDesc"
                              maxLength={100}
                            />
                            <ErrorMessage
                              name="itemDesc"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-5">
                            <label className="form-label">Item Rate</label>
                            <span
                              className="fs-6 fw-bold text-gray-700"
                              style={{
                                position: "absolute",
                                padding: "12px",
                                right: "305px",
                                marginTop: "25px",
                              }}
                            >
                              {baseCurrency}
                            </span>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Item Rate"
                              name="itemRate"
                              style={{ paddingLeft: "60px" }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const value = e.target.value;

                                if (value === "0" || value === "") {
                                  setFieldValue("itemRate", "");
                                  return;
                                }
                                // If the value is empty, set the previous value to an empty string
                                if (value === "") {
                                  setFieldValue("itemRate", value);
                                }

                                // Check if the value is a valid number with up to 2 decimal places and a single decimal point
                                if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
                                  setFieldValue("itemRate", value);
                                }
                              }}
                            />

                            <ErrorMessage
                              name="itemRate"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="mb-10">
                            <div className="form-check form-switch form-check-custom form-check-solid d-flex flex-row justify-content-between">
                              <label
                                className="form-check-label fs-6 text-dark"
                                htmlFor="itemAvailability"
                              >
                                Item Availability
                              </label>
                              <Field
                                type="checkbox"
                                id="itemAvailability"
                                name="itemAvailability"
                                render={({
                                  field,
                                }: {
                                  field: {
                                    name: string;
                                    value: boolean;
                                    onChange: (
                                      e: React.ChangeEvent<any>
                                    ) => void;
                                  };
                                }) => (
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="form-check-input inp"
                                      id="itemAvailability"
                                      name={field.name}
                                      checked={field.value}
                                      onChange={field.onChange}
                                      style={{
                                        backgroundColor: values.itemAvailability
                                          ? "#0000FF"
                                          : "#dbdfe9",
                                      }}
                                    />
                                  </div>
                                )}
                              />
                            </div>
                            <ErrorMessage
                              name="itemAvailability"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="">
                            <button type="submit" className="btn w-100">
                              Submit
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <InvoiceDetailsModal Data={selectedData} />
        </>
      )}
    </div>
  );
}
