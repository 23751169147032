/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from "./PayrollAppModel";
import { useNavigate } from "react-router-dom";
import { EmployeeDetails } from "./Data";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const SteppperModal4 = ({ data, updateData }: StepProps) => {
  const navigate = useNavigate();
  console.log(data, updateData);

  return (
    <div className="pb-5 card shadow-sm p-10" data-kt-stepper-element="content">
      <div className="w-100">
        <div className="card shadow d-flex justify-content-center align-items-center p-5">
          <div className="p-5 ">
            <i className="bi bi-check-circle fs-5x text-success"></i>
          </div>
          <div className="label">
            <div className="modal-title-wrapper">
              <h1 className="modal-title" style={{ color: "#246bfd" }}>
                Confirmation !!!
              </h1>
            </div>
          </div>
          <div className="label p-0">
            <div className="lorem-ipsum-dolor-wrapper d-flex flex-column align-items-center">
              <p className="fs-7">Employee has been created !</p>
              <p className="fw-bold">
                Name:&nbsp;
                {EmployeeDetails.empName?.length > 25 ? (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    overlay={
                      <Popover
                        className="p-2 bg-light-secondary"
                        id="popover-trigger-focus"
                      >
                        {EmployeeDetails.empName
                          ?.split(",")
                          .map((part) => part.trim())
                          .join("\n")}
                      </Popover>
                    }
                  >
                    <span>
                      {EmployeeDetails.empName?.length > 25
                        ? `${EmployeeDetails.empName.slice(0, 25)}...`
                        : EmployeeDetails.empName}
                    </span>
                  </OverlayTrigger>
                ) : (
                  <span>
                    {EmployeeDetails.empName
                      ?.split(",")
                      .map((part) => part.trim())
                      .join("\n")}
                  </span>
                )}
              </p>

              <p className="fw-bold">
                Id:&nbsp;
                <span style={{ fontWeight: "normal" }}>
                  {EmployeeDetails.empCode}
                </span>{" "}
              </p>
            </div>
          </div>

          <div className="d-flex flex-column gap-5 mb-5">
            <button
              type="button"
              className="btn rounded"
              style={{
                width: "226px",
              }}
              onClick={() => {
                navigate("/tax-calculator");
              }}
            >
              View Payslip Structure
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
