import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { v5 as uuidv5 } from "uuid";
import { useAuth } from "../../core/Auth";
import { business, getAllData } from "./api";
import { useNavigate } from "react-router-dom";
import { useFormCompletion } from "./Stepper";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const getLocation = async () => {
  return new Promise<GeolocationCoordinates>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position.coords);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

const BusinessDetails = () => {
  const deviceId = uuidv4();
  const [loading, setLoading] = useState(false);
  const { regUser, setRegUser } = useAuth();
  const [uuidLocation, setUuidLocation] = useState<string | null>(null);
  const navigate = useNavigate();
  const { markFormCompleted } = useFormCompletion();

  const initialValues = {
    registeredBusinessName: regUser?.businessName ?? "",
    brandName: regUser?.brandName ?? "",
    businessRegistrationDate: regUser?.yearOfIncorporation?.split("T")[0] ?? "",
    businessRegistrationNumber: regUser?.vatNumber ?? "",
    cityOfBusiness: regUser?.city ?? "",
    status: "",
  };

  const getLocationAndGenerateUuid = async () => {
    try {
      const coords = await getLocation();
      const locationString = `${coords.latitude},${coords.longitude}`;
      setUuidLocation(locationString);
    } catch (error) {
      console.error("Error getting location:", error);
    }
  };

  useEffect(() => {
    getLocationAndGenerateUuid();
    sessionStorage.setItem("deviceId", deviceId);
  }, []);

  const validationSchema = Yup.object({
    registeredBusinessName: Yup.string()
      .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed")
      .required("Business name required")
      .test(
        "name-changed",
        "Business name must be different from the current value",
        function (value) {
          if (regUser?.reapplyParams?.businessName) {
            return value !== initialValues.registeredBusinessName;
          }
          return true;
        }
      ),

    brandName: Yup.string().matches(
      /^[a-zA-Z0-9 ]+$/,
      "Only letters and numbers are allowed"
    ),

    businessRegistrationDate: Yup.date()
      .required("Business registration date required")
      .test(
        "date-changed",
        "Registration date must be different from the current value",
        function (value) {
          if (regUser?.reapplyParams?.yearOfIncorporation) {
            const initialDate = new Date(
              initialValues.businessRegistrationDate
            );
            const newDate = new Date(value);
            initialDate.setHours(0, 0, 0, 0);
            newDate.setHours(0, 0, 0, 0);
            return initialDate.getTime() !== newDate.getTime();
          }
          return true; // No validation if reapplyParams is not present
        }
      ),

    businessRegistrationNumber: Yup.string()
      .matches(
        /^[a-zA-Z0-9]+$/,
        "Business registration number can only contain letters and numbers"
      )
      .required("Business registration number is required")
      .test(
        "number-changed",
        "Registration number must be different from the current value",
        function (value) {
          if (regUser?.reapplyParams?.vatNumber) {
            return value !== initialValues.businessRegistrationNumber;
          }
          return true; // No validation if reapplyParams is not present
        }
      ),

    cityOfBusiness: Yup.string()
      .matches(/^[a-zA-Z ]+$/, "Only letters are allowed")
      .required("City of business required")
      .test(
        "city-changed",
        "City of business must be different from the current value",
        function (value) {
          if (regUser?.reapplyParams?.city) {
            return value !== initialValues.cityOfBusiness;
          }
          return true; // No validation if reapplyParams is not present
        }
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setFieldValue, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);

      try {
        const res = await business(
          true,
          regUser?.mobile ?? "",
          regUser?.isdCode ?? 91,
          values?.brandName,
          values?.registeredBusinessName,
          values?.businessRegistrationDate,
          values?.businessRegistrationNumber,
          values?.cityOfBusiness,
          deviceId,
          "WEB",
          uuidLocation ?? "",
          "103.171.98.61",
          regUser?.token ?? ""
        );

        if (res?.data?.status?.statusCode === 0) {
          setLoading(false);
          navigate("/auth/documents");
          markFormCompleted("business");
          setRegUser((prevState) => ({
            ...prevState,
            ...res?.data?.message,
          }));
        } else {
          setFieldValue("status", res?.data?.status?.messageDescription);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    getAllData(regUser?.token ?? "").then((res) => {
      setRegUser((prevState) => ({
        ...prevState,
        ...res.data.message,
      }));
    });
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center">
      <p className="fs-2 fw-bold text-center">
        Please provide us with your business details
      </p>
      <div className="p-10">
        {formik?.values?.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {formik?.values?.status}
            </div>
          </div>
        )}
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-5">
            <label className="form-label">
              Registered Business Name
              {regUser?.reapplyParams?.businessName ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-add-money">
                      {regUser?.reapplyParams?.businessName}
                    </Tooltip>
                  }
                >
                  <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                    <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                  </span>
                </OverlayTrigger>
              ) : (
                ""
              )}
            </label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.registeredBusinessName &&
                formik.errors.registeredBusinessName
                  ? "is-invalid"
                  : ""
              } ${regUser?.reapplyParams?.businessName ? "is-invalid" : ""}`}
              placeholder="Business Name"
              id="registeredBusinessName"
              name="registeredBusinessName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.registeredBusinessName}
              maxLength={50}
            />
            {formik.errors.registeredBusinessName && (
              <div className="invalid-feedback">
                {formik.errors.registeredBusinessName}
              </div>
            )}
          </div>

          <div className="mb-5">
            <label className="form-label">Brand Name</label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.brandName && formik.errors.brandName
                  ? "is-invalid"
                  : ""
              } `}
              placeholder="Brand Name (If any)"
              id="brandName"
              name="brandName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.brandName}
              maxLength={50}
            />
            {formik.errors.brandName && (
              <div className="invalid-feedback">{formik.errors.brandName}</div>
            )}
          </div>

          <div className="mb-5 position-relative">
            <label className="form-label">
              Business Registration date{" "}
              {regUser?.reapplyParams?.yearOfIncorporation ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-add-money">
                      {regUser?.reapplyParams?.yearOfIncorporation}
                    </Tooltip>
                  }
                >
                  <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                    <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                  </span>
                </OverlayTrigger>
              ) : (
                ""
              )}
            </label>
            <input
              type="date"
              className={`form-control ${
                formik.touched.businessRegistrationDate &&
                formik.errors.businessRegistrationDate
                  ? "is-invalid"
                  : ""
              } ${
                regUser?.reapplyParams?.yearOfIncorporation ? "is-invalid" : ""
              }`}
              placeholder="Business Registration date"
              id="businessRegistrationDate"
              name="businessRegistrationDate"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.businessRegistrationDate}
              max={new Date().toISOString().split("T")[0]}
            />
            {formik.errors.businessRegistrationDate && (
              <div className="invalid-feedback">
                {formik.errors.businessRegistrationDate}
              </div>
            )}
          </div>

          <div className="mb-5 position-relative">
            <label className="form-label">
              Business registration number{" "}
              {regUser?.reapplyParams?.vatNumber ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-add-money">
                      {regUser?.reapplyParams?.vatNumber}
                    </Tooltip>
                  }
                >
                  <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                    <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                  </span>
                </OverlayTrigger>
              ) : (
                ""
              )}
            </label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.businessRegistrationNumber &&
                formik.errors.businessRegistrationNumber
                  ? "is-invalid"
                  : ""
              } ${regUser?.reapplyParams?.vatNumber ? "is-invalid" : ""}`}
              placeholder="Business registration number"
              id="businessRegistrationNumber"
              name="businessRegistrationNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.businessRegistrationNumber}
              maxLength={50}
            />
            {formik.errors.businessRegistrationNumber && (
              <div className="invalid-feedback">
                {formik.errors.businessRegistrationNumber}
              </div>
            )}
          </div>

          <div className="mb-5 position-relative">
            <label className="form-label">
              City of Business{" "}
              {regUser?.reapplyParams?.city ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-add-money">
                      {regUser?.reapplyParams?.city}
                    </Tooltip>
                  }
                >
                  <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                    <i className="bi bi-info-circle-fill fs-5 text-danger"></i>
                  </span>
                </OverlayTrigger>
              ) : (
                ""
              )}
            </label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.cityOfBusiness && formik.errors.cityOfBusiness
                  ? "is-invalid"
                  : ""
              } ${regUser?.reapplyParams?.city ? "is-invalid" : ""}`}
              placeholder="City of Business"
              id="cityOfBusiness"
              name="cityOfBusiness"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cityOfBusiness}
              maxLength={50}
            />
            {formik.errors.cityOfBusiness && (
              <div className="invalid-feedback">
                {formik.errors.cityOfBusiness}
              </div>
            )}
          </div>

          <div className="mt-10">
            <button
              type="submit"
              className="btn w-100"
              disabled={formik.isSubmitting || loading}
            >
              {loading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div>Next</div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessDetails;
