import { useState, useEffect, useRef } from "react";
import React from "react";
import { Formik, Field, Form, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { TransferMoneyData, resetFunction } from "./TransferMoney";
import PinInput from "react-pin-input";
import { E_ReceiptModal } from "./E-Receipt";
import Swal from "sweetalert2";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import OTPInput from "react-otp-input";
import { getCurrentDateTime } from "../bulkPayment/bulkPaymentStepper/steps/Step2";
import { DrawerComponent } from "../../../_metronic/assets/ts/components";
import { ThreeDots } from "react-loader-spinner";

interface Data {
  currency: string;
  payeeAccountNumber: string;
  payeeType: string;
  payeeFirstName: string;
  payeeId: number;
  isFavourite: boolean;
  payeeAlias: string;
  fundType: string;
}

interface Bank {
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
}

export interface TransferResponse {
  Amount: string;
  "Payee Name": string;
  "Payee Account": string;
  Date: string;
  Time: string;
  "Transaction Id": string;
  "Service Charge": string;
  Type: string;
  Status: string;
}
interface FormValues {
  inputValue: string;
  selectedAccount: string;
  selectedCategory: string;
  remarks: string;
  passcode: string;
  schedule: string;
  date: string;
  approver: string;
}

export interface User {
  userGuid: string;
  name: string;
  roleId: string;
  roleName: string;
  currency: string;
  amount: string;
}

export interface RuleMessage {
  userId: string;
  tenantId: string;
  referenceId: string;
  referenceType: string;
  businessGuid: string;
  channel: string;
  traceId: string;
  amount: number;
  serviceChargeAmt: number;
  appType: string;
  serviceCharge: number;
  transactionType: string;
  currency: string;
  serviceChargeTax: number;
  processingType: string;
  actorType: string;
  actorId: string;
  actorName: string;
  initiatedTxn: boolean;
}

export function TransferModal({ Data }: { Data: Data | null }) {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const inputRef = useRef<HTMLInputElement>(null);
  const formikRef = React.useRef<FormikProps<FormValues>>(null);
  const modalRef = useRef(null);

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;
  const [authority, setAuthority] = useState(0);

  const [category, setCategory] = useState([]);
  const [accounts, setAccounts] = useState<Bank[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("Select Account");
  const [selectedAccount, setSelectedAccount] = useState<Bank | null>(null);
  const [password, setPassword] = useState("");
  const [container, setContainer] = useState(false);
  const [ruleData, setRuleData] = useState<RuleMessage | null>(null);
  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const [showDateTimePickers, setShowDateTimePickers] =
    useState<boolean>(false);
  const [successData, setSuccessData] = useState<TransferResponse>({
    Amount: "",
    "Payee Name": "",
    "Payee Account": "",
    Date: "",
    Time: "",
    "Transaction Id": "",
    "Service Charge": "",
    Type: "",
    Status: "",
  });

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [pin, setPin] = useState("");
  const [approvers, setApprovers] = useState<User[]>([]);
  const [buttontext, setButton] = useState("Next");
  const [show, setShow] = useState(true);
  const [amount, setAmount] = useState("");

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);

    setShowDateTimePickers(selectedValue === "no");
  };

  useEffect(() => {
    const modalElement = modalRef?.current as HTMLElement | null;

    const handleShownModal = () => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRefs.current[activeInput]?.focus();
      }
    };

    if (modalElement) {
      modalElement.addEventListener("shown.bs.modal", handleShownModal);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("shown.bs.modal", handleShownModal);
      }
    };
  }, [modalRef, inputRef]);

  const handleReset = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
      TransferMoneyData.transactionPIN = "";
      setIsSelected("Select Account");
      setSelectedAccount(null);
      setPassword("");
      setIsActive(false);
      setContainer(false);
      setShowDateTimePickers(false);
      setPin("");
      setOtp(["", "", "", ""]);
      setActiveInput(0);
      setRuleData(null);
      setButton("Next");
      setLoading(false);
    }
  };

  const handleResetOnAmtChange = () => {
    if (formikRef.current) {
      TransferMoneyData.transactionPIN = "";
      setContainer(false);
      setPin("");
      setOtp(["", "", "", ""]);
      setRuleData(null);
      setButton("Next");
      setLoading(false);
      setPassword("");
      setActiveInput(0);
    }
  };

  const handleEReceipt = () => {
    const modalElement = document.getElementById("e_receipt");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };
  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = event.target.value;
  //   // Check if the value is a valid number using a regular expression
  //   if (/^\d*$/.test(value)) {
  //     setInputValue(value);
  //   }
  // };

  const ApproverList = async (amount: string) => {
    try {
      const url = `${API_URL}/team/getApproverList`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        currency: baseCurrency,
        amount: amount,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovers(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const TransferCategory = async () => {
    try {
      const url = `${API_URL}/transaction/getTrfCategory`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setCategory(data.message.trfCategory);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    TransferCategory();
  }, []);

  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setAccounts(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const getRules = async () => {
    try {
      const url = `${API_URL}/transaction/checkTxnDetails`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        amount: TransferMoneyData?.amount,
        currency: Data ? Data?.currency : "",
        transactionType:
          TransferMoneyData?.payeeSubType === "INTRA"
            ? "TRANSFER_INTRA_BANK"
            : "TRANSFER_INTER_BANK",
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      setRuleData(data?.message);

      if (data.message?.processingType === "PROCESS") {
        console.log(TransferMoneyData);
        if (TransferMoneyData.txnScheduleDate !== "") {
          setContainer(true);
          setButton("Schedule");
        } else {
          setContainer(true);
          setButton("Pay Now");
        }
      }
      if (data.message?.processingType === "SEND_FOR_APPROVAL") {
        setContainer(true);
        setButton("Initiate");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const doTransfer = async () => {
    setPaymentLoading(true);
    try {
      const url = `${API_URL}/transaction/doTransfer`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: TransferMoneyData?.accountNumber,
        payeeType: TransferMoneyData?.payeeType,
        payeeAlias: TransferMoneyData?.payeeAlias,
        payeeSubType: TransferMoneyData?.payeeSubType,
        amount: TransferMoneyData?.amount,
        remark: TransferMoneyData?.remark,
        ...(showDateTimePickers && {
          txnScheduleDate: TransferMoneyData.txnScheduleDate,
        }),
        transactionPIN: TransferMoneyData?.transactionPIN,
        trfCategory: TransferMoneyData?.trfCategory,
        ...(buttontext === "Initiate" && {
          // approverGuid: TransferMoneyData?.approverGuid,
          beneficiaryName: Data ? Data?.payeeFirstName : "",
          currency: Data ? Data?.currency : "",
          // approverName: TransferMoneyData?.approverName,
          initiatedTxn: true,
        }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      if (data?.status?.statusCode === 0) {
        getAccounts();
        var drawerEl = document.querySelector("#kt_modal_transfer");
        var drawer = DrawerComponent.getInstance(drawerEl!?.id);
        drawer?.hide();
        if (buttontext === "Initiate") {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            html: "Initiation successful <br> Pending for approval",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: `${
              data?.message?.txnScheduleDate
                ? "Transfer Scheduled"
                : "Transfer Successful"
            }`,
            text: `${
              data?.message?.txnScheduleDate
                ? "Transaction has been scheduled"
                : "Payment Transfer successfully sent"
            }`,
            showConfirmButton: data?.message?.txnScheduleDate ? false : true,
            showCancelButton: true, // Show the cancel button
            confirmButtonColor: "#007bff", // Set the confirm button color to btn
            confirmButtonText: "View E-Receipt",
            cancelButtonColor: "#9fa6b2",
            cancelButtonText: `${
              data?.message?.txnScheduleDate ? "Ok" : "Close"
            }`,
            allowEscapeKey: true,
            allowEnterKey: true, // Text for the cancel button
          }).then((result) => {
            if (result.isConfirmed) {
              handleEReceipt();
            }
          });
        }

        setSuccessData(data?.message?.receipt as TransferResponse);
      }
      if (data?.status?.statusCode === 1) {
        setPassword("");
        setPin("");
        setActiveInput(0);
        setOtp(["", "", "", ""]);
      }
      setPaymentLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleAmountChange = (value: string) => {
  //   if (+value > +userDet?.userDetails?.zarLimit) {
  //     // ApproverList(value);
  //     // setButton("Initiate");
  //   } else {
  //     // setButton("Pay Now");
  //   }
  // };

  useEffect(() => {
    // if (userDet?.userDetails?.authority?.includes("2")) {
    //   ApproverList(
    //     userDet?.userDetails?.zarLimit ? userDet?.userDetails?.zarLimit : "0"
    //   );
    //   setButton("Initiate");
    // }

    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  // useEffect(() => {
  //   const debounceTimeout = setTimeout(() => {
  //     if (amount) {
  //       console.log(amount);

  //       // ApproverList(amount ? amount : "0");
  //     }
  //   }, 300);

  //   return () => clearTimeout(debounceTimeout);
  // }, [amount]);

  useEffect(() => {
    DrawerComponent.reinitialization();
  }, []);

  var drawerEl = document.querySelector("#kt_modal_transfer");
  var drawer = DrawerComponent.getInstance(drawerEl!?.id);
  drawer?.on("kt.drawer.hide", function () {
    handleReset();
  });

  return (
    <>
      <style>
        {`
        
        .custom-input::-webkit-outer-spin-button,
        .custom-input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
 .App {
 font-family: sans-serif;
 }
 
 .dropdown {
 margin: 0 auto;
 z-index: 10000;
 width: 180px;
 position: relative;
 border-radius: 50px;
 }
 .dropdown,
 .dropdown * {
 // z-index: 10;
 }
 .dropdown .dropdown-btn {
 cursor: pointer;
 background: white;
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 10px;
 background: white;
 border: 1px solid #ddd;
 border-radius: 20px;
 color: #777;
 font-weight: 500;
 }
 .dropdown-content {
 position: absolute;
 background: white; 
 /* top: 110%; */
 left: 0;
 width: 100%;
 box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
 }
 .dropdown-content .item {
 padding: 10px;
 cursor: pointer;
 }
 .dropdown-content .item:hover {
 background: #fcfcfc;
 }
 
 // button {
 // // z-index: -1;
 // display: block;
 // width: 300px;
 // margin: 0 auto;
 // }
 `}
      </style>

      <div
        ref={modalRef}
        id="kt_modal_transfer"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_modal_toggle_transfer"
        data-kt-drawer-close="#kt_close"
      >
        <div
          className="card shadow-none rounded-0 w-100 p-5"
          style={{
            pointerEvents: authority === 3 ? "none" : "auto",
            opacity: authority === 3 ? 0.5 : 1,
          }}
        >
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 px-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_close"
              style={{ height: "24px", width: "24px" }}
              onClick={() => {
                handleReset();
                setIsSelected("Choose One");
                setSelectedAccount(null);
                setIsActive(false);
                setPassword("");
                setContainer(false);
                setShowDateTimePickers(false);
              }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-gray-600 p-0">
              Transfer Money
            </h5>
          </div>

          <div className="card-body overflow-auto">
            <div className="label">
              <div className="h-1-wrapper d-flex justify-content-center">
                <h1 className="text-wrapper ">{Data?.payeeFirstName} </h1>
              </div>
            </div>
            <div className="label mb-10">
              <div className="div-wrapper d-flex justify-content-center">
                <div className="text-wrapper">
                  Bank | {Data?.payeeAccountNumber}
                </div>
              </div>
            </div>
            <Formik
              innerRef={formikRef}
              initialValues={{
                inputValue: "",
                selectedAccount: "",
                selectedCategory: "",
                remarks: "",
                passcode: "",
                schedule: "yes",
                date: "",
                approver: "",
              }}
              validationSchema={Yup.object({
                inputValue: Yup.number()
                  .required("Amount is required")
                  .positive("Minimum value should be greater than 1"),
                selectedAccount: Yup.string().required(
                  "Please choose an account"
                ),
                selectedCategory: Yup.string().required(
                  "Please select a category"
                ),
                // remarks: Yup.string().required("Please enter remarks"),
                schedule: Yup.string().required(
                  "Please select a schedule option"
                ),
                date: Yup.string().when("schedule", {
                  is: "no", // when schedule is "no" (Later)
                  then: (schema) =>
                    schema
                      .required("Please select a date and time")
                      .test(
                        "future-date",
                        "Date must be in the future",
                        function (value) {
                          if (!value) return false;
                          const selectedDate = new Date(value);
                          const now = new Date();
                          return selectedDate > now;
                        }
                      ),
                  otherwise: (schema) => schema.notRequired(),
                }),
                approver: Yup.string().test(
                  "approver-required",
                  "Select an approver",
                  function (value) {
                    // Access the buttontext value from the context or scope
                    if (buttontext === "Initiate") {
                      return !!value; // Return true if value is present (valid), false otherwise (invalid)
                    }
                    return true; // If buttontext is not 'Initiate', always return true (valid)
                  }
                ),
              })}
              onSubmit={(values, { resetForm }) => {
                console.log(TransferMoneyData);

                TransferMoneyData.accountNumber =
                  selectedAccount?.accountNumber || "";
                TransferMoneyData.payeeType = Data?.payeeType || "";
                TransferMoneyData.payeeAlias = Data?.payeeAlias || "";
                TransferMoneyData.payeeSubType = Data?.fundType || "";
                TransferMoneyData.amount = Number(values.inputValue) || 0;
                TransferMoneyData.remark = values.remarks;
                TransferMoneyData.trfCategory = [
                  values.selectedCategory as string,
                ];
                TransferMoneyData.txnScheduleDate = values.date;
                TransferMoneyData.approverGuid = values.approver;
                const approverGuid = approvers.find(
                  (app) => app.userGuid === values.approver
                );
                TransferMoneyData.approverName = approverGuid
                  ? approverGuid.name
                  : "";
                if (values.schedule === "no") setShowDateTimePickers(true);
                else setShowDateTimePickers(false);
                if (values) {
                  getRules();
                  setLoading(true);
                }
              }}
            >
              {({
                values,
                setFieldValue,
                errors,
                touched,
                // handleChange,
                handleBlur,
                handleSubmit,
                resetForm,
              }) => (
                <Form autoComplete="off">
                  <div
                    className="card shadow-sm border-p d-flex flex-row justify-content-around p-5 mb-5 gap-5 "
                    style={{
                      borderRadius: "20px",
                      border: "1px solid #246BFD",
                    }}
                  >
                    <div className="d-flex flex-column ">
                      <div className="d-flex flex-row align-items-center ">
                        <h1 className="m-0 p-2 fs-2">{baseCurrency}</h1>
                        <Field
                          innerRef={inputRef}
                          onWheel={(e: any) => e.currentTarget.blur()}
                          className="fs-2 text-dark form-control custom-input"
                          type="text"
                          name="inputValue"
                          placeholder="0"
                          style={{
                            border: "none",
                            padding: "7px",
                            outline: "none",
                            fontWeight: "700",
                          }}
                          autoFocus={true}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const inputValue = e.target.value;
                            if (inputValue === "0" || inputValue === "") {
                              setFieldValue("inputValue", "");
                              return;
                            }

                            if (inputValue === "") {
                              // ApproverList("");
                              setAmount("");
                              setFieldValue("inputValue", "");
                            }

                            if (/^\d{1,12}(\.\d{0,2})?$/.test(inputValue)) {
                              // ApproverList(inputValue);
                              setAmount(inputValue);
                              setFieldValue("inputValue", inputValue);
                            }
                            handleResetOnAmtChange();
                          }}
                          // onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          //   console.log(e.target.value);
                          //   if (e.target.value === "") {
                          //     ApproverList("");
                          //   }
                          //   if (/^\d{1,12}(\.\d{0,2})?$/.test(e.target.value)) {

                          //     ApproverList(e.target.value);
                          //   }
                          // }}
                        />
                      </div>

                      <ErrorMessage
                        name="inputValue"
                        component="div"
                        className="text-danger text-nowrap"
                      />
                    </div>
                  </div>

                  <div className=" mb-5" style={{ position: "relative" }}>
                    <div className="">
                      <label className="form-label">Select Account</label>
                      <div
                        onClick={() => {
                          setIsActive(!isActive);
                        }}
                        className="form-select mb-2"
                        tabIndex={0}
                      >
                        {selected}
                        <span className="px-10" />
                        {selectedAccount?.currency}{" "}
                        {selectedAccount?.balance?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                      <div
                        className="dropdown-content"
                        style={{
                          display: isActive ? "block" : "none",
                          // marginLeft: "45px",
                          maxWidth: "365px",
                        }}
                      >
                        {accounts
                          ?.filter((item) => item.currency !== "USD")
                          .map((item, key) => (
                            <div
                              key={key}
                              onClick={() => {
                                setSelectedAccount(item);
                                setIsSelected(`${item.accountNumber}`);
                                setIsActive(!isActive);
                                setFieldValue(
                                  "selectedAccount",
                                  `${item.accountNumber},${item.accountType},${item.currency}`
                                );
                              }}
                              className="item"
                            >
                              <span
                                style={{ fontWeight: "bold" }}
                                className="pe-15"
                              >
                                {item.accountNumber}
                              </span>
                              <span
                                style={{
                                  marginLeft: "10px",
                                  color:
                                    parseFloat(values.inputValue) > item.balance
                                      ? "red"
                                      : "#246BFD",
                                }}
                                className="ps-15"
                              >
                                {item.currency}{" "}
                                {item.balance.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="text-danger">
                      {selectedAccount &&
                      Number(values.inputValue) > selectedAccount.balance
                        ? "Low balance, choose a different account"
                        : ""}
                    </div>

                    <ErrorMessage
                      name="selectedAccount"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="mb-5">
                    <label className="form-label">Select Category</label>
                    <Field
                      as="select"
                      id="selectedCategory"
                      name="selectedCategory"
                      className="form-select"
                      disabled={
                        selectedAccount &&
                        Number(values.inputValue) > selectedAccount.balance
                      }
                    >
                      <option value="" disabled>
                        Select Category
                      </option>
                      {category.map((item, key) => (
                        <option value={item} key={key}>
                          {item}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="selectedCategory"
                      component="div"
                      className="text-danger mt-2"
                    />
                  </div>
                  <div>
                    <label className="form-label">Remarks</label>
                    <Field
                      type="text"
                      id="remarks"
                      name="remarks"
                      className="form-control"
                      placeholder="Remarks"
                      disabled={
                        selectedAccount &&
                        Number(values.inputValue) > selectedAccount.balance
                      }
                      maxLength={100}
                      onKeyPress={(
                        e: React.KeyboardEvent<HTMLInputElement>
                      ) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent default behavior of form submission
                          handleSubmit(); // Submit the form
                        }
                      }}
                    />
                    <ErrorMessage
                      name="remarks"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <br />
                  <label className="form-label">
                    Would you like to Pay now or later ?
                  </label>
                  <div>
                    <div
                      style={{ padding: "5px 0px" }}
                      className="d-flex flex-row gap-10"
                    >
                      <div className="d-flex flex-row gap-10">
                        <div className="mb-3">
                          <div className="form-check form-check-custom form-check-solid">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="schedule"
                              value="yes"
                              id="flexRadioYes"
                              disabled={
                                selectedAccount &&
                                Number(values.inputValue) >
                                  selectedAccount.balance
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioYes"
                            >
                              Now
                            </label>
                          </div>
                        </div>

                        <div className="mb-3">
                          <div className="form-check form-check-custom form-check-solid">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="schedule"
                              value="no"
                              id="flexRadioNo"
                              disabled={
                                selectedAccount &&
                                Number(values.inputValue) >
                                  selectedAccount.balance
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioNo"
                            >
                              Later
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {values.schedule === "no" && (
                      <div className="d-flex flex-column ">
                        <label
                          className="form-label p-0 m-0"
                          htmlFor="selectedMinute"
                        >
                          Select a date
                        </label>

                        <Field
                          name="date"
                          type="datetime-local"
                          className="form-control"
                          min={getCurrentDateTime()}
                          onChange={(event: any) => {
                            const selectedDate = event.target.value;
                            const date = selectedDate?.split("T")[0];
                            const time = selectedDate?.split("T")[1];
                            const formattedDate = date + " " + time + ":00";
                            console.log(formattedDate);
                            setFieldValue("date", formattedDate);
                            handleResetOnAmtChange();
                          }}
                          max="9999-12-31T23:59"
                        />

                        <ErrorMessage
                          name="date"
                          component="div"
                          className="text-danger"
                        />
                        <br />
                      </div>
                    )}
                  </div>
                  {loading && (
                    <div className="d-flex flex-row justify-content-center">
                      <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#0000FF"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  )}
                  {ruleData && !loading && (
                    <div className="card shadow-sm p-5 mt-2">
                      <p>
                        {" "}
                        <span className="fw-bold">Service Charge :</span>&nbsp;
                        <span>
                          {ruleData?.serviceCharge}&nbsp;{Data?.currency}
                        </span>
                      </p>

                      <p>
                        <span className="fw-bold">Service Charge Tax : </span>
                        &nbsp;
                        <span>
                          {ruleData?.serviceChargeTax}&nbsp;{Data?.currency}
                        </span>
                      </p>
                      <p>
                        <span className="fw-bold">
                          Service Charge Amount :{" "}
                        </span>
                        &nbsp;
                        <span>
                          {ruleData?.serviceChargeAmt}&nbsp;{Data?.currency}
                        </span>
                      </p>
                      <p>
                        <span className="fw-bold">Total : </span>&nbsp;
                        <span>
                          {Number(amount) + Number(ruleData?.serviceChargeAmt)}
                          &nbsp;{Data?.currency}
                        </span>
                      </p>
                      {ruleData?.processingType === "SEND_FOR_APPROVAL" && (
                        <p>
                          {" "}
                          <span className="fw-bold">
                            This will be sent to {ruleData?.actorName}&nbsp;(
                            {ruleData?.actorType}) for approval
                          </span>
                        </p>
                      )}
                    </div>
                  )}

                  {/* {buttontext === "Initiate" ? (
                    <div className="mb-5">
                      <label className="form-label">Select Approver</label>
                      <Field
                        as="select"
                        id="approver"
                        name="approver"
                        className="form-select"
                      >
                        <option value="" disabled>
                          Select Approver
                        </option>
                        {approvers.map((approv, key) => (
                          <option value={approv.userGuid}>{approv.name}</option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="approver"
                        component="div"
                        className="text-danger mt-2"
                      />
                    </div>
                  ) : null} */}

                  <br />
                  {container && (
                    <div className="card shadow p-5">
                      <div className="text-center mt-5">
                        <h4>Enter Your PIN</h4>
                      </div>
                      <div className="text-center">
                        <p className="mb-3">Enter your PIN to confirm</p>
                      </div>
                      <div className="text-center">
                        <Field name="passcode">
                          {({
                            field,
                            form,
                          }: {
                            field: any;
                            form: FormikProps<any>;
                          }) => (
                            <div className="mb-5 d-flex justify-content-center">
                              <OTPInput
                                // {...field}
                                value={otp.join("")}
                                onChange={(value: string) => {
                                  value
                                    .split("")
                                    .forEach((char, index) =>
                                      handleChange(char, index)
                                    );
                                }}
                                numInputs={4}
                                renderSeparator={<span>&nbsp;&nbsp;</span>}
                                renderInput={(props, index) => (
                                  <input
                                    {...props}
                                    value={otp[index]}
                                    ref={(el) =>
                                      (inputRefs.current[index] = el)
                                    }
                                    onChange={(e) => {
                                      const pin = handleChange(
                                        e.target.value,
                                        index
                                      );
                                      form.setFieldValue("passcode", pin);
                                      TransferMoneyData.transactionPIN = pin;
                                      setPassword(pin);
                                    }}
                                    onKeyDown={(e) => {
                                      const pin = handleKeyDown(e, index);
                                      form.setFieldValue("passcode", pin);
                                      TransferMoneyData.transactionPIN = pin;
                                      setPassword(pin);
                                    }}
                                    type={show ? "password" : "text"}
                                    autoFocus={true}
                                    disabled={index !== activeInput}
                                    onFocus={() => setActiveInput(index)}
                                    className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                                    style={{ height: "50px", width: "50px" }}
                                  />
                                )}
                              />
                            </div>
                          )}
                        </Field>
                        <p
                          className="text-center text-decoration-underline cursor-pointer mb-0"
                          onClick={() => setShow(!show)}
                        >
                          {show ? "Show PIN" : "Hide PIN"}
                        </p>
                      </div>
                    </div>
                  )}

                  <br />

                  <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: container ? "5px" : "50px" }}
                  >
                    {password.toString().length === 4 ? (
                      <button
                        type="submit"
                        className="btn rounded"
                        style={{
                          width: "380px",
                        }}
                        onClick={() => {
                          doTransfer();
                        }}
                      >
                        {paymentLoading ? (
                          <div
                            className=" spinner-border text-primary"
                            role="status"
                          ></div>
                        ) : (
                          <div>{buttontext}</div>
                        )}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn rounded"
                        style={{
                          width: "380px",
                        }}
                        disabled={
                          selectedAccount
                            ? Number(values.inputValue) >
                              selectedAccount.balance
                            : false
                        }
                      >
                        {buttontext}
                      </button>
                    )}
                  </div>
                  {authority === 3 && (
                    <p className="text-danger fw-bold">
                      You don't have the authority to perform this action
                    </p>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <E_ReceiptModal data={successData} />
    </>
  );
}
