import { useState, useRef, useEffect, ChangeEvent } from "react";
import React from "react";
import { Modal } from "bootstrap";
import { Formik, Field, Form, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";
import { parsePhoneNumber } from "react-phone-number-input";
import { PhoneInput } from "react-international-phone";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { CountryDropdown } from "react-country-region-selector";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  PhoneNumber,
  PhoneNumberFormat,
  PhoneNumberUtil,
} from "google-libphonenumber";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Authorize from "../../modules/auth/components/Authorize";
import { useDispatch, useSelector } from "react-redux";
import { fetchVendors } from "./vendorSlice";
import { RootState } from "../../store";
import { AddVendorModal } from "./AddVendorModal";
import Loader from "../components/Loader";
const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export interface Vendor {
  tenantId: string;
  vendorGuid: string;
  name: string;
  companyName: string;
  vendorType: number;
  country: string;
  isdCode: number;
  mobileNumber: string;
  email: string;
  address: string;
  vatNumber: string;
  bankName: string;
  accountNumber: string;
  bic: string;
  notes: string;
  updatedDate: string;
  favourite: string;
}

interface Bank {
  key: string;
  value: string;
}

interface FormValues {
  customerName: string;
  companyName: string;
  country: string;
  phone: string;
  business: string;
  email: string;
  address: string;
  vatNumber: string;
  bank: string;
  bankAccNumber: string;
  bic: string;
  notes: string;
}

export default function Vendor() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const editmodalRef = useRef<HTMLDivElement | null>(null);
  const formikRef = React.useRef<FormikProps<FormValues>>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tooltipEdit = <Tooltip id="tooltip-add-money">Edit</Tooltip>;
  const tooltipDelete = <Tooltip id="tooltip-add-money">Delete</Tooltip>;

  const handleReset = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
      setEditData(null);
    }
  };

  const { vendors, loading } = useSelector(
    (state: RootState) => state?.vendors
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [filteredTransactions, setFilteredTransactions] = useState(vendors);
  const [editData, setEditData] = useState<Vendor | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [bank, setBank] = useState<Bank[] | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>("All");

  const editCustomerData = {
    name: "",
    vendorType: 0,
    companyName: "",
    country: "",
    isdCode: 0,
    mobileNumber: "",
    email: "",
    address: "",
    vatNumber: "",
    bankName: "",
    accountNumber: "",
    bic: "",
    notes: "",
    favourite: "false",
    vendorGuid: "",
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "All") {
      setFilteredTransactions(vendors);
    } else if (tab === "Favourite") {
      const filteredData = vendors.filter(
        (transaction) => transaction.favourite === "true"
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "International") {
      const filteredData = vendors.filter(
        (transaction) => transaction.vendorType === 2
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Domestic") {
      const filteredData = vendors.filter(
        (transaction) => transaction.vendorType === 1
      );
      setFilteredTransactions(filteredData);
    }
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (editmodalRef.current) {
      const modal = new Modal(editmodalRef.current);
      editmodalRef.current.addEventListener(
        "hidden.bs.modal",
        handleEditCloseModal
      );
      return () => {
        editmodalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleEditCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    // Filter data based on both selected tab and search query
    const filteredData = vendors?.filter((transaction) => {
      // Filter based on selected tab
      let isTabMatched = false;
      switch (selectedTab) {
        case "All":
          isTabMatched = true; // Always match for "All" tab
          break;
        case "Favourite":
          isTabMatched = transaction.favourite === "true";
          break;
        case "International":
          isTabMatched = transaction.vendorType === 2;
          break;
        case "Domestic":
          isTabMatched = transaction.vendorType === 1;
          break;
        default:
          isTabMatched = true; // Default to true for unknown tabs
      }

      // Return true if both conditions are matched
      return isTabMatched;
    });

    // Update filtered transactions
    setFilteredTransactions(filteredData);
  }, [vendors, searchValue, selectedTab]);

  const handleEditCloseModal = () => {
    setEditData(null);
    const modalElement = editmodalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };
  const handleOpenModal = () => {
    const modalElement = document.getElementById("item-modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
    handleReset();
  };

  const handleClick = (index: number) => {
    const clickedData: Vendor = currentData[index];

    if (clickedData.favourite === "false") {
      addFav(clickedData.vendorGuid);
    } else if (clickedData.favourite === "true") {
      undoFav(clickedData.vendorGuid);
    }
  };

  const handleEditClick = (index: number) => {
    handleReset();
    const clickedData: Vendor = currentData[index];
    editCustomerData.vendorGuid = clickedData.vendorGuid;
    setEditData(clickedData);

    const modalElement = document.getElementById("item-Editmodal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDeleteClick = (index: number) => {
    const clickedData: Vendor = currentData[index];

    const deleteItem = async () => {
      try {
        const url = `${API_URL}/vendor/delete`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          vendorGuid: clickedData.vendorGuid,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        if (data.status.statusCode === 0) {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "The selected vendor has been deleted",
            showCancelButton: false,
            confirmButtonColor: "#007bff",
            confirmButtonText: "Ok",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }
        dispatch(fetchVendors({} as any) as any);
      } catch (error) {
        console.log(error);
      }
    };

    Swal.fire({
      icon: "warning",
      title: "Delete vendor",
      text: "Are you sure you want to delete the selected vendor?",
      showCancelButton: true,
      confirmButtonColor: "#007bff",
      confirmButtonText: "Yes",
      cancelButtonColor: "#9fa6b2",
      cancelButtonText: "No",
      allowEscapeKey: true,
      allowEnterKey: true,
    }).then((res) => {
      if (res.isConfirmed) deleteItem();
    });
    // const modalElement = document.getElementById("delete_modal");
    // const modal = new Modal(modalElement as Element);
    // modal.show();
  };

  const addFav = async (vendorGuid: string) => {
    try {
      const url = `${API_URL}/vendor/markFavorite`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        vendorGuid: vendorGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status?.statusCode === 0)
        dispatch(fetchVendors({} as any) as any);
    } catch (error) {
      console.log(error);
    }
  };

  const undoFav = async (vendorGuid: string) => {
    try {
      const url = `${API_URL}/vendor/markUnfavorite`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        vendorGuid: vendorGuid,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status?.statusCode === 0)
        dispatch(fetchVendors({} as any) as any);
    } catch (error) {
      console.log(error);
    }
  };

  const getBank = async () => {
    try {
      const url = `${API_URL}/invSetting/getBanks`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setBank(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
  };

  const generateSearchCriteria = (
    propertyName: string,
    condition: boolean,
    propertyValue: any
  ) => {
    return condition
      ? [
          {
            propertyName: propertyName,
            propertyValue: propertyValue,
          },
        ]
      : [];
  };

  const fetchSegmentUsersPayload = {
    pageNumber: currentPage,
    pageSize: itemsPerPage,
    skipPagination: false,
    searchCriteriaList: [
      ...generateSearchCriteria("randomsearch", !!searchValue, searchValue),
    ],
  };

  useEffect(() => {
    getBank();
    dispatch(fetchVendors(fetchSegmentUsersPayload as any) as any);
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchVendors(fetchSegmentUsersPayload as any) as any);
  }, [currentPage, searchValue]);

  const updateVendor = async () => {
    try {
      const url = `${API_URL}/vendor/update`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...editCustomerData,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        handleReset();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "The selected vendor has been updated",
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        // handleUpdateConfirmation();
      }
      dispatch(fetchVendors({} as any) as any);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  return (
    <div>
      <>
        <div>
          <h4 className="mb-4">Vendor</h4>
        </div>
        <div className="card shadow py-2 px-5">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <ul className="nav nav-tabs nav-line-tabs ms-5 fs-6 d-flex flex-nowrap">
              <li className="nav-item" key={1}>
                <a
                  className="nav-link active text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                  onClick={() => {
                    handleTabClick("All");
                  }}
                >
                  All
                </a>
              </li>
              <li className="nav-item" key={2}>
                <a
                  className="nav-link text-nowrap text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                  onClick={() => {
                    handleTabClick("Favourite");
                  }}
                >
                  Favourite
                </a>
              </li>

              <li className="nav-item" key={3}>
                <a
                  className="nav-link text-nowrap text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_3"
                  onClick={() => {
                    handleTabClick("International");
                  }}
                >
                  International Vendor
                </a>
              </li>
              <li className="nav-item" key={4}>
                <a
                  className="nav-link text-nowrap text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_4"
                  onClick={() => {
                    handleTabClick("Domestic");
                  }}
                >
                  Domestic Vendor
                </a>
              </li>
            </ul>

            <div className="d-flex justify-content-center align-items-center p-3 gap-10">
              <div className="d-flex align-items-center gap-10">
                <div className="d-flex align-items-center  position-relative my-1">
                  <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    value={searchValue}
                    onChange={handleSearchChange}
                    type="text"
                    data-kt-docs-table-filter="search"
                    className="form-control  p-3 border-secondary form-control-solid w-250px ps-15"
                    placeholder="Search vendors"
                  />
                </div>
              </div>
              <Authorize hasAnyPermission={["MENU_VENDOR|ADD"]}>
                <button className="btn " onClick={handleOpenModal}>
                  Add Vendor
                </button>
              </Authorize>
            </div>
          </div>

          <div
            className="tab-content "
            id="myTabContent"
            style={{
              height: "440px",
              overflowY: "scroll",
            }}
          >
            <div
              className="tab-pane fade show active"
              id="kt_tab_pane_1"
              role="tabpanel"
            >
              <>
                <div className="card p-2">
                  <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                    <thead className="bg-gray-200">
                      <tr className="text-start text-dark-600 fw-bold fs-6  ">
                        <th className="min-w-100px p-5">Vendor Name</th>
                        <th className="min-w-100px p-5">Country</th>
                        <th className="min-w-100px p-5">Bank</th>

                        <th className="min-w-100px p-5 text-nowrap">
                          Account Number
                        </th>
                        <th className="min-w-100px p-5 text-nowrap">
                          Favourite
                        </th>
                        <th className="min-w-100px p-5 text-nowrap">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {currentData && currentData.length > 0 ? (
                        currentData?.map((item, index) => (
                          <tr
                            key={index}
                            onMouseOver={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "#f1f1f2";
                              // target.style.transform =
                              //   "translate3d(6px, -6px, 0)";
                              // target.style.boxShadow =
                              //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                            }}
                            onMouseOut={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "white";
                              // target.style.transform = "none";
                              // target.style.boxShadow = "none";
                            }}
                          >
                            <td
                              className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                              onClick={() =>
                                navigate("/invoice/vendorDetails", {
                                  state: { vendorGuid: item.vendorGuid },
                                })
                              }
                            >
                              {/* <a href="" style={{ textDecoration: "underline" }}> */}
                              {item.companyName}
                              {/* </a> */}
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              {item.vendorType === 1
                                ? "South Africa"
                                : item?.country}
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              {item.bankName}
                            </td>

                            <td className="p-5 text-gray-600">
                              {item.accountNumber}
                            </td>
                            <td className="p-5 text-gray-600">
                              <Authorize
                                hasAnyPermission={["MENU_VENDOR|EDIT"]}
                              >
                                <div
                                  className="d-flex "
                                  style={{ marginLeft: "20px" }}
                                >
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.favourite === "true"
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 cursor-pointer`}
                                  />
                                </div>
                              </Authorize>
                            </td>
                            <td className="p-5 text-gray-600 p-2">
                              <div className="d-flex gap-10">
                                <Authorize
                                  hasAnyPermission={["MENU_VENDOR|EDIT"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipEdit}
                                  >
                                    <i
                                      className="bi bi-pencil-fill cursor-pointer"
                                      style={{ color: "#0000ff" }}
                                      onClick={() => handleEditClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                                <Authorize
                                  hasAnyPermission={["MENU_VENDOR|DELETE"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash cursor-pointer"
                                      style={{ color: "#FF0000" }}
                                      onClick={() => handleDeleteClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
              <>
                <div className="card p-2">
                  <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                    <thead className="bg-gray-200">
                      <tr className="text-start text-dark-600 fw-bold fs-6  ">
                        <th className="min-w-100px p-5">Vendor Name</th>
                        <th className="min-w-100px p-5">Country</th>
                        <th className="min-w-100px p-5">Bank</th>

                        <th className="min-w-100px p-5 text-nowrap">
                          Account Number
                        </th>
                        <th className="min-w-100px p-5 text-nowrap">
                          Favourite
                        </th>
                        <th className="min-w-100px p-5 text-nowrap">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {currentData && currentData.length > 0 ? (
                        currentData?.map((item, index) => (
                          <tr
                            key={index}
                            onMouseOver={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "#f1f1f2";
                            }}
                            onMouseOut={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "white";
                            }}
                          >
                            <td
                              className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                              onClick={() =>
                                navigate("/invoice/vendorDetails", {
                                  state: { vendorGuid: item.vendorGuid },
                                })
                              }
                            >
                              {item.companyName}
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              {item.vendorType === 1
                                ? "South Africa"
                                : item?.country}
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              {item.bankName}
                            </td>

                            <td className="p-5 text-gray-600">
                              {item.accountNumber}
                            </td>
                            <td className="p-5 text-gray-600">
                              <Authorize
                                hasAnyPermission={["MENU_VENDOR|EDIT"]}
                              >
                                <div
                                  className="d-flex "
                                  style={{ marginLeft: "20px" }}
                                >
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.favourite === "true"
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 cursor-pointer`}
                                  />
                                </div>
                              </Authorize>
                            </td>
                            <td className="p-5 text-gray-600 p-2">
                              <div className="d-flex gap-10">
                                <Authorize
                                  hasAnyPermission={["MENU_VENDOR|EDIT"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipEdit}
                                  >
                                    <i
                                      className="bi bi-pencil-fill cursor-pointer"
                                      style={{ color: "#0000ff" }}
                                      onClick={() => handleEditClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                                <Authorize
                                  hasAnyPermission={["MENU_VENDOR|DELETE"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash cursor-pointer"
                                      style={{ color: "#FF0000" }}
                                      onClick={() => handleDeleteClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
              <>
                <div className="card p-2">
                  <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                    <thead className="bg-gray-200">
                      <tr className="text-start text-dark-600 fw-bold fs-6  ">
                        <th className="min-w-100px p-5">Vendor Name</th>
                        <th className="min-w-100px p-5">Country</th>
                        <th className="min-w-100px p-5">Bank</th>

                        <th className="min-w-100px p-5 text-nowrap">
                          Account Number
                        </th>
                        <th className="min-w-100px p-5 text-nowrap">
                          Favourite
                        </th>
                        <th className="min-w-100px p-5 text-nowrap">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {currentData && currentData.length > 0 ? (
                        currentData?.map((item, index) => (
                          <tr
                            key={index}
                            onMouseOver={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "#f1f1f2";
                            }}
                            onMouseOut={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "white";
                            }}
                          >
                            <td
                              className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                              onClick={() =>
                                navigate("/invoice/vendorDetails", {
                                  state: { vendorGuid: item.vendorGuid },
                                })
                              }
                            >
                              {item.companyName}
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              {item?.country}
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              {item.bankName}
                            </td>

                            <td className="p-5 text-gray-600">
                              {item.accountNumber}
                            </td>
                            <td className="p-5 text-gray-600">
                              <Authorize
                                hasAnyPermission={["MENU_VENDOR|EDIT"]}
                              >
                                <div
                                  className="d-flex "
                                  style={{ marginLeft: "20px" }}
                                >
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.favourite === "true"
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 cursor-pointer`}
                                  />
                                </div>
                              </Authorize>
                            </td>
                            <td className="p-5 text-gray-600 p-2">
                              <div className="d-flex gap-10">
                                <Authorize
                                  hasAnyPermission={["MENU_VENDOR|EDIT"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipEdit}
                                  >
                                    <i
                                      className="bi bi-pencil-fill cursor-pointer"
                                      style={{ color: "#0000ff" }}
                                      onClick={() => handleEditClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                                <Authorize
                                  hasAnyPermission={["MENU_VENDOR|DELETE"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash cursor-pointer"
                                      style={{ color: "#FF0000" }}
                                      onClick={() => handleDeleteClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
              <>
                <div className="card p-2">
                  <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                    <thead className="bg-gray-200">
                      <tr className="text-start text-dark-600 fw-bold fs-6  ">
                        <th className="min-w-100px p-5">Vendor Name</th>
                        <th className="min-w-100px p-5">Country</th>
                        <th className="min-w-100px p-5">Bank</th>

                        <th className="min-w-100px p-5 text-nowrap">
                          Account Number
                        </th>
                        <th className="min-w-100px p-5 text-nowrap">
                          Favourite
                        </th>
                        <th className="min-w-100px p-5 text-nowrap">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {currentData && currentData.length > 0 ? (
                        currentData?.map((item, index) => (
                          <tr
                            key={index}
                            onMouseOver={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "#f1f1f2";
                            }}
                            onMouseOut={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "white";
                            }}
                          >
                            <td
                              className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                              onClick={() =>
                                navigate("/invoice/vendorDetails", {
                                  state: { vendorGuid: item.vendorGuid },
                                })
                              }
                            >
                              {item.companyName}
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              South Africa
                            </td>
                            <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                              {item.bankName}
                            </td>

                            <td className="p-5 text-gray-600">
                              {item.accountNumber}
                            </td>
                            <td className="p-5 text-gray-600">
                              <Authorize
                                hasAnyPermission={["MENU_VENDOR|EDIT"]}
                              >
                                <div
                                  className="d-flex "
                                  style={{ marginLeft: "20px" }}
                                >
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.favourite === "true"
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 cursor-pointer`}
                                  />
                                </div>
                              </Authorize>
                            </td>
                            <td className="p-5 text-gray-600 p-2">
                              <div className="d-flex gap-10">
                                <Authorize
                                  hasAnyPermission={["MENU_VENDOR|EDIT"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipEdit}
                                  >
                                    <i
                                      className="bi bi-pencil-fill cursor-pointer"
                                      style={{ color: "#0000ff" }}
                                      onClick={() => handleEditClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                                <Authorize
                                  hasAnyPermission={["MENU_VENDOR|DELETE"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash cursor-pointer"
                                      style={{ color: "#FF0000" }}
                                      onClick={() => handleDeleteClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="p-5">
              <ul className="pagination">
                <li
                  className={`page-item double-arrow ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  key={0}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(1)}
                  >
                    <i className="previous"></i>
                    <i className="previous"></i>
                  </a>
                </li>
                <li
                  className={`page-item previous ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  key={1}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="previous"></i>
                  </a>
                </li>
                {renderPageNumbers()}
                <li
                  className={`page-item next ${
                    currentPage ===
                    Math.ceil(filteredTransactions?.length / itemsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                  key={2}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="next"></i>
                  </a>
                </li>
                <li
                  className={`page-item double-arrow ${
                    currentPage ===
                    Math.ceil(filteredTransactions?.length / itemsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                  key={3}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() =>
                      handlePageChange(
                        Math.ceil(filteredTransactions?.length / itemsPerPage)
                      )
                    }
                  >
                    <i className="next"></i>
                    <i className="next"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="p-5 d-flex align-items-center">
              <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                Total: {filteredTransactions?.length}&nbsp;&nbsp;
              </label>
              <select
                id="itemsPerPage"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </div>

        <AddVendorModal />

        <div
          className="modal fade"
          tabIndex={-1}
          id="item-Editmodal"
          ref={editmodalRef}
        >
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
              <div className="modal-header">
                <h3
                  className="modal-title text-nowrap m-0"
                  // style={{ color: "#246bfd" }}
                >
                  Edit Vendor
                </h3>

                {/* <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleReset}
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div> */}
              </div>

              <div className="modal-body" style={{ padding: "30px" }}>
                <Formik
                  innerRef={formikRef}
                  enableReinitialize={true}
                  initialValues={{
                    customerName: editData ? editData.name : "",
                    companyName: editData ? editData.companyName : "",
                    business: editData ? editData?.vendorType?.toString() : "",
                    country: editData ? editData.country : "",
                    phone: editData
                      ? editData.isdCode + " " + editData.mobileNumber
                      : "",
                    email: editData ? editData.email : "",
                    address: editData ? editData.address : "",
                    vatNumber: editData ? editData.vatNumber : "",
                    bank: editData ? editData.bankName : "",
                    bankAccNumber: editData ? editData.accountNumber : "",
                    bic: editData ? editData.bic : "",
                    notes: editData ? editData.notes : "",
                  }}
                  validationSchema={Yup.object({
                    customerName: Yup.string()
                      .min(2, "Minimum 2 characters required")
                      .max(50, "Maximum 50 characters allowed")
                      .matches(
                        /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]+$/,
                        "Business holder name should be alphanumeric with letters"
                      )
                      // .matches(
                      //   /^[a-zA-Z0-9 ]+$/,
                      //   "Only letters and numbers are allowed"
                      // )
                      .required("Business Holder Name required"),
                    companyName: Yup.string()
                      .min(2, "Minimum 2 characters required")
                      .max(50, "Maximum 50 characters allowed")
                      .matches(
                        /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]+$/,
                        "Vendor name should be alphanumeric with letters"
                      )
                      // .matches(
                      //   /^[a-zA-Z0-9 ]+$/,
                      //   "Only letters and numbers are allowed"
                      // )
                      .required("Vendor Name required"),
                    business: Yup.string().required("Vendor Type required"),
                    country: Yup.string().when("business", {
                      is: (business: string) => business !== "1",
                      then: (schema) => schema.required("Country is required"),
                    }),
                    phone: Yup.string()
                      .min(12, "Phone number is required")
                      .test(
                        "is-valid-phone",
                        "Phone number is invalid",
                        function (value) {
                          console.log("phonenum", value);

                          if (value) {
                            const isVlaid = isPhoneValid(value);
                            console.log(isVlaid);

                            return isVlaid;
                          }
                          return true;
                        }
                      )
                      .required("Phone number is required"),
                    email: Yup.string()
                      .matches(
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        "Invalid email address"
                      )
                      .required("Email Required"),
                    address: Yup.string()
                      .min(2, "Minimum 2 characters required")
                      .max(100, "Maximum 100 characters allowed")
                      .required("Address Required"),
                    vatNumber: Yup.string()
                      .min(6, "Minimum 6 characters required")
                      .max(20, "Maximum 20 characters allowed")
                      .matches(
                        /^[a-zA-Z0-9 ]+$/,
                        "Only letters and numbers are allowed"
                      )
                      .required("VAT Number Required"),
                    bank: Yup.string().required("Bank is required"),
                    bankAccNumber: Yup.string()
                      .min(10, "Minimum 10 characters needed")
                      .max(34, "Maximum 34 characters allowed")
                      .matches(
                        /^[a-zA-Z0-9]+$/,
                        "Only letters and numbers are allowed"
                      )
                      .required("Bank Account Number required"),
                    bic: Yup.string()
                      .min(8, "Minimum 8 characters required")
                      .max(11, "Maximum 11 characters allowed")
                      .matches(
                        /^[a-zA-Z0-9 ]+$/,
                        "Only letters and numbers are allowed"
                      )
                      .required("BIC Required"),
                    notes: Yup.string().max(
                      100,
                      "Maximum 100 characters allowed"
                    ),
                    //   .required("Notes Required"),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    const phoneNumber = values.phone;
                    const phoneNumberParsed = parsePhoneNumber(phoneNumber);

                    editCustomerData.name = values?.customerName;
                    editCustomerData.companyName = values?.companyName;
                    editCustomerData.country =
                      values?.business === "1"
                        ? "South Africa"
                        : values?.country;
                    editCustomerData.vendorType = +values?.business;
                    editCustomerData.mobileNumber =
                      phoneNumberParsed?.nationalNumber.toString() || "";
                    editCustomerData.isdCode = +(
                      phoneNumberParsed?.countryCallingCode ?? 0
                    );
                    editCustomerData.email = values?.email;
                    editCustomerData.address = values?.address;
                    editCustomerData.vatNumber = values?.vatNumber;
                    editCustomerData.bankName = values?.bank;
                    editCustomerData.accountNumber = values?.bankAccNumber;
                    editCustomerData.bic = values?.bic;
                    editCustomerData.notes = values?.notes;
                    editCustomerData.vendorGuid = editData
                      ? editData.vendorGuid
                      : "";
                    updateVendor();
                    handleEditCloseModal();
                    resetForm();
                  }}
                >
                  {({ values }) => (
                    <Form>
                      <div
                        className="mb-10"
                        style={{ maxHeight: "400px", overflow: "auto" }}
                      >
                        <div className="mb-5">
                          <label className="form-label">
                            Business Holder Name
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Business Holder Name"
                            name="customerName"
                            maxLength={50}
                          />
                          <ErrorMessage
                            name="customerName"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">Vendor Name</label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Vendor Name"
                            name="companyName"
                            maxLength={50}
                          />
                          <ErrorMessage
                            name="companyName"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">Type of Vendor</label>
                          <Field
                            as="select"
                            className="form-select"
                            aria-label="Select Business"
                            name="business"
                            disabled
                          >
                            <option value="">Select vendor type</option>
                            <option value="1">Domestic Vendor</option>
                            <option value="2">International Vendor</option>
                          </Field>
                          <ErrorMessage
                            name="business"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {values?.business === "2" && (
                          <div className="mb-5">
                            <label className="form-label">Country</label>
                            <Field name="country">
                              {({
                                field,
                                form,
                              }: {
                                field: any;
                                form: FormikProps<any>;
                              }) => (
                                <div className="mb-5">
                                  <CountryDropdown
                                    {...field}
                                    classes="form-select"
                                    onChange={(country) =>
                                      form.setFieldValue("country", country)
                                    }
                                  />
                                </div>
                              )}
                            </Field>
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        )}

                        <div className="mb-5">
                          <label className="form-label">Phone</label>
                          <Field name="phone">
                            {({
                              field,
                              form,
                            }: {
                              field: any;
                              form: FormikProps<any>;
                            }) => (
                              <div className="mb-5">
                                <PhoneInput
                                  {...field}
                                  inputClassName="form-control"
                                  defaultCountry="in"
                                  onChange={(phone) => {
                                    form.setFieldValue("phone", phone);
                                  }}
                                  inputStyle={{
                                    padding: "21px",
                                    width: "100%",
                                  }}
                                  countrySelectorStyleProps={{
                                    buttonStyle: {
                                      padding: "21px 5px",
                                    },
                                  }}
                                  disableDialCodePrefill={false}
                                  forceDialCode={true}
                                />
                              </div>
                            )}
                          </Field>

                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">Email</label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">Address</label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            name="address"
                            maxLength={100}
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">VAT Number</label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="VAT Number"
                            name="vatNumber"
                            maxLength={20}
                          />
                          <ErrorMessage
                            name="vatNumber"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">Bank</label>
                          <Field
                            as="select"
                            className="form-select"
                            aria-label="Select Bank"
                            name="bank"
                          >
                            <option value="">Select a bank</option>
                            {bank &&
                              bank.map((item: Bank, index: number) => (
                                <option key={index} value={item.key}>
                                  {item.value}
                                </option>
                              ))}
                          </Field>

                          <ErrorMessage
                            name="bank"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">
                            IBAN / Account Number
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="bankAccNumber"
                            name="bankAccNumber"
                            maxLength={34}
                          />
                          <ErrorMessage
                            name="bankAccNumber"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">SWIFT / BIC</label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="BIC"
                            name="bic"
                            maxLength={11}
                          />
                          <ErrorMessage
                            name="bic"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-5">
                          <label className="form-label">Notes</label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Notes"
                            name="notes"
                            maxLength={100}
                          />
                          <ErrorMessage
                            name="notes"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="">
                        <button type="submit" className="btn w-100">
                          Submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
