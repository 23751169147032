import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Swal from "sweetalert2";

const API_URL = process.env.REACT_APP_WEB_API_URL;

export interface Rule {
    tenantId: string;
    instanceGuid: string;
    categoryName: string;
    categoryCode: string;
    ruleName: string;
    ruleCode: string;
    status: string;
    businessGuid: string;
}

interface createRulePayload {
   
    categoryName: string;
    categoryCode: string;
    ruleName: string;
    ruleCode: string;
    
}


interface Category {
    code: string;
    name: string;
    names: {
        code: string;
        name: string;
        categoryCode: string;
    }[];
}

interface RuleDashboardState {
    rules: Rule[];
    count: number;
    categoryList: Category[];
    loading: boolean;
    error: string | null;
}

interface FindRuleBody {
    pageNumber: number;
    pageSize: number;
    skipPagination: boolean;
    searchCriteriaList?: {
        propertyName: string;
        propertyValue: string;
    }[];
}

interface InstanceActionBody {
    instanceGuid: string;
}

const initialState: RuleDashboardState = {
    rules: [],
    count: 0,
    categoryList: [],
    loading: false,
    error: null,
};

const getToken = (): string | null => {
    const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
    const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
    return tokenData ? tokenData.token : null;
};

const apiCall = async (url: string, body?: object): Promise<any> => {
    const token = getToken();
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };

    const options = {
        method: "POST",
        headers,
        body: JSON.stringify(body || {}),
    };

    const response = await enhancedFetch(url, options);
    const data = await response.json();
    
    if (data.status.statusCode !== 0) {
        const errorMessage = data.status.messageDescription || data.message || "An error occurred.";
        
        // Display error using Swal
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorMessage,
        });
    
        throw new Error(errorMessage); // Throws the error to be caught by higher-level error handling
    }
    
    return data;
};

export const findRule = createAsyncThunk<
    { results: Rule[]; count: number },
    FindRuleBody,
    { rejectValue: string }
>("ruleDashboard/findRule", async (body, { rejectWithValue }) => {
    try {
        const url = `${API_URL}/instance/find`;
        const data = await apiCall(url, body);
        return { results: data.results, count: data.count };
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const findMasterData = createAsyncThunk<
    Category[],
    void,
    { rejectValue: string }
>("ruleDashboard/findMasterData", async (_, { rejectWithValue }) => {
    try {
        const url = `${API_URL}/instance/findMasterData`;
        const data = await apiCall(url, {});
        return data.message.categoryList;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const activateInstance = createAsyncThunk<
    void,
    InstanceActionBody,
    { rejectValue: string }
>("ruleDashboard/activateInstance", async (body, { rejectWithValue }) => {
    try {
        const url = `${API_URL}/instance/activate`;
        await apiCall(url, body);
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const inactivateInstance = createAsyncThunk<
    void,
    InstanceActionBody,
    { rejectValue: string }
>("ruleDashboard/inactivateInstance", async (body, { rejectWithValue }) => {
    try {
        const url = `${API_URL}/instance/inactivate`;
        await apiCall(url, body);
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const deleteInstance = createAsyncThunk<
    void,
    InstanceActionBody,
    { rejectValue: string }
>("ruleDashboard/deleteInstance", async (body, { rejectWithValue }) => {
    try {
        const url = `${API_URL}/instance/delete`;
        await apiCall(url, body);
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const createRule = createAsyncThunk<
    Rule,
    createRulePayload,
    { rejectValue: string }
>("ruleDashboard/createRule", async (body, { rejectWithValue }) => {
    try {
        const url = `${API_URL}/instance/create`;
        const data = await apiCall(url, body);
        return data;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

const ruleDashboardSlice = createSlice({
    name: "ruleDashboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(findRule.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(findRule.fulfilled, (state, action: PayloadAction<{ results: Rule[]; count: number }>) => {
                state.loading = false;
                state.rules = action.payload.results;
                state.count = action.payload.count;
            })
            .addCase(findRule.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Failed to fetch rules.";
            })
            .addCase(findMasterData.pending, (state) => {
                state.error = null;
            })
            .addCase(findMasterData.fulfilled, (state, action: PayloadAction<Category[]>) => {
                state.categoryList = action.payload;
            })
            .addCase(findMasterData.rejected, (state, action) => {
                state.error = action.payload || "Failed to fetch master data.";
            })
            .addCase(activateInstance.rejected, (state, action) => {
                state.error = action.payload || "Failed to activate instance.";
            })
            .addCase(inactivateInstance.rejected, (state, action) => {
                state.error = action.payload || "Failed to inactivate instance.";
            })
            .addCase(deleteInstance.rejected, (state, action) => {
                state.error = action.payload || "Failed to delete instance.";
            })
            .addCase(createRule.pending, (state) => {
                state.error = null;
            })
            .addCase(createRule.fulfilled, (state, action: PayloadAction<Rule>) => {
                state.error = null;
            })
            .addCase(createRule.rejected, (state, action) => {
                state.error = action.payload || "Failed to create rule.";
            });
    },
});


export default ruleDashboardSlice.reducer;
