import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { Bank } from "../Payee/PayrollStepper/PayrollStepperModal2";

// Define your API URL
const API_URL = process.env.REACT_APP_WEB_API_URL;

export interface Vendor {
  tenantId: string;
  vendorGuid: string;
  name: string;
  companyName: string;
  vendorType: number;
  country: string;
  isdCode: number;
  mobileNumber: string;
  email: string;
  address: string;
  vatNumber: string;
  bankName: string;
  accountNumber: string;
  bic: string;
  notes: string;
  updatedDate: string;
  favourite: string;
}

// Define initial state
interface VendorsState {
  vendors: Vendor[];
  banks: Bank[];
  count: number;
  loading: boolean;
  error: string | null;
}

const initialState: VendorsState = {
  vendors: [],
  banks: [],
  count: 0,
  loading: false,
  error: null,
};

export interface SearchCriteria {
  propertyName: string;
  propertyValue: string | number;
}

interface FetchVendorPayload {
  tenantId: string;
  searchCriteriaList: SearchCriteria[];
  pageNumber: number;
  pageSize: number;
  skipPagination: boolean;
  // Define your payload type here if needed
}

// Define async thunk for fetching notifications
export const fetchVendors = createAsyncThunk<
  Vendor[],
  FetchVendorPayload,
  { rejectValue: string }
>(
  "/vendor/findAllVendor",
  async (payload: FetchVendorPayload, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;

      const response = await enhancedFetch(`${API_URL}/vendor/findAllVendor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          searchCriteriaList: payload.searchCriteriaList,
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
          skipPagination: payload.skipPagination,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return responseData.results;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const fetchBanks = createAsyncThunk<
  Bank[],
  FetchVendorPayload,
  { rejectValue: string }
>("/invSetting/getBanks", async (FetchVendorPayload, { rejectWithValue }) => {
  try {
    const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
    const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
    const token = tokenData ? tokenData.token : null;
    console.log(FetchVendorPayload);
    const response = await enhancedFetch(`${API_URL}/invSetting/getBanks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      const errorData = await response.json();
      return rejectWithValue(errorData.message || "An error occurred.");
    }

    const responseData = await response.json();
    return responseData.results;
  } catch (error: any) {
    return rejectWithValue(error.message || "An error occurred.");
  }
});

// Create slice
export const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVendors.fulfilled, (state, action) => {
        state.loading = false;
        state.vendors = action.payload;
        state.count = action.payload.length;
      })
      .addCase(fetchVendors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchBanks.pending, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchBanks.fulfilled, (state, action) => {
        state.loading = false;
        state.banks = action.payload;
        state.count = action.payload.length;
      })
      .addCase(fetchBanks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

// Selectors
export const selectVendors = (state: { notifications: VendorsState }) =>
  state.notifications;

// Reducer
export default vendorSlice.reducer;
