import React from 'react'
import { MutatingDots } from 'react-loader-spinner'

const Loader = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '75vh' }}>
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#0000ff"
            secondaryColor="#0000ff"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
  )
}

export default Loader