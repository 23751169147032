import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../core/Auth";
import { getUSD, getZAR } from "./api";
import Swal from "sweetalert2";

interface RootObject {
  userId: string;
  tenantId: string;
  referenceId: string;
  referenceType: string;
  businessGuid: string;
  traceId: string;
  accounts: Account[];
  zarLimit: number;
  usdLimit: number;
}

interface Account {
  accountNumber: string;
  currency: string;
  cif: string;
  swiftCode: string;
  accountType: string;
  balance: number;
  subAccountExist: boolean;
}

const ZARAccount = () => {
  const { regUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<RootObject | null>(null);

  useEffect(() => {
    getZAR(regUser?.token ?? "").then((res) => {
      if (res?.data?.status?.statusCode === 0) {
        setData(res?.data?.message);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  }, [regUser?.token]);

  const accountKeyMap: { [key in keyof Account]?: string } = {
    accountNumber: "Account Number",
    currency: "Currency",
    cif: "Customer ID",
    swiftCode: "SWIFT/BIC",
  };

  const handleSubmit = async () => {
    try {
      const result = await Swal.fire({
        title: "Would you like to go ahead and also open a USD account?",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        confirmButtonText: "Yes",
        cancelButtonText: "Later",
        allowEscapeKey: true,
        allowEnterKey: true,
      });

      if (result.isConfirmed) {
        navigate("/auth/secondary-acc");
      } else if (result.isDismissed) {
        await getUSD(regUser?.token ?? "", true);
        navigate("/auth/pin");
      }
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center">
      <p className="fs-2 fw-bold text-center">
        Your primary account details are as follows
      </p>
      <div className="mt-10">
        <div className="p-10">
          <p className="fs-4 fw-bold">Account details</p>
          {loading ? (
            <div className="spinner-border text-primary " role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            data && (
              <div>
                {data.accounts.map((account, index) => (
                  <div key={index}>
                    {Object.entries(account)
                      .filter(([key]) => key in accountKeyMap)
                      .map(([key, value]) => (
                        <div key={key} className="mb-2">
                          <strong>
                            {accountKeyMap[key as keyof Account]}:
                          </strong>{" "}
                          {String(value)}
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center mt-10 p-10">
        <button className="btn w-100" onClick={handleSubmit}>
          Proceed
        </button>
      </div>
    </div>
  );
};

export default ZARAccount;
