import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { PhoneInput } from "react-international-phone";
import { PasswordResponse } from "../../../interface/Interface";
import { PhoneNumberUtil } from "google-libphonenumber";
import { enhancedFetch } from "../core/_requests";
import Swal from "sweetalert2";

const phoneUtil = PhoneNumberUtil.getInstance();

const getValidationSchema = (type: "email" | "phone") => {
  if (type === "email") {
    return Yup.object({
      email: Yup.string()
        .email("Wrong email format")
        .required("Email is required")
        .matches(
          /^[a-z0-9.@]+$/,
          "Only letters (a-z), digits (0-9), and periods (.) are allowed"
        ),
      phone: Yup.string().notRequired(),
    });
  }
};

export function ForgotPassword() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [selection, setSelection] = useState<"email" | "phone">("phone");
  const [isPhoneValid, setIsPhoneValid] = useState<null | boolean>(null);
  const [isPhoneTouched, setIsPhoneTouched] = useState(false);
  const [response, setResponse] = useState({
    userId: "",
    tenantId: "",
    referenceId: "",
    referenceType: 0,
    appId: "",
    mobile: "",
    email: "",
    isdCode: 0,
    password: "",
    userStatus: 0,
    customerCategory: "",
    businessGuid: "",
  });

  const getDetails = async (loginPrincipal: string, isdCode?: number) => {
    try {
      const url = `${API_URL}/resetPassword/getDetails`;
      const headers = {
        "Content-Type": "application/json",
        tenant_id: "eazy_bank",
      };

      const body = JSON.stringify({ loginPrincipal, isdCode });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handlePhoneChange = (value: string) => {
    if (!value) {
      setIsPhoneValid(null);
      formik.setFieldValue("phone", value);
      return;
    }

    try {
      const isValid = phoneUtil.isValidNumber(
        phoneUtil.parseAndKeepRawInput(value)
      );
      setIsPhoneValid(isValid);
    } catch (error) {
      setIsPhoneValid(false);
    }
    formik.setFieldValue("phone", value);
  };

  const handleSelectionChange = (selected: "email" | "phone") => {
    setSelection(selected);
    setIsPhoneValid(null);
    setIsPhoneTouched(false);

    if (selected === "email") {
      formik.setFieldValue("phone", ""); // Clear phone field
    } else if (selected === "phone") {
      formik.setFieldValue("email", ""); // Clear email field
    }
  };

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { email: "", phone: "" },
    validationSchema: getValidationSchema(selection),
    onSubmit: async (values, { setStatus, setSubmitting, setTouched }) => {
      setLoading(true);
      setHasErrors(undefined);

      // If there are any errors, mark all fields as touched
      if (formik.errors && Object.keys(formik.errors).length > 0) {
        setTouched({
          email: true,
          phone: true,
        });
      }

      if (values.email) {
        const data = await getDetails(values.email);
        setResponse(data);

        if (
          data &&
          (data?.message?.userStatus === 10 ||
            data?.message?.userStatus === 12 ||
            data?.message?.userStatus === 4)
        ) {
          navigate("authselect", { state: { responseData: data.message } });
        }
        //  else {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Error",
        //     text: data?.status?.messageDescription,
        //     allowEscapeKey: true,
        //     allowEnterKey: true,
        //   });
        // }
      }
      if (values.phone.length >= 15) {
        // Extract the country code and actual number
        const countryCode = values.phone.split(" ")[0].replace("+", ""); // This will give you '+91'
        const actualNumber = values.phone.split(" ")[1]; // This will give you '66666-66666'

        // Remove the '-' from the actual number
        const formattedNumber = actualNumber.replace(/-/g, "");
        const data = await getDetails(formattedNumber, +countryCode);
        setResponse(data);

        if (
          data &&
          (data?.message?.userStatus === 10 ||
            data?.message?.userStatus === 12 ||
            data?.message?.userStatus === 4)
        ) {
          navigate("authselect", { state: { responseData: data.message } });
        } 
        // else {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Error",
        //     text: data?.status?.messageDescription,
        //     allowEscapeKey: true,
        //     allowEnterKey: true,
        //   });
        // }
      }
    },
  });

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">Forgot Password ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          Enter your Phone to reset your password.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <div className="mb-4">
          <label className="me-5">
            <input
              type="radio"
              className="form-check-input me-1"
              name="contactType"
              value="phone"
              checked={selection === "phone"}
              onChange={() => {
                handleSelectionChange("phone");
                formik.setErrors({});
                formik.setTouched({});
              }}
            />
            Phone
          </label>
          {/* <label>
            <input
              type="radio"
              className="form-check-input me-1"
              name="contactType"
              value="email"
              checked={selection === "email"}
              onChange={() => {
                handleSelectionChange("email");
                formik.setErrors({});
                formik.setTouched({});
              }}
            />
            Email
          </label> */}
        </div>
        {selection === "email" ? (
          <div className="fv-row mb-8">
            <input
              type="email"
              placeholder="Enter Registered Email"
              autoComplete="off"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control bg-transparent",
                { "is-invalid": formik.touched.email && formik.errors.email },
                { "is-valid": formik.touched.email && !formik.errors.email }
              )}
            />
          </div>
        ) : (
          <div className="fv-row mb-8 mt-5">
            <PhoneInput
              defaultCountry="in"
              onChange={handlePhoneChange}
              inputProps={{
                onBlur: () => setIsPhoneTouched(true),
                autoFocus: true,
              }}
              countrySelectorStyleProps={{
                buttonStyle: {
                  padding: "21px 5px",
                },
              }}
              value={formik.values.phone}
              inputStyle={{ width: "350px", height: "43.5px" }}
              disableDialCodePrefill={false}
              forceDialCode={true}
              className={isPhoneValid === false ? "error" : ""}
            />
          </div>
        )}

        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}

        {isPhoneValid === false && (isPhoneTouched || formik.isSubmitting) && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">Invalid phone number</span>
            </div>
          </div>
        )}
      </div>

      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-flex flex-wrap justify-content-center pb-lg-0">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary me-4"
        >
          <span className="indicator-label">Submit</span>
          {loading && (
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{" "}
      </div>
      {/* end::Form group */}
    </form>
  );
}
