import { useState, useEffect, ChangeEvent } from "react";
import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { PaymentDetailsModal } from "./PaymentLinkDetails/paymentDetails";
import { CreateAppModal } from "./paymentLinkStepper/CreateAppModal";
import copyIcon from "../../../_metronic/assets/Icons/copy.png";
import Swal from "sweetalert2";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Authorize from "../../modules/auth/components/Authorize";
import Loader from "../components/Loader";
import { DrawerComponent } from "../../../_metronic/assets/ts/components";

export interface AccountStmnt {
  name: string;
  currency: string;
  amount: number;
  status: string;
  referenceId: string;
  createdDate: string;
  expiryDate: string;
  autoReminder: string;
  paymentLink: string;
  notes: string;
  description: string;
}

export interface statData {
  paylinkIssuedAmount: number;
  paylinkPaidAmount: number;
  qrIssuedAmount: number;
  qrPaidAmount: number;
}

export default function PaymentLink() {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [data, setData] = useState<AccountStmnt[]>([]);
  const [selectedData, setSelectedData] = useState<AccountStmnt | null>(null);
  const [filteredTransactions, setFilteredTransactions] = useState(data);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const [statistics, setStatistics] = useState<statData | null>(null);
  const [loading, setLoading] = useState(false);

  const [showTooltip, setShowTooltip] = useState<number | null>(null);

  const tooltipCopy = <Tooltip id="tooltip-add-money">Copy</Tooltip>;
  const tooltipResend = <Tooltip id="tooltip-add-money">Resend</Tooltip>;

  useEffect(() => {
    setLoading(true);
  }, []);

  const handleCopyClick = (paymentLink: string, index: number) => {
    // Copy payment link to clipboard
    navigator.clipboard.writeText(paymentLink);

    // Show tooltip for the specific index
    setShowTooltip(index);

    // Hide tooltip after a short delay (e.g., 1500 milliseconds)
    setTimeout(() => {
      setShowTooltip(null);
    }, 1500);
  };
  const renderTooltip = (index: number) => {
    if (showTooltip === index) {
      return (
        <div
          style={{
            position: "absolute",
            top: "-25px",
            left: "0",
            background: "#fff",
            padding: "3px",
            border: "1px solid #ccc",
            fontSize: "12px",
          }}
        >
          <img
            src={copyIcon}
            alt="copy"
            style={{ height: "12px", width: "12px", cursor: "pointer" }}
          />
          <span style={{ marginLeft: "3px" }}> Copied</span>
        </div>
      );
    }
    return null;
  };

  const handleSelect = (selectedIndex: number) => {
    setSelectedIndex(selectedIndex);
  };
  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };
  const handleDetailsClick = (index: number) => {
    const clickedData: AccountStmnt = currentData[index];
    setSelectedData(() => clickedData);
  };

  useEffect(() => {
    if (data) {
      let filteredData = data.filter((paylink) => {
        const dateMatch = paylink?.createdDate
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase());
        const amountMatch = paylink.amount
          ?.toString()
          ?.includes(searchQuery?.toLowerCase());
        const nameMatch = paylink?.name
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase());
        return dateMatch || amountMatch || nameMatch;
      });

      // Filter further based on the selected tab
      if (selectedTab === "Unpaid") {
        filteredData = filteredData.filter(
          (transaction) => transaction.status === "created"
        );
      } else if (selectedTab === "Paid") {
        filteredData = filteredData.filter(
          (transaction) => transaction.status === "paid"
        );
      } else if (selectedTab === "Expired") {
        filteredData = filteredData.filter(
          (transaction) => transaction.status === "expired"
        );
      }

      setFilteredTransactions(filteredData);
    }
  }, [data, searchQuery, selectedTab]);

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "All") {
      setFilteredTransactions(data);
    } else if (tab === "Unpaid") {
      const filteredData = data.filter((transaction) =>
        tab === "Unpaid" ? transaction.status === "created" : ""
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Paid") {
      const filteredData = data.filter((transaction) =>
        tab === "Paid" ? transaction.status === "paid" : ""
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Expired") {
      const filteredData = data.filter((transaction) =>
        tab === "Expired" ? transaction.status === "expired" : ""
      );
      setFilteredTransactions(filteredData);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const Paymentlog = async () => {
    try {
      const url = `${API_URL}/payment/viewPaymentLinks`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setData(data.message.paymentLinkModels);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Paymentlog();
  }, []);

  const getQRStatistics = async () => {
    try {
      const url = `${API_URL}/payment/getPaymentStatistics`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setStatistics(data.message);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getQRStatistics();
  }, []);

  const handleResend = (index: number) => {
    const clickedData: AccountStmnt = currentData[index];
    const refNo = clickedData.referenceId;

    const resendPaymentLink = async () => {
      try {
        const url = `${API_URL}/payment/resentPaymentLink`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          txnPGReferenceNumber: refNo,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        if (data.status.statusCode === 0) {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "Payment link has been resent",
            confirmButtonColor: "#007bff",
            confirmButtonText: "OK",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    resendPaymentLink();
  };

  useEffect(() => {
    DrawerComponent.reinitialization();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h4 className="mb-5">Payment Link Dashboard</h4>
          <div
            className={`d-flex gap-3 ${
              window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
            }`}
          >
            <div className="card  d-flex flex-column flex-grow-1">
              <div
                className={`d-flex justify-content-between gap-8 ${
                  window.innerWidth < 13 * 96 ? "flex-wrap" : "flex-nowrap"
                } `}
                style={{
                  paddingTop: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <Carousel
                  activeIndex={selectedIndex}
                  onSelect={handleSelect}
                  indicators={false}
                  style={{
                    width: window.innerWidth < 11 * 96 ? "50%" : "450px",
                  }}
                  interval={null}
                  controls={false}
                >
                  <Carousel.Item>
                    <div className="card shadow-sm d-flex flex-grow-1 p-3 bg-gray-300">
                      <div
                        className="card-body p-10 d-flex flex-column align-items-start justify-content-center"
                        style={{ height: "200px" }}
                      >
                        <h4 className="p-0 m-0 text-dark">
                          Unpaid Payment Links
                        </h4>
                        <div>
                          <h1 className="p-0 text-dark mt-5">
                            {
                              data?.filter((item) => item.status === "created")
                                ?.length
                            }
                          </h1>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>

                <div
                  className="card shadow-sm d-flex  flex-grow-1 "
                  style={{ width: "50%" }}
                >
                  <div className="card-body p-5 flex-grow-1 p-0 m-0">
                    <div className="d-flex justify-content-between mb-3 flex-grow-1">
                      <h4 className="p-0 m-0">Payment Link Life Time</h4>
                    </div>
                    <br />

                    <div className="  w-100 d-flex flex-row gap-7">
                      <div className=" card shadow-sm bg-info w-100 p-10">
                        <div className="d-flex justify-content-start">
                          <h1 className="text-light fs-2x fw-bolder">
                            {" "}
                            {statistics?.paylinkIssuedAmount?.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            <span className="fs-9">({baseCurrency})</span>
                          </h1>
                          <br />
                          <br />
                        </div>
                        <div className="d-flex justify-content-start">
                          <p className="text-light fs-7 ">
                            {" "}
                            Total Issued Amount
                          </p>
                        </div>
                      </div>
                      <div className=" card shadow-sm bg-primary w-100  p-10 ">
                        <div className="d-flex justify-content-start">
                          <h1 className="text-light fs-2x fw-bolder">
                            {statistics?.paylinkPaidAmount?.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            <span className="fs-9">({baseCurrency})</span>
                          </h1>
                          <br />
                          <br />
                        </div>
                        <div className="d-flex justify-content-start">
                          <p className="text-light fs-7 text-nowrap me-5">
                            {" "}
                            Total Collections
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div className="p-5">
                <div className="py-2 card shadow-sm p-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                      <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
                        <li className="nav-item" key={1}>
                          <a
                            className="nav-link text-active-gray-800 active"
                            data-bs-toggle="tab"
                            href="#kt_tab_pane_1"
                            onClick={() => handleTabClick("All")}
                          >
                            All
                          </a>
                        </li>
                        <li className="nav-item" key={2}>
                          <a
                            className="nav-link text-active-gray-800"
                            data-bs-toggle="tab"
                            href="#kt_tab_pane_2"
                            onClick={() => handleTabClick("Unpaid")}
                          >
                            Unpaid
                          </a>
                        </li>
                        <li className="nav-item" key={3}>
                          <a
                            className="nav-link text-active-gray-800 text-nowrap"
                            data-bs-toggle="tab"
                            href="#kt_tab_pane_3"
                            onClick={() => handleTabClick("Paid")}
                          >
                            Paid
                          </a>
                        </li>
                        <li className="nav-item" key={4}>
                          <a
                            className="nav-link text-active-gray-800 text-nowrap"
                            data-bs-toggle="tab"
                            href="#kt_tab_pane_4"
                            onClick={() => handleTabClick("Expired")}
                          >
                            Expired
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="d-flex flex-1 align-items-center p-3 gap-10 me-5">
                      <div className="d-flex align-items-center position-relative my-1">
                        <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-4">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        {/* <form autoComplete="off"> */}
                        <input
                          type="text"
                          className="form-control p-3 border-secondary form-control-solid w-250px ps-15"
                          placeholder="Search Payment Links"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        {/* </form> */}
                      </div>
                      <Authorize hasAnyPermission={["MENU_PAY_LINK|ADD"]}>
                        <button
                          className="btn fs-7 p-3"
                          // onClick={() => navigate("/payment-link/create-payment-link")}
                          onClick={() => setShowCreateAppModal(true)}
                        >
                          Create Payment Link
                        </button>
                      </Authorize>
                    </div>
                  </div>

                  <div className="tab-content " id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="kt_tab_pane_1"
                      role="tabpanel"
                    >
                      <>
                        <div className="card p-2">
                          <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                            <thead className="bg-gray-200">
                              <tr className="text-start text-dark-600 fw-bold fs-6  ">
                                <th className="min-w-100px p-5">
                                  Business Name
                                </th>
                                <th className="min-w-100px p-5">
                                  Payment Link
                                </th>
                                <th className="min-w-100px p-5">Expiry Date</th>
                                <th className="min-w-100px p-5 text-nowrap">
                                  Amount
                                </th>
                                <th className="min-w-100px p-5 text-nowrap">
                                  Status
                                </th>
                                <th className="min-w-100px p-5 text-nowrap">
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {currentData && currentData?.length > 0 ? (
                                currentData?.map((item, index) => (
                                  <tr
                                    key={item.referenceId}
                                    onMouseOver={(e) => {
                                      const target =
                                        e.currentTarget as HTMLElement;
                                      target.style.backgroundColor = "#f1f1f2";
                                      // target.style.transform =
                                      //   "translate3d(6px, -6px, 0)";
                                      // target.style.boxShadow =
                                      //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                    }}
                                    onMouseOut={(e) => {
                                      const target =
                                        e.currentTarget as HTMLElement;
                                      target.style.backgroundColor = "white";
                                      // target.style.transform = "none";
                                      // target.style.boxShadow = "none";
                                    }}
                                  >
                                    <td className="p-5 text-gray-600">
                                      {item?.name}
                                    </td>
                                    <td
                                      className="p-5 text-gray-600 cursor-pointer text-hover-primary"
                                      onClick={() => handleDetailsClick(index)}
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="left"
                                      data-bs-dismiss="click"
                                      data-bs-trigger="hover"
                                      id="kt_modal_toggle"
                                    >
                                      {item?.paymentLink}
                                    </td>

                                    <td className="p-5 text-gray-600">
                                      {item?.expiryDate}
                                    </td>
                                    <td className={`p-5 text-gray-600`}>
                                      {item?.currency}{" "}
                                      {item?.amount.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                      {/* <i
                            className={`bi bi-box-arrow-${
                              item?.txnType == 'D' ? 'in-down text-primary' : 'up text-danger'
                            }`}
                          ></i> */}
                                    </td>
                                    <td
                                      className={`p-5  p-2 ${
                                        item?.status == "created"
                                          ? "text-primary"
                                          : item?.status == "paid"
                                            ? "text-success"
                                            : "text-danger"
                                      } `}
                                    >
                                      {item?.status === "created"
                                        ? "Unpaid"
                                        : item?.status === "paid"
                                          ? "Paid"
                                          : item?.status === "cancelled"
                                            ? "Cancelled"
                                            : item?.status === "expired"
                                              ? "Expired"
                                              : ""}
                                    </td>
                                    {item?.status === "created" && (
                                      <td className="p-5 text-gray-600 p-2">
                                        <div
                                          className="d-flex align-items-center gap-10"
                                          style={{ position: "relative" }}
                                        >
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={tooltipCopy}
                                          >
                                            <img
                                              src={copyIcon}
                                              alt="copy"
                                              style={{
                                                height: "15px",
                                                width: "15px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleCopyClick(
                                                  item.paymentLink,
                                                  index
                                                )
                                              }
                                            />
                                          </OverlayTrigger>

                                          {/* Tooltip component */}
                                          {renderTooltip(index)}
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={tooltipResend}
                                          >
                                            <i
                                              className="bi bi-send-plus text-dark fs-5 cursor-pointer"
                                              onClick={() => {
                                                handleResend(index);
                                              }}
                                            ></i>
                                          </OverlayTrigger>
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6} className="text-center">
                                    No Data Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="kt_tab_pane_2"
                      role="tabpanel"
                    >
                      <>
                        <div className="card p-2">
                          <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                            <thead className="bg-gray-200">
                              <tr className="text-start text-dark-600 fw-bold fs-6  ">
                                <th className="min-w-100px p-5">
                                  Business Name
                                </th>
                                <th className="min-w-100px p-5">
                                  Payment Link
                                </th>
                                <th className="min-w-100px p-5">Expiry Date</th>
                                <th className="min-w-100px p-5 text-nowrap">
                                  Amount
                                </th>
                                <th className="min-w-100px p-5 text-nowrap">
                                  Status
                                </th>
                                <th className="min-w-100px p-5 text-nowrap">
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {currentData && currentData.length > 0 ? (
                                currentData?.map((item, index) => {
                                  if (item.status === "created") {
                                    return (
                                      <tr
                                        key={item.referenceId}
                                        onMouseOver={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "#f1f1f2";
                                          // target.style.transform =
                                          //   "translate3d(6px, -6px, 0)";
                                          // target.style.boxShadow =
                                          //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                        }}
                                        onMouseOut={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "white";
                                          // target.style.transform = "none";
                                          // target.style.boxShadow = "none";
                                        }}
                                      >
                                        <td className="p-5 text-gray-600">
                                          {item?.name}
                                        </td>
                                        <td
                                          className="p-5 text-gray-600 cursor-pointer text-hover-primary"
                                          onClick={() =>
                                            handleDetailsClick(index)
                                          }
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="left"
                                          data-bs-dismiss="click"
                                          data-bs-trigger="hover"
                                          id="kt_modal_toggle"
                                        >
                                          {item?.paymentLink}
                                        </td>

                                        <td className="p-5 text-gray-600">
                                          {item?.expiryDate}
                                        </td>
                                        <td className={`p-5 text-gray-600`}>
                                          {item?.currency}{" "}
                                          {item?.amount.toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}{" "}
                                          {/* <i
                            className={`bi bi-box-arrow-${
                              item?.txnType == 'D' ? 'in-down text-primary' : 'up text-danger'
                            }`}
                          ></i> */}
                                        </td>
                                        <td className="p-5 text-primary  p-2">
                                          {item?.status === "created"
                                            ? "Unpaid"
                                            : ""}{" "}
                                        </td>
                                        <td className="p-5 text-gray-600 p-2">
                                          <div
                                            className="d-flex align-items-center gap-10"
                                            style={{ position: "relative" }}
                                          >
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={tooltipCopy}
                                            >
                                              <img
                                                src={copyIcon}
                                                alt="copy"
                                                style={{
                                                  height: "15px",
                                                  width: "15px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  handleCopyClick(
                                                    item.paymentLink,
                                                    index
                                                  )
                                                }
                                              />
                                            </OverlayTrigger>

                                            {/* Tooltip component */}
                                            {renderTooltip(index)}

                                            <OverlayTrigger
                                              placement="top"
                                              overlay={tooltipResend}
                                            >
                                              <i
                                                className="bi bi-send-plus text-dark fs-5 cursor-pointer"
                                                onClick={() => {
                                                  handleResend(index);
                                                }}
                                              ></i>
                                            </OverlayTrigger>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                })
                              ) : (
                                <tr>
                                  <td colSpan={6} className="text-center">
                                    No Data Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="kt_tab_pane_3"
                      role="tabpanel"
                    >
                      <>
                        <div className="card p-2">
                          <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                            <thead className="bg-gray-200">
                              <tr className="text-start text-dark-600 fw-bold fs-6  ">
                                <th className="min-w-100px p-5">
                                  Business Name
                                </th>
                                <th className="min-w-100px p-5">
                                  Payment Link
                                </th>
                                <th className="min-w-100px p-5">Expiry Date</th>
                                <th className="min-w-100px p-5 text-nowrap">
                                  Amount
                                </th>
                                <th className="min-w-100px p-5 text-nowrap">
                                  Status
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {currentData && currentData.length > 0 ? (
                                currentData?.map((item, index) => {
                                  if (item.status === "paid") {
                                    return (
                                      <tr
                                        key={item.referenceId}
                                        onMouseOver={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "#f1f1f2";
                                          // target.style.transform =
                                          //   "translate3d(6px, -6px, 0)";
                                          // target.style.boxShadow =
                                          //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                        }}
                                        onMouseOut={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "white";
                                          // target.style.transform = "none";
                                          // target.style.boxShadow = "none";
                                        }}
                                      >
                                        <td className="p-5 text-gray-600">
                                          {item?.name}
                                        </td>
                                        <td
                                          className="p-5 text-gray-600 cursor-pointer text-hover-primary"
                                          onClick={() =>
                                            handleDetailsClick(index)
                                          }
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="left"
                                          data-bs-dismiss="click"
                                          data-bs-trigger="hover"
                                          id="kt_modal_toggle"
                                        >
                                          {item?.paymentLink}
                                        </td>

                                        <td className="p-5 text-gray-600">
                                          {item?.expiryDate}
                                        </td>
                                        <td className={`p-5 text-gray-600`}>
                                          {item?.currency}{" "}
                                          {item?.amount.toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}{" "}
                                          {/* <i
                            className={`bi bi-box-arrow-${
                              item?.txnType == 'D' ? 'in-down text-primary' : 'up text-danger'
                            }`}
                          ></i> */}
                                        </td>
                                        <td className="p-5 text-success p-2">
                                          {item.status === "paid"
                                            ? "Paid"
                                            : item.status}{" "}
                                        </td>
                                      </tr>
                                    );
                                  }
                                })
                              ) : (
                                <tr>
                                  <td colSpan={6} className="text-center">
                                    No Data Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="kt_tab_pane_4"
                      role="tabpanel"
                    >
                      <>
                        <div className="card p-2">
                          <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                            <thead className="bg-gray-200">
                              <tr className="text-start text-dark-600 fw-bold fs-6  ">
                                <th className="min-w-100px p-5">
                                  Business Name
                                </th>
                                <th className="min-w-100px p-5">
                                  Payment Link
                                </th>
                                <th className="min-w-100px p-5">Expiry Date</th>
                                <th className="min-w-100px p-5 text-nowrap">
                                  Amount
                                </th>
                                <th className="min-w-100px p-5 text-nowrap">
                                  Status
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {currentData && currentData.length > 0 ? (
                                currentData?.map((item, index) => {
                                  if (item.status === "expired") {
                                    return (
                                      <tr
                                        key={item.referenceId}
                                        onMouseOver={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "#f1f1f2";
                                          // target.style.transform =
                                          //   "translate3d(6px, -6px, 0)";
                                          // target.style.boxShadow =
                                          //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                        }}
                                        onMouseOut={(e) => {
                                          const target =
                                            e.currentTarget as HTMLElement;
                                          target.style.backgroundColor =
                                            "white";
                                          // target.style.transform = "none";
                                          // target.style.boxShadow = "none";
                                        }}
                                      >
                                        <td className="p-5 text-gray-600">
                                          {item?.name}
                                        </td>
                                        <td
                                          className="p-5 text-gray-600 cursor-pointer text-hover-primary"
                                          onClick={() =>
                                            handleDetailsClick(index)
                                          }
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="left"
                                          data-bs-dismiss="click"
                                          data-bs-trigger="hover"
                                          id="kt_modal_toggle"
                                        >
                                          {item?.paymentLink}
                                        </td>

                                        <td className="p-5 text-gray-600">
                                          {item?.expiryDate}
                                        </td>
                                        <td className={`p-5 text-gray-600`}>
                                          {item?.currency}{" "}
                                          {item?.amount.toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}{" "}
                                          {/* <i
                            className={`bi bi-box-arrow-${
                              item?.txnType == 'D' ? 'in-down text-primary' : 'up text-danger'
                            }`}
                          ></i> */}
                                        </td>
                                        <td className="p-5 text-danger p-2">
                                          {item.status === "expired"
                                            ? "Expired"
                                            : item.status}{" "}
                                        </td>
                                      </tr>
                                    );
                                  }
                                })
                              ) : (
                                <tr>
                                  <td colSpan={6} className="text-center">
                                    No Data Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="p-5">
                      <ul className="pagination">
                        <li
                          className={`page-item double-arrow ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          key={0}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() => handlePageChange(1)}
                          >
                            <i className="previous"></i>
                            <i className="previous"></i>
                          </a>
                        </li>
                        <li
                          className={`page-item previous ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          key={1}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            <i className="previous"></i>
                          </a>
                        </li>
                        {renderPageNumbers()}
                        <li
                          className={`page-item next ${
                            currentPage ===
                            Math.ceil(
                              filteredTransactions?.length / itemsPerPage
                            )
                              ? "disabled"
                              : ""
                          }`}
                          key={2}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            <i className="next"></i>
                          </a>
                        </li>
                        <li
                          className={`page-item double-arrow ${
                            currentPage ===
                            Math.ceil(
                              filteredTransactions?.length / itemsPerPage
                            )
                              ? "disabled"
                              : ""
                          }`}
                          key={3}
                        >
                          <a
                            href="#"
                            className="page-link"
                            onClick={() =>
                              handlePageChange(
                                Math.ceil(
                                  filteredTransactions?.length / itemsPerPage
                                )
                              )
                            }
                          >
                            <i className="next"></i>
                            <i className="next"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="p-5 d-flex align-items-center">
                      <label
                        htmlFor="itemsPerPage"
                        style={{ fontWeight: "bold" }}
                      >
                        Total: {filteredTransactions?.length}&nbsp;&nbsp;
                      </label>
                      <select
                        className=" cursor-pointer"
                        id="itemsPerPage"
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                      >
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PaymentDetailsModal Data={selectedData} />
          <CreateAppModal
            show={showCreateAppModal}
            handleClose={() => setShowCreateAppModal(false)}
            Paymentlog={Paymentlog}
          />
        </>
      )}
    </div>
  );
}
