/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { defaultCreateAppData, ICreateAppData } from "./IAppModels";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { KTIcon } from "../../../../_metronic/helpers";
import { Step1 } from "./steps/Step1";
import { Step2, dataOutcome } from "./steps/Step2";
import Swal from "sweetalert2";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import { Step3 } from "./steps/Step3";

type Props = {
  show: boolean;
  handleClose: () => void;
  Paymentlog: () => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const CreateAppModal = ({ show, handleClose, Paymentlog }: Props) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData);
  const [hasError, setHasError] = useState(false);

  // const [isFileUploading, setIsFileUploading] = useState(false);
  const isFileUploading = false;
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const [txnPin, setTxnPin] = useState("");

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  document.addEventListener("keydown", function (event) {
    if (event.key === "Escape" || event.key === "Esc") {
      Swal.clickConfirm();
      updateData(resetData);
      handleClose();
    }
  });

  const validateForm = () => {
    console.log("validate called");

    const expiryDate = new Date(data.date.date);
    const today = new Date();
    const reminderFrequency = parseInt(data.days.days);

    if (!expiryDate || !reminderFrequency) {
      return false;
    }

    const differenceInTime = expiryDate.getTime() - today.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    console.log(differenceInDays, reminderFrequency);

    if (differenceInDays < reminderFrequency) {
      return false;
    }

    return true;
  };

  const checkAppBasic = (): boolean => {
    if (data.category.category === "Manual") {
      if (!data.customer.customer) {
        return false;
      }
      if (!data.phone.phone) {
        return false;
      }
      if (!data.amount.amount) {
        return false;
      }
      if (!data.email.email) {
        return false;
      }
      if (!data.description.description) {
        return false;
      }
      if (!data.date.date) {
        return false;
      }
      if (!data.days.days && data.reminder.reminder === "Yes") {
        return false;
      }
      if (data.reminder.reminder === "Yes") {
        return validateForm();
      } else {
        return true;
      }
    }
    if (data.category.category === "Batch") {
      if (!dataOutcome.fileExist) {
        return false;
      }
    }

    return true;
  };

  const checkAppPin = (): boolean => {
    if (txnPin.length === 4) {
      return true;
    }

    return false;
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    // updateData(resetData);
    stepper.current.goPrev();
  };

  const nextStep = () => {
    setHasError(false);
    if (!stepper.current) {
      return;
    }
    console.log(stepper.current.getCurrentStepIndex());

    if (stepper.current.getCurrentStepIndex() === 2) {
      if (!checkAppBasic()) {
        setHasError(true);
        return;
      } else {
        if (data.category.category === "Manual") createPaymentLink();
        if (data.category.category === "Batch") stepper.current.goNext();
      }
    }

    // if (
    //   stepper.current.getCurrentStepIndex() === 2 &&
    //   data.category.category === "Manual"
    // ) {
    //   createPaymentLink();
    // }

    // if (
    //   stepper.current.getCurrentStepIndex() === 2 &&
    //   data.category.category === "Batch"
    // ) {
    //   stepper.current.goNext();
    // }

    if (stepper.current.getCurrentStepIndex() === 1) {
      stepper.current.goNext();
    }
  };

  const submit = () => {
    setHasError(false);

    if (!stepper.current) {
      return;
    }

    if (stepper.current.getCurrentStepIndex() === 3) {
      if (!checkAppPin()) {
        setHasError(true);
        return;
      }
    }

    // if (data.category.category === "Manual") {
    //   console.log(data);

    //   createPaymentLink();
    // }
    if (data.category.category === "Batch" && dataOutcome.fileUploadId) {
      fileUpload();
    }
  };
  const resetData = {
    category: { category: "Manual" },
    amount: { amount: "" },
    customer: { customer: "" },
    phone: { phone: "" },
    email: { email: "" },
    description: { description: "" },
    date: { date: "" },
    days: { days: "" },
    reminder: { reminder: "No" },
    fileData: { fileData: "" },
    submit: { submit: () => {} },
  };

  const fileUpload = async () => {
    try {
      const url = `${API_URL}/bulk/processPaymentLinkFile`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        fileUploadId: dataOutcome?.fileUploadId,
        transactionPIN: txnPin,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      if (data.status.statusCode === 0) {
        updateData(resetData);
        stepper.current?.goto(1);
        handleClose();

        const msg = isFileUploading
          ? "File upload successful !! We will notify once the file is processed"
          : "Your Payment Link has been generated";
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: msg,
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        Paymentlog();
      }
      // if (data.status.statusCode === 1) {
      //   const msg = data?.status?.messageDescription;
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error",
      //     text: msg,
      //     showCancelButton: false,
      //     confirmButtonColor: "#007bff",
      //     confirmButtonText: "Ok",
      //   });
      // }
    } catch (error) {
      console.log(error);
    }
  };
  const createPaymentLink = async () => {
    try {
      const url = `${API_URL}/payment/getPaymentLink`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        firstName: data.customer.customer,
        mobile: data.phone.phone,
        email: data.email.email,
        amount: data.amount.amount,
        currencyCode: "INR",
        refDesc: data.description.description,
        expiryDate: data.date.date,
        reminderEnabled: data.reminder.reminder === "Yes" ? true : false,
        reminderDays: data.days.days,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let qrData = await response.json();

      if (qrData.status.statusCode === 0) {
        updateData({
          amount: { amount: "" },
          customer: { customer: "" },
          phone: { phone: "" },
          email: { email: "" },
          description: { description: "" },
          date: { date: "" },
          days: { days: "" },
          reminder: { reminder: "No" },
          fileData: { fileData: "" },
        });
        Paymentlog();
        stepper.current?.goNext();
        if (data.category.category === "Batch") {
          handleClose();
          const msg = isFileUploading
            ? "File upload successful !! We will notify once the file is processed"
            : "Payment link  has been delivered successfully";
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: msg,
            showCancelButton: false,
            confirmButtonColor: "#007bff",
            confirmButtonText: "Ok",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }
      }
      // if (qrData.status.statusCode === 1) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error",
      //     text: qrData?.status?.messageDescription,
      //     showCancelButton: false,
      //     confirmButtonColor: "#007bff",
      //     confirmButtonText: "Ok",
      //     allowEscapeKey: true,
      //     allowEnterKey: true,
      //   });
      // }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-800px"
      show={show}
      onHide={() => {
        handleClose();
        stepper.current?.goto(1);
        updateData(resetData);
      }}
      onEntered={loadStepper}
    >
      <div className="modal-header">
        <h3 className="m-0 fw-bold">Create Payment Link</h3>

        {/* <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => {
            updateData(resetData);
            stepper.current?.goto(1);
            handleClose();
          }}
        >
          <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
        </div> */}
      </div>

      <div className="modal-body py-lg-10 px-lg-10">
        <div
          ref={stepperRef}
          className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
          id="kt_modal_create_app_stepper"
        >
          <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
            <div className="stepper-nav ps-lg-10">
              <div
                className="stepper-item current"
                data-kt-stepper-element="nav"
              >
                <div className="stepper-wrapper">
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">1</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">
                      Select Type of Payment Link
                    </h3>
                  </div>
                </div>

                <div className="stepper-line h-40px"></div>
              </div>

              <div className="stepper-item" data-kt-stepper-element="nav">
                <div className="stepper-wrapper">
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">2</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Enter details/Upload File</h3>
                  </div>
                </div>
                <div className="stepper-line h-40px"></div>
              </div>

              <div className="stepper-item" data-kt-stepper-element="nav">
                <div className="stepper-wrapper">
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">3</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Confirmation</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="flex-row-fluid  px-lg-15"
            style={{ overflow: "auto", maxHeight: "500px" }}
          >
            <form noValidate id="kt_modal_create_app_form">
              <Step1
                data={data}
                updateData={updateData}
                hasError={hasError}
                txnPin={txnPin}
                setTxnPin={setTxnPin}
              />
              <Step2
                data={data}
                updateData={updateData}
                hasError={hasError}
                txnPin={txnPin}
                setTxnPin={setTxnPin}
              />
              <Step3
                data={data}
                updateData={updateData}
                hasError={hasError}
                txnPin={txnPin}
                setTxnPin={setTxnPin}
              />

              <div className="d-flex flex-stack pt-10">
                <div className="me-2">
                  {data.category.category === "Manual" &&
                  stepper?.current?.getCurrentStepIndex() === 3 ? null : (
                    <button
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                      onClick={prevStep}
                    >
                      <KTIcon iconName="arrow-left" className="fs-3 me-1" />{" "}
                      Previous
                    </button>
                  )}
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-lg btn"
                    data-kt-stepper-action="next"
                    onClick={nextStep}
                  >
                    Next Step{" "}
                    <KTIcon iconName="arrow-right" className="fs-3 ms-1 me-0" />
                  </button>
                  {data.category.category === "Manual" &&
                  stepper?.current?.getCurrentStepIndex() === 3 ? (
                    <button
                      type="button"
                      className="btn btn-lg"
                      onClick={() => {
                        updateData(resetData);
                        stepper.current?.goto(1);
                        handleClose();
                      }}
                    >
                      Close{" "}
                      <KTIcon
                        iconName="arrow-right"
                        className="fs-3 ms-2 me-0"
                      />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-lg btn"
                      data-kt-stepper-action="submit"
                      onClick={submit}
                    >
                      Submit{" "}
                      <KTIcon
                        iconName="arrow-right"
                        className="fs-3 ms-2 me-0"
                      />
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  );
};

export { CreateAppModal };
