import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikProps,
  FormikValues,
} from "formik";
import { PhoneNumberUtil } from "google-libphonenumber";
import { parsePhoneNumber } from "react-phone-number-input";
import { useEffect, useRef } from "react";
import * as Yup from "yup";
import { CountrySelector, PhoneInput } from "react-international-phone";
import { Bank } from "../Payee/PayrollStepper/PayrollStepperModal2";
import Swal from "sweetalert2";
import { fetchBanks, fetchVendors } from "./vendorSlice";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { RootState } from "../../store";
import { CountryDropdown } from "react-country-region-selector";
import { allCountries } from "country-telephone-data";
import { ParsedCountry } from "react-international-phone/build/types";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const AddVendorModal: React.FC = () => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const modalRef = useRef<HTMLDivElement>(null);
  const formikRef = useRef<FormikProps<FormikValues>>(null);

  const { banks } = useSelector((state: RootState) => state?.banks);

  const dispatch = useDispatch();
  const customerData = {
    name: "",
    vendorType: 1,
    companyName: "",
    country: "",
    isdCode: 91,
    mobileNumber: "",
    email: "",
    address: "",
    vatNumber: "",
    bankName: "",
    accountNumber: "",
    bic: "",
    notes: "",
    favourite: "false",
  };

  const handleCloseModal = () => {
    formikRef?.current?.resetForm();
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener("hidden.bs.modal", handleCloseModal);
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    dispatch(fetchVendors({} as any) as any);
    dispatch(fetchBanks({} as any) as any);
  }, []);

  const addVendor = async () => {
    try {
      const url = `${API_URL}/vendor/create`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...customerData,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        handleCloseModal();
        formikRef?.current?.resetForm();
        dispatch(fetchVendors({} as any) as any);
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Vendor has been added",
          showCancelButton: false, // Hide the cancel button
          confirmButtonColor: "#007bff", // Set the button color to btn
          confirmButtonText: "OK",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      // else {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error",
      //     text: data?.status?.messageDescription,
      //     showCancelButton: false, // Hide the cancel button
      //     confirmButtonColor: "#007bff", // Set the button color to btn
      //     confirmButtonText: "OK",
      //     allowEscapeKey: true,
      //     allowEnterKey: true,
      //   });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="modal fade"
      tabIndex={-1}
      id="item-modal"
      ref={modalRef}
      // style={{ zIndex: 99999 }}
    >
      <div
        className="modal-dialog modal-dialog-centered w-800px"
        // style={{ marginTop: "80px" }}
      >
        <div className="modal-content">
          <Formik
            innerRef={formikRef}
            initialValues={{
              customerName: "",
              companyName: "",
              business: "",
              country: "",
              phone: "",
              email: "",
              address: "",
              vatNumber: "",
              bank: "",
              bankAccNumber: "",
              bic: "",
              notes: "",
            }}
            validationSchema={Yup.object({
              customerName: Yup.string()
                .min(2, "Minimum 2 characters required")
                .max(50, "Maximum 50 characters allowed")
                .matches(
                  /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]+$/,
                  "Business holder name should be alphanumeric with letters"
                )
                // .matches(
                //   /^[a-zA-Z0-9 ]+$/,
                //   "Only letters and numbers are allowed"
                // )
                .required("Business Holder Name required"),
              companyName: Yup.string()
                .min(2, "Minimum 2 characters required")
                .max(50, "Maximum 50 characters allowed")
                .matches(
                  /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]+$/,
                  "Vendor name should be alphanumeric with letters"
                )
                // .matches(
                //   /^[a-zA-Z0-9 ]+$/,
                //   "Only letters and numbers are allowed"
                // )
                .required("Vendor Name required"),
              business: Yup.string().required("Vendor Type required"),
              country: Yup.string().when("business", {
                is: (business: string) => business !== "1",
                then: (schema) => schema.required("Country is required"),
              }),
              phone: Yup.string()
                .min(12, "Phone number is required")
                .test(
                  "is-valid-phone",
                  "Phone number is invalid",
                  function (value) {
                    if (value) {
                      return isPhoneValid(value); // Validate phone only if Phone is true and value exists
                    }
                    return true; // If Phone is false, or no value, bypass the phone validation
                  }
                )
                .required("Phone number is required"),
              email: Yup.string()
                .matches(
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  "Invalid email address"
                )
                .required("Email Required"),
              address: Yup.string()
                .min(2, "Minimum 2 characters required")
                .max(100, "Maximum 100 characters allowed")
                .required("Address Required"),
              vatNumber: Yup.string()
                .min(6, "Minimum 6 characters required")
                .max(20, "Maximum 20 characters allowed")
                .matches(
                  /^[a-zA-Z0-9 ]+$/,
                  "Only letters and numbers are allowed"
                )
                .required("VAT Number Required"),
              bank: Yup.string().required("Bank is required"),
              bankAccNumber: Yup.string()
                .min(10, "Mainimum 10 characters needed")
                .max(34, "Maximum 34 characters allowed")
                .matches(
                  /^[a-zA-Z0-9]+$/,
                  "Only letters and numbers are allowed"
                )
                .required("IBAN/Account Number required"),
              bic: Yup.string()
                .min(8, "Minimum 8 characters required")
                .max(11, "Maximum 11 characters allowed")
                .matches(
                  /^[a-zA-Z0-9 ]+$/,
                  "Only letters and numbers are allowed"
                )
                .required("BIC Required"),
              notes: Yup.string().max(100, "Maximum 100 characters allowed"),
              // .required("Notes Required"),
            })}
            onSubmit={(values, { resetForm }) => {
              const phoneNumber = values.phone;
              const phoneNumberParsed = parsePhoneNumber(phoneNumber);

              customerData.name = values?.customerName;
              customerData.companyName = values?.companyName;
              customerData.country =
                values?.business === "1" ? "South Africa" : values?.country;
              customerData.vendorType = +values?.business;
              customerData.mobileNumber =
                phoneNumberParsed?.nationalNumber.toString() || "";
              customerData.isdCode = +(
                phoneNumberParsed?.countryCallingCode ?? 0
              );
              customerData.email = values?.email;
              customerData.address = values?.address;
              customerData.vatNumber = values?.vatNumber;
              customerData.bankName = values?.bank;
              customerData.accountNumber = values?.bankAccNumber;
              customerData.bic = values?.bic;
              customerData.notes = values?.notes;
              addVendor();
            }}
          >
            {({ values }) => (
              <>
                <div className="modal-header">
                  <h3
                    className="modal-title text-nowrap m-0"
                    // style={{ color: "#246bfd" }}
                  >
                    Add Vendor
                  </h3>
                  {/* <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                resetForm();
                handleCloseModal();
              }}
            >
              <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
            </div> */}
                </div>

                <div className="modal-body p-10">
                  <Form>
                    <div
                      className="mb-10"
                      style={{ maxHeight: "400px", overflow: "auto" }}
                    >
                      <div className="mb-5">
                        <label className="form-label">
                          Business Holder Name
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Business Holder Name"
                          name="customerName"
                          maxLength={50}
                        />
                        <ErrorMessage
                          name="customerName"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">Vendor Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Vendor Name"
                          name="companyName"
                          maxLength={50}
                        />
                        <ErrorMessage
                          name="companyName"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">Type of Vendor</label>
                        <Field
                          as="select"
                          className="form-select"
                          aria-label="Select Business"
                          name="business"
                        >
                          <option value="">Select Vendor type</option>
                          <option value="1">Domestic Vendor</option>
                          <option value="2">International Vendor</option>
                        </Field>
                        <ErrorMessage
                          name="business"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      {values?.business === "2" && (
                        <div className="mb-5">
                          <label className="form-label">Country</label>
                          <Field name="country">
                            {({
                              field,
                              form,
                            }: {
                              field: any;
                              form: FormikProps<any>;
                            }) => (
                              <div className="mb-5">
                                <CountryDropdown
                                  {...field}
                                  classes="form-select"
                                  onChange={(
                                    country,
                                    e: React.ChangeEvent<Element>
                                  ) => {
                                    // Find the country code
                                    console.log(allCountries);

                                    const countryData = allCountries.find(
                                      (c) => {
                                        const countryName = c.name
                                          .toLowerCase()
                                          .replace(/\s*\(.*?\)\s*/g, ""); // Removes anything in parentheses
                                        const searchCountry = country
                                          .toLowerCase()
                                          .replace(/\s*\(.*?\)\s*/g, ""); // Same for the search term
                                        return countryName === searchCountry;
                                      }
                                    );

                                    const isdCode = countryData
                                      ? `+${countryData.dialCode}`
                                      : "";

                                    console.log(e);
                                    form.setFieldValue("country", country);
                                    form.setFieldValue("phone", isdCode);
                                  }}
                                />
                                {/* <CountrySelector
                                  selectedCountry="ua"
                                  onSelect={(e) => console.log(e)}
                                  buttonContentWrapperStyle={{}}
                                  buttonStyle={{ width: "430px" }}
                                /> */}
                              </div>
                            )}
                          </Field>
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      )}

                      <div className="mb-5">
                        <label className="form-label">Phone</label>
                        <Field name="phone">
                          {({
                            field,
                            form,
                          }: {
                            field: any;
                            form: FormikProps<any>;
                          }) => (
                            <div className="mb-5">
                              <PhoneInput
                                {...field}
                                inputClassName="form-control"
                                defaultCountry="in"
                                onChange={(phone) => {
                                  form.setFieldValue("phone", phone);
                                }}
                                inputStyle={{ padding: "21px", width: "100%" }}
                                countrySelectorStyleProps={{
                                  buttonStyle: {
                                    padding: "21px 5px",
                                  },
                                }}
                                disableDialCodePrefill={false}
                                forceDialCode={
                                  formikRef?.current?.values?.business === "1"
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          )}
                        </Field>

                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">Email</label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          maxLength={50}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">Address</label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Address"
                          name="address"
                          maxLength={100}
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">VAT Number</label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="VAT Number"
                          name="vatNumber"
                          maxLength={20}
                        />
                        <ErrorMessage
                          name="vatNumber"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">Bank</label>
                        <Field
                          as="select"
                          className="form-select"
                          aria-label="Select Bank"
                          name="bank"
                        >
                          <option value="">Select a bank</option>
                          {banks &&
                            banks.map((item: Bank, index: number) => (
                              <option key={index} value={item.key}>
                                {item.value}
                              </option>
                            ))}
                        </Field>

                        <ErrorMessage
                          name="bank"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">
                          IBAN / Account Number
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="IBAN/Account Number"
                          name="bankAccNumber"
                          maxLength={34}
                        />
                        <ErrorMessage
                          name="bankAccNumber"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">SWIFT / BIC</label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="BIC"
                          name="bic"
                          maxLength={11}
                        />
                        <ErrorMessage
                          name="bic"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-5">
                        <label className="form-label">Notes</label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Notes"
                          name="notes"
                          maxLength={100}
                        />
                        <ErrorMessage
                          name="notes"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="">
                      <button type="submit" className="btn w-100">
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
