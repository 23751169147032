import { useState, ChangeEvent, FC, useEffect } from "react";
import { CreateAppModal } from "../components/Modal/PayeeModal";
import { TransferModal } from "./TransferModal";
import { DeleteModal } from "../components/DeleteModal";
import { OverseasTransfer } from "./OverseasTransfer";
import { resetFunction } from "./TransferMoney";
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Authorize from "../../modules/auth/components/Authorize";
import { DrawerComponent } from "../../../_metronic/assets/ts/components";
import Loader from "../components/Loader";

interface MyData {
  currency: string;
  payeeAccountNumber: string;
  payeeType: string;
  payeeFirstName: string;
  payeeSubType: string;
  payeeAlias: string;
  payeeId: number;
  isFavourite: boolean;
  payeeCategory: string;
  payeeStatus: number;
  fundType: string;
}

export const ApiTrfMon = {
  api: (): void => {},
};

const TableComponent: FC = () => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const tooltipDelete = <Tooltip id="tooltip-add-money">Delete</Tooltip>;

  const role = sessionStorage.getItem("role");
  const roleName = role ? JSON.parse(role) : null;

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [data, setData] = useState<MyData[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState(data);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<MyData | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [deletePayee, setDeletePayee] = useState(0);
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      Swal.fire({
        icon: "warning",

        html: `
    <p style="font-weight: bold; text-align: center; color: red;">
      You do not have access to this feature.
    </p>
 
    <p style="font-weight: bold; text-align: center; color: red;">
      Please contact admin.
    </p>
  `,
        allowEscapeKey: true,
        allowEnterKey: true,
        preConfirm: () => {
          window.history.back();
        },
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true)
  }, [])


  useEffect(() => {
    // Filter data based on both selected tab and search query
    const filteredData = data?.filter((payee) => {
      // Filter based on selected tab
      let isTabMatched = false;
      switch (selectedTab) {
        case "All":
          isTabMatched = payee.payeeStatus === 10;
          break;
        case "Favourite":
          isTabMatched = payee.isFavourite && payee.payeeStatus === 10;
          break;
        case "Same bank":
          isTabMatched =
            payee.payeeSubType === "INTRA" && payee.payeeStatus === 10;
          break;
        case "Other bank":
          isTabMatched =
            payee.payeeSubType === "INTER" && payee.payeeStatus === 10;
          break;
        case "International":
          isTabMatched =
            payee.payeeType === "REMITTANCE" && payee.payeeStatus === 10;
          break;
        case "Wallet":
          isTabMatched =
            payee.payeeType === "WALLET" && payee.payeeStatus === 10;
          break;
        default:
          isTabMatched = true; // Default to true for "All" tab
      }

      // Filter based on search query
      const isSearchMatched =
        payee.payeeAccountNumber
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        payee.payeeFirstName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        payee.currency.toString().includes(searchQuery.toLowerCase());

      // Return true if both conditions are matched
      return isTabMatched && isSearchMatched;
    });

    // Update filtered transactions
    setFilteredTransactions(filteredData);
  }, [data, searchQuery, selectedTab]);

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleClick = (index: number) => {
    const clickedData: MyData = currentData[index];
    const condition = clickedData.isFavourite ? false : true;

    const addFav = async () => {
      try {
        const url = `${API_URL}/payee/makeFavourite`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          payeeType: clickedData?.payeeType,
          payeeAlias: clickedData?.payeeAlias,
          isFavourite: condition,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        if (data?.status?.statusCode === 0) PayeeData();
      } catch (error) {
        console.log(error);
      }
    };
    addFav();
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleDetailsClick = (index: number) => {
    const clickedData: MyData = currentData[index];
    setSelectedData(() => clickedData);
  };

  const PayeeData = async () => {
    try {
      const url = `${API_URL}/payee/listPayee`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      setData(data.results);
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    ApiTrfMon.api = PayeeData;
    PayeeData();
  }, []);

  const handleDelete = (index: number) => {
    const clickedData: MyData = currentData[index];
    setDeletePayee(clickedData.payeeId);

    const DeletePayee = async () => {
      try {
        const url = `${API_URL}/payee/deletePayee`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          payeeAlias: clickedData?.payeeAlias,
          payeeType: clickedData?.payeeType,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        if (data?.status?.statusCode === 0) {
          PayeeData();
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: "The selected payee has been deleted!",
            showCancelButton: false, // Show the cancel button
            confirmButtonColor: "#007bff", // Set the confirm button color to btn
            confirmButtonText: "Ok",
            cancelButtonColor: "#9fa6b2",
            cancelButtonText: "Cancel",
            allowEscapeKey: true,
            allowEnterKey: true, // Text for the cancel button
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    Swal.fire({
      icon: "warning",
      text: "Are you sure you want to delete the selected payee?",
      showCancelButton: true, // Show the cancel button
      confirmButtonColor: "#007bff", // Set the confirm button color to btn
      confirmButtonText: "Yes",
      cancelButtonColor: "#9fa6b2",
      cancelButtonText: "Cancel",
      allowEscapeKey: true,
      allowEnterKey: true, // Text for the cancel button
    }).then((result) => {
      if (result.isConfirmed) {
        DeletePayee();
      }
    });
    // const modalElement = document.getElementById("delete_modal");
    // const modal = new Modal(modalElement as Element);
    // modal.show();
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "All") {
      const filteredData = data.filter(
        (transaction) => transaction.payeeStatus === 10
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Favourite") {
      const filteredData = data.filter(
        (transaction) =>
          transaction.isFavourite && transaction.payeeStatus === 10
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Same bank") {
      const filteredData = data.filter(
        (transaction) =>
          transaction.payeeSubType === "INTRA" && transaction.payeeStatus === 10
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Other bank") {
      const filteredData = data.filter(
        (transaction) =>
          transaction.payeeSubType === "INTER" && transaction.payeeStatus === 10
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "International") {
      const filteredData = data.filter(
        (transaction) =>
          transaction.payeeType === "REMITTANCE" &&
          transaction.payeeStatus === 10
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Wallet") {
      const filteredData = data.filter(
        (transaction) =>
          transaction.payeeType === "WALLET" && transaction.payeeStatus === 10
      );
      setFilteredTransactions(filteredData);
    }
  };
  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  useEffect(() => {
    DrawerComponent.reinitialization();
  }, []);

  return (
    <div >
    {loading ? (
     <Loader/>
    ) : (
    <>
      <style>{`
  .btn:hover {
    color: #0000ff !important;
  }
 
`}</style>

      <h4 className="mb-5">Transfer Money</h4>

      <div className="py-2 card p-5">
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
              <li className="nav-item" key={1}>
                <a
                  className="nav-link text-active-gray-800 active"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                  onClick={() => handleTabClick("All")}
                >
                  All
                </a>
              </li>
              <li className="nav-item" key={2}>
                <a
                  className="nav-link text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                  onClick={() => handleTabClick("Favourite")}
                >
                  Favourite
                </a>
              </li>
              <li className="nav-item" key={3}>
                <a
                  className="nav-link text-active-gray-800 text-nowrap"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_3"
                  onClick={() => handleTabClick("Same bank")}
                >
                  Same bank
                </a>
              </li>
              <li className="nav-item" key={4}>
                <a
                  className="nav-link text-active-gray-800 text-nowrap"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_4"
                  onClick={() => handleTabClick("Other bank")}
                >
                  Other bank
                </a>
              </li>
              {/* <Authorize hasAnyPermission={["MENU_REMIT|VIEW"]}> */}
              <li className="nav-item" key={5}>
                <a
                  className="nav-link text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_5"
                  onClick={() => handleTabClick("International")}
                >
                  International
                </a>
              </li>
              {/* </Authorize> */}
              <li className="nav-item" key={6}>
                <a
                  className="nav-link text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_6"
                  onClick={() => handleTabClick("Wallet")}
                >
                  Wallet
                </a>
              </li>
            </ul>
          </div>

          <div className="d-flex align-items-center p-3 gap-10">
            <div className="d-flex align-items-center position-relative my-1">
              <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              <input
                value={searchQuery}
                onChange={handleSearchChange}
                type="text"
                data-kt-docs-table-filter="search"
                className="form-control p-3 border-secondary form-control-solid w-250px ps-15"
                placeholder="Search Payees"
              />
            </div>
            <Authorize hasAnyPermission={["MENU_PAYEE|ADD"]}>
              <button
                className="btn fs-7 p-3"
                onClick={() => setShowCreateAppModal(true)}
                style={{ backgroundColor: "#0000ff", color: "#ffff" }}
              >
                Add Payee
              </button>
            </Authorize>
          </div>
        </div>

        <CreateAppModal
          show={showCreateAppModal}
          handleClose={() => setShowCreateAppModal(false)}
        />

        <div className="tab-content " id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="kt_tab_pane_1"
            role="tabpanel"
          >
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Payee Name</th>
                      <th className="min-w-100px p-5">Payee Type</th>
                      <th className="min-w-100px p-5">Account Number</th>
                      <th className="min-w-100px p-5 text-nowrap">Currency</th>
                      <Authorize hasAnyPermission={["MENU_PAYEE|DELETE"]}>
                        <th className="min-w-100px p-5 text-nowrap">Action</th>
                      </Authorize>
                      <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                        <th className="min-w-100px p-5 text-nowrap">
                          Favourite
                        </th>
                      </Authorize>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData.map((item, index) => {
                        if (item.payeeStatus === 10) {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                            >
                              <td
                                className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                                onClick={() => {
                                  if (item.payeeType === "REMITTANCE") {
                                    if (roleName === "ROLE_SME_ADMIN") {
                                      handleDetailsClick(index);
                                      resetFunction.reset();
                                    } else {
                                      Swal.fire({
                                        icon: "warning",

                                        html: `
                                    <p style="font-weight: bold; text-align: center; color: red;">
                                      You do not have access to this feature.
                                    </p>
                                  
                                    <p style="font-weight: bold; text-align: center; color: red;">
                                      Please contact admin.
                                    </p>
                                  `,
                                        allowEscapeKey: true,
                                        allowEnterKey: true,
                                      });
                                    }
                                  } else {
                                    handleDetailsClick(index);
                                    resetFunction.reset();
                                  }
                                }}
                                {...(item.payeeType === "REMITTANCE"
                                  ? roleName === "ROLE_SME_ADMIN"
                                    ? {
                                        "data-bs-toggle": "tooltip",
                                        "data-bs-placement": "left",
                                        "data-bs-dismiss": "click",
                                        "data-bs-trigger": "hover",
                                        id: "kt_modal_overseas_transfer",
                                      }
                                    : {}
                                  : {
                                      "data-bs-toggle": "tooltip",
                                      "data-bs-placement": "left",
                                      "data-bs-dismiss": "click",
                                      "data-bs-trigger": "hover",
                                      id: "kt_modal_toggle_transfer",
                                    })}
                              >
                                {item.payeeFirstName}
                              </td>

                              {/* <td
                              className="p-5"
                              style={{ fontWeight: "bolder", fontSize: "15px" }}
                              onClick={() => {
                                handleDetailsClick(index);
                                resetFunction.reset();
                              }}
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              data-bs-dismiss="click"
                              data-bs-trigger="hover"
                              id="kt_modal_overseas_transfer"
                            >
                              {item.payeeFirstName}

                            </td> */}

                              <td className="p-5 text-gray-600">
                                <span
                                  className={`badge ${
                                    item?.payeeCategory === "RETAIL"
                                      ? "badge-light-success"
                                      : "badge-light-info"
                                  }`}
                                >
                                  {item?.payeeCategory === "RETAIL"
                                    ? "INDIVIDUAL"
                                    : item.payeeCategory}
                                </span>
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.payeeAccountNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.currency === "R" ? "ZAR" : item.currency}
                              </td>
                              <Authorize
                                hasAnyPermission={["MENU_PAYEE|DELETE"]}
                              >
                                <td className="p-5 text-gray-600 p-2">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash3 text-danger p-5 cursor-pointer"
                                      onClick={() => handleDelete(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </td>
                              </Authorize>
                              <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                                <td className="p-5 ">
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.isFavourite
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 p-5 cursor-pointer`}
                                  />
                                </td>
                              </Authorize>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>

            {/* <DeleteModal data={deletePayee} payee={PayeeData} /> */}
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Payee Name</th>
                      <th className="min-w-100px p-5">Payee Type</th>
                      <th className="min-w-100px p-5">Account Number</th>
                      <th className="min-w-100px p-5 text-nowrap">Currency</th>
                      <Authorize hasAnyPermission={["MENU_PAYEE|DELETE"]}>
                        <th className="min-w-100px p-5 text-nowrap">Action</th>
                      </Authorize>
                      <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                        <th className="min-w-100px p-5 text-nowrap">
                          Favourite
                        </th>
                      </Authorize>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData.map((item, index) => {
                        if (item.isFavourite && item.payeeStatus === 10) {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                            >
                              <td
                                className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                                onClick={() => {
                                  if (item.payeeType === "REMITTANCE") {
                                    if (roleName === "ROLE_SME_ADMIN") {
                                      handleDetailsClick(index);
                                      resetFunction.reset();
                                    } else {
                                      Swal.fire({
                                        icon: "warning",

                                        html: `
                                    <p style="font-weight: bold; text-align: center; color: red;">
                                      You do not have access to this feature.
                                    </p>
                                  
                                    <p style="font-weight: bold; text-align: center; color: red;">
                                      Please contact admin.
                                    </p>
                                  `,
                                        allowEscapeKey: true,
                                        allowEnterKey: true,
                                      });
                                    }
                                  } else {
                                    handleDetailsClick(index);
                                    resetFunction.reset();
                                  }
                                }}
                                {...(item.payeeType === "REMITTANCE"
                                  ? roleName === "ROLE_SME_ADMIN"
                                    ? {
                                        "data-bs-toggle": "tooltip",
                                        "data-bs-placement": "left",
                                        "data-bs-dismiss": "click",
                                        "data-bs-trigger": "hover",
                                        id: "kt_modal_overseas_transfer",
                                      }
                                    : {}
                                  : {
                                      "data-bs-toggle": "tooltip",
                                      "data-bs-placement": "left",
                                      "data-bs-dismiss": "click",
                                      "data-bs-trigger": "hover",
                                      id: "kt_modal_toggle_transfer",
                                    })}
                              >
                                {item.payeeFirstName}
                              </td>
                              <td className="p-5 text-gray-600">
                                <span
                                  className={`badge ${
                                    item?.payeeCategory === "RETAIL"
                                      ? "badge-light-success"
                                      : "badge-light-info"
                                  }`}
                                >
                                  {item?.payeeCategory === "RETAIL"
                                    ? "INDIVIDUAL"
                                    : item.payeeCategory}
                                </span>
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.payeeAccountNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.currency === "R" ? "ZAR" : item.currency}
                              </td>
                              <Authorize
                                hasAnyPermission={["MENU_PAYEE|DELETE"]}
                              >
                                <td className="p-5 text-gray-600 p-2">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash3 text-danger p-5 cursor-pointer"
                                      onClick={() => handleDelete(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </td>
                              </Authorize>
                              <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                                <td className="p-5 ">
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.isFavourite
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 p-5 cursor-pointer`}
                                  />
                                </td>
                              </Authorize>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
            {/* <TransferModal Data={selectedData} /> */}
            {/* <DeleteModal data={deletePayee} payee={PayeeData} /> */}
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Payee Name</th>
                      <th className="min-w-100px p-5">Payee Type</th>
                      <th className="min-w-100px p-5">Account Number</th>
                      <th className="min-w-100px p-5 text-nowrap">Currency</th>
                      <Authorize hasAnyPermission={["MENU_PAYEE|DELETE"]}>
                        <th className="min-w-100px p-5 text-nowrap">Action</th>
                      </Authorize>
                      <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                        <th className="min-w-100px p-5 text-nowrap">
                          Favourite
                        </th>
                      </Authorize>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData.map((item, index) => {
                        if (
                          item.payeeSubType === "INTRA" &&
                          item.payeeStatus === 10
                        ) {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                            >
                              <td
                                className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                                onClick={() => {
                                  handleDetailsClick(index);
                                  resetFunction.reset();
                                }}
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                data-bs-dismiss="click"
                                data-bs-trigger="hover"
                                id="kt_modal_toggle_transfer"
                              >
                                {item.payeeFirstName}
                              </td>
                              <td className="p-5 text-gray-600">
                                <span
                                  className={`badge ${
                                    item?.payeeCategory === "RETAIL"
                                      ? "badge-light-success"
                                      : "badge-light-info"
                                  }`}
                                >
                                  {item?.payeeCategory === "RETAIL"
                                    ? "INDIVIDUAL"
                                    : item.payeeCategory}
                                </span>
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.payeeAccountNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.currency === "R" ? "ZAR" : item.currency}
                              </td>
                              <Authorize
                                hasAnyPermission={["MENU_PAYEE|DELETE"]}
                              >
                                <td className="p-5 text-gray-600 p-2">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash3 text-danger p-5 cursor-pointer"
                                      onClick={() => handleDelete(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </td>
                              </Authorize>
                              <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                                <td className="p-5 ">
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.isFavourite
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 p-5 cursor-pointer`}
                                  />
                                </td>
                              </Authorize>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
            {/* <TransferModal Data={selectedData} /> */}
            {/* <DeleteModal data={deletePayee} payee={PayeeData} /> */}
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Payee Name</th>
                      <th className="min-w-100px p-5">Payee Type</th>
                      <th className="min-w-100px p-5">Account Number</th>
                      <th className="min-w-100px p-5 text-nowrap">Currency</th>
                      <Authorize hasAnyPermission={["MENU_PAYEE|DELETE"]}>
                        <th className="min-w-100px p-5 text-nowrap">Action</th>
                      </Authorize>
                      <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                        <th className="min-w-100px p-5 text-nowrap">
                          Favourite
                        </th>
                      </Authorize>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData.map((item, index) => {
                        if (
                          item.payeeSubType === "INTER" &&
                          item.payeeStatus === 10
                        ) {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                            >
                              <td
                                className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline cursor-pointer"
                                onClick={() => {
                                  handleDetailsClick(index);
                                  resetFunction.reset();
                                }}
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                data-bs-dismiss="click"
                                data-bs-trigger="hover"
                                id="kt_modal_toggle_transfer"
                              >
                                {item.payeeFirstName}
                              </td>
                              <td className="p-5 text-gray-600">
                                <span
                                  className={`badge ${
                                    item?.payeeCategory === "RETAIL"
                                      ? "badge-light-success"
                                      : "badge-light-info"
                                  }`}
                                >
                                  {item?.payeeCategory === "RETAIL"
                                    ? "INDIVIDUAL"
                                    : item.payeeCategory}
                                </span>
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.payeeAccountNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.currency === "R" ? "ZAR" : item.currency}
                              </td>
                              <Authorize
                                hasAnyPermission={["MENU_PAYEE|DELETE"]}
                              >
                                <td className="p-5 text-gray-600 p-2">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash3 text-danger p-5 cursor-pointer"
                                      onClick={() => handleDelete(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </td>
                              </Authorize>
                              <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                                <td className="p-5">
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.isFavourite
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 p-5 cursor-pointer`}
                                  />
                                </td>
                              </Authorize>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
            {/* <TransferModal Data={selectedData} /> */}
            {/* <DeleteModal data={deletePayee} payee={PayeeData} /> */}
          </div>
          {/* <Authorize hasAnyPermission={["MENU_REMIT|VIEW"]}> */}
          <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Payee Name</th>
                      <th className="min-w-100px p-5">Payee Type</th>
                      <th className="min-w-100px p-5">Account Number</th>
                      <th className="min-w-100px p-5 text-nowrap">Currency</th>
                      <Authorize hasAnyPermission={["MENU_PAYEE|DELETE"]}>
                        <th className="min-w-100px p-5 text-nowrap">Action</th>
                      </Authorize>
                      <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                        <th className="min-w-100px p-5 text-nowrap">
                          Favourite
                        </th>
                      </Authorize>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData.map((item, index) => {
                        if (
                          item.payeeType === "REMITTANCE" &&
                          item.payeeStatus === 10
                        ) {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                            >
                              <td
                                className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (roleName === "ROLE_SME_ADMIN") {
                                    handleDetailsClick(index);
                                    resetFunction.reset();
                                  } else {
                                    Swal.fire({
                                      icon: "warning",

                                      html: `
                                  <p style="font-weight: bold; text-align: center; color: red;">
                                    You do not have access to this feature.
                                  </p>
                                
                                  <p style="font-weight: bold; text-align: center; color: red;">
                                    Please contact admin.
                                  </p>
                                `,
                                      allowEscapeKey: true,
                                      allowEnterKey: true,
                                    });
                                  }
                                }}
                                {...(roleName === "ROLE_SME_ADMIN" && {
                                  "data-bs-toggle": "tooltip",
                                  "data-bs-placement": "left",
                                  "data-bs-dismiss": "click",
                                  "data-bs-trigger": "hover",
                                  id: "kt_modal_overseas_transfer",
                                })}
                              >
                                {item.payeeFirstName}
                              </td>
                              <td className="p-5 text-gray-600">
                                <span
                                  className={`badge ${
                                    item?.payeeCategory === "RETAIL"
                                      ? "badge-light-success"
                                      : "badge-light-info"
                                  }`}
                                >
                                  {item?.payeeCategory === "RETAIL"
                                    ? "INDIVIDUAL"
                                    : item.payeeCategory}
                                </span>
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.payeeAccountNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.currency === "R" ? "ZAR" : item.currency}
                              </td>
                              <td className="p-5 text-gray-600 p-2">
                                <Authorize
                                  hasAnyPermission={["MENU_PAYEE|DELETE"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash3 text-danger p-5"
                                      onClick={() => handleDelete(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                              </td>
                              <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                                <td className="p-5 ">
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.isFavourite
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 p-5`}
                                  />
                                </td>
                              </Authorize>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
            {/* <TransferModal Data={selectedData} /> */}
            {/* <DeleteModal data={deletePayee} payee={PayeeData} /> */}
          </div>
          {/* </Authorize> */}
          <div className="tab-pane fade" id="kt_tab_pane_6" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Payee Name</th>
                      <th className="min-w-100px p-5">Payee Type</th>
                      <th className="min-w-100px p-5">Account Number</th>
                      <th className="min-w-100px p-5 text-nowrap">Currency</th>
                      <Authorize hasAnyPermission={["MENU_PAYEE|DELETE"]}>
                        <th className="min-w-100px p-5 text-nowrap">Action</th>
                      </Authorize>
                      <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                        <th className="min-w-100px p-5 text-nowrap">
                          Favourite
                        </th>
                      </Authorize>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData.map((item, index) => {
                        if (
                          item.payeeType === "WALLET" &&
                          item.payeeStatus === 10
                        ) {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                            >
                              <td
                                className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleDetailsClick(index);
                                  resetFunction.reset();
                                }}
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                data-bs-dismiss="click"
                                data-bs-trigger="hover"
                                id="kt_modal_toggle_transfer"
                              >
                                {item.payeeFirstName}
                              </td>
                              <td className="p-5 text-gray-600">
                                <span
                                  className={`badge ${
                                    item?.payeeCategory === "RETAIL"
                                      ? "badge-light-success"
                                      : "badge-light-info"
                                  }`}
                                >
                                  {item?.payeeCategory === "RETAIL"
                                    ? "INDIVIDUAL"
                                    : item.payeeCategory}
                                </span>
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.payeeAccountNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.currency === "R" ? "ZAR" : item.currency}
                              </td>
                              <Authorize
                                hasAnyPermission={["MENU_PAYEE|DELETE"]}
                              >
                                <td className="p-5 text-gray-600 p-2">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipDelete}
                                  >
                                    <i
                                      className="bi bi-trash3 text-danger p-5"
                                      onClick={() => handleDelete(index)}
                                    ></i>
                                  </OverlayTrigger>
                                </td>
                              </Authorize>
                              <Authorize hasAnyPermission={["MENU_PAYEE|EDIT"]}>
                                <td className="p-5 ">
                                  <i
                                    onClick={() => handleClick(index)}
                                    className={`bi bi-star${
                                      item.isFavourite
                                        ? "-fill text-warning"
                                        : ""
                                    } fs-2 p-5`}
                                  />
                                </td>
                              </Authorize>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
            {/* <TransferModal Data={selectedData} /> */}
            {/* <DeleteModal data={deletePayee} payee={PayeeData} /> */}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="p-5">
            <ul className="pagination">
              <li
                className={`page-item double-arrow ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={0}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(1)}
                >
                  <i className="previous"></i>
                  <i className="previous"></i>
                </a>
              </li>
              <li
                className={`page-item previous ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={1}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <i className="previous"></i>
                </a>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item next ${
                  currentPage ===
                  Math.ceil(filteredTransactions?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={2}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <i className="next"></i>
                </a>
              </li>
              <li
                className={`page-item double-arrow ${
                  currentPage ===
                  Math.ceil(filteredTransactions?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={3}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() =>
                    handlePageChange(
                      Math.ceil(filteredTransactions?.length / itemsPerPage)
                    )
                  }
                >
                  <i className="next"></i>
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="p-5 d-flex align-items-center">
            <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
              Total: {filteredTransactions?.length}&nbsp;&nbsp;
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
      <TransferModal Data={selectedData} />
      <OverseasTransfer Data={selectedData} />
      <DeleteModal data={deletePayee} payee={PayeeData} />
    </>
       )}
    </div>
  );
};

export default TableComponent;
