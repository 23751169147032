import { FC, useRef } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { checkIsActive, KTIcon, WithChildren } from "../../../../helpers";
import { useLayout } from "../../../core";
import money from "../../../../assets/all-serviceIcons/home.svg";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  const toggleRef = useRef<HTMLDivElement>(null);
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? "collapse"
    : appSidebarDefaultMinimizeDesktopEnabled
    ? "minimize"
    : "";
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? "active" : "";

  return (
    <>
      <div className="menu-item">
        <Link
          className={clsx("menu-link without-sub", { active: isActive })}
          to={title === "Currency Conversion" ? "/dashboard" : to}
        >
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
            <span
              className="menu-icon"
              ref={toggleRef}
              id="kt_app_sidebar_toggle"
              data-kt-toggle="true"
              data-kt-toggle-state={toggleState}
              data-kt-toggle-target="body"
              data-kt-toggle-name={`app-sidebar-${toggleType}`}
            >
              {icon !== "dashboard" && (
                <img
                  style={{ height: "18px", width: "18px" }}
                  src={icon}
                  alt=""
                />
              )}
              {icon === "dashboard" && (
                <img
                  style={{ height: "18px", width: "18px" }}
                  src={money}
                  alt=""
                />
              )}
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
            <i className={clsx("bi fs-3", fontIcon)}></i>
          )}
          <span
            className="menu-title"
            {...(title === "Currency Conversion"
              ? {
                  id: "kt_modal_toggle_conversion",
                }
              : {})}
          >
            {title}
          </span>
        </Link>
        {children}
      </div>
    </>
  );
};

export { SidebarMenuItem };
