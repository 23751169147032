import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { enhancedFetch } from "../app/modules/auth/core/_requests";

// Define your API URL
const API_URL = process.env.REACT_APP_WEB_API_URL;

// Define types/interfaces for your data
interface Button {
  buttonId: string;
  name: string;
  code: string;
  action: string;
  type: number;
}

interface NotificationDetails {
  notificationGuid: string;
  businessGuid: string;
  title: string;
  header: string;
  footer: string;
  body: string;
  interactive: boolean;
  mediaUrl: string;
  fcmToken: string;
  buttons: Button[];
}

interface Notification {
  notificationStatus: number;
  businessGuid: string;
  createdDate: string;
  createdDateString: string;
  notificationDetails: NotificationDetails;
}

// Define initial state
interface NotificationsState {
  notifications: Notification[];
  count: number;
  loading: boolean;
  error: string | null;
}

const initialState: NotificationsState = {
  notifications: [],
  count: 0,
  loading: false,
  error: null,
};
interface FetchTransactionPayload {
  // Define your payload type here if needed
}

// Define async thunk for fetching notifications
export const fetchNotifications = createAsyncThunk<
  Notification[],
  FetchTransactionPayload,
  { rejectValue: string }
>(
  "notifications/fetchNotifications",
  async (FetchTransactionPayload, { rejectWithValue }) => {
    try {
      const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
      const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
      const token = tokenData ? tokenData.token : null;
      console.log(FetchTransactionPayload);

      const response = await enhancedFetch(`${API_URL}/notification/sync`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "An error occurred.");
      }

      const responseData = await response.json();
      return responseData.results;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred.");
    }
  }
);

// Create slice
export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = false
        state.notifications = action.payload;
        state.count = action.payload.length;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading =false
        state.error = action.payload as string;
      });
  },
});

// Selectors
export const selectNotifications = (state: {
  notifications: NotificationsState;
}) => state.notifications;

// Reducer
export default notificationsSlice.reducer;
